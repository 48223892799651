import { html } from '../../../../../../core/scripts/util/util';

export default vm => {
    // sm-md

    const smmdButton = `
        <button pf-mix-click="$closest.onAnimalNameChanged(petNameInput.value)" type="submit" class="field_icon-btn">
            <span class="icon icon_md m-icon_colorDark">
                <svg role="img">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-search"></use>
                </svg>
            </span>
            <span class="u-isVisuallyHidden">Search</span>
        </button>
    `;

    const lgxlButton = `
        <button pf-mix-click="$closest.onAnimalNameChanged(petNameInput.value)" type="submit" class="field_icon-btn m-field_icon-btn_sm">
            <span class="icon icon_smmd m-icon_colorDark">
                <svg role="img">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-search"></use>
                </svg>
            </span>
            <span class="u-isVisuallyHidden">Search</span>
        </button>
    `;

    const button = (vm.state.currentBreakpoint === 'sm') ? smmdButton : lgxlButton;

    return html`
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Pet Name</div>
        <pfdc-element observe-state="animalSearch.filters.animalName" class="field field_icon m-field_iconRight_sm m-field_noLabel">
            <label for="PetName" class="u-isVisuallyHidden">Pet Name</label>
            <input type="text"
                name="search"
                id="PetName"
                class="field-input"
                autocomplete="off"
                placeholder=""
                pf-mix-id="petNameInput"
                pf-mix-change="$closest.onAnimalNameChanged($element.value)"
                pf-mix-value="observedState.value"
                value="$${vm.state.animalSearch.filters.animalName.value}"
                data-test="Filters_Pet_Name_Search_Input"
            >
            ${button}
        </pfdc-element>
    `;
};
