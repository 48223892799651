import PFDCElement from '../pfdc-element/element';

import Utils from '../../../../core/scripts/lib/wirey/Utils';

import APIService from '../../../../core/scripts/services/APIService';
import APIResponse from '../../../../core/scripts/services/APIResponse';

import {
    KEY_DOWN,
    KEY_ARROW_DOWN,
    KEY_UP,
    KEY_ARROW_UP,
    KEY_ESC,
    KEY_ESCAPE,
    KEY_ENTER,
    KEY_TAB,
} from '../../constants/keyboard';

import siteStateController from '../../state-controllers/siteStateController';

import template from './suggester-template.html';

/**
 * Default consts
 */

const DEFAULT_SELECTED_INDEX = -1;
const DEFAULT_MIN_SEARCH_TEXT_LENGTH = 2;

export default class PFDCSuggesterElement extends PFDCElement {
    static _elementTag = 'pfdc-suggester';

    static template = template;

    /**
     * State address getters
     */

    get baseStateAddress() {
        return this.getAttribute('base-state-address');
    }

    /**
     * Attribute getters
     */

    get _apiServiceAttribute() {
        return this.getAttribute('suggester-api-service');
    }
    get _apiMethodAttribute() {
        return this.getAttribute('suggester-api-method');
    }
    get labelPlaceholder() {
        return this.getAttribute('label-placeholder');
    }
    get labelPrefix() {
        return this.getAttribute('label-prefix') || '';
    }
    get _inputAriaLabel() {
        return this.getAttribute('input-aria-label');
    }
    get unitLabelSingular() {
        return this.getAttribute('suggester-unit-label-singular') || 'item';
    }
    get unitLabelPlural() {
        return this.getAttribute('suggester-unit-label-plural') || 'items';
    }
    get _minSearchTextLength() {
        return (
            Number(this.getAttribute('suggester-min-search-text-length')) ||
            DEFAULT_MIN_SEARCH_TEXT_LENGTH
        );
    } // eslint-disable-line

    /**
     * Address getters
     */

    get activeItemIndexAddress() {
        return `${this.baseStateAddress}.activeItemIndex`;
    }
    get optionsAddress() {
        return `${this.baseStateAddress}.options`;
    }
    get optionsLoadingAddress() {
        return `${this.baseStateAddress}.optionsLoading`;
    }
    get optionsMenuOpenAddress() {
        return `${this.baseStateAddress}.optionsMenuOpen`;
    }
    get inputTextAddress() {
        return `${this.baseStateAddress}.inputText`;
    }
    get labelTextAddress() {
        return `${this.baseStateAddress}.labelText`;
    }

    /**
     * State value getters/setters
     */

    get _options() {
        return siteStateController.stateAt(this.optionsAddress);
    }

    get activeItemIndex() {
        return siteStateController.stateAt(this.activeItemIndexAddress);
    }
    get _menuIsOpen() {
        return siteStateController.stateAt(this.optionsMenuOpenAddress);
    }
    get optionsLoading() {
        return siteStateController.stateAt(this.optionsLoadingAddress);
    }
    get optionCount() {
        return this._options.length;
    }

    get inputText() {
        return siteStateController.stateAt(this.inputTextAddress);
    }
    set inputText(text) {
        siteStateController.setStateAtAddress(text, this.inputTextAddress);
    }

    get labelText() {
        return siteStateController.stateAt(this.labelTextAddress);
    }
    set labelText(text) {
        siteStateController.setStateAtAddress(text, this.labelTextAddress);
    }

    /**
     * Computed getters
     */

    get currentLabel() {
        return this.labelText || this.labelPlaceholder || '';
    }

    get currentLabelIsNotPlaceholder() {
        return Boolean(
            this.labelText && this.labelText !== this.labelPlaceholder
        );
    }

    get hasEnoughInputForSearch() {
        return this.inputText.length >= this._minSearchTextLength;
    }

    get viewModel() {
        return {
            ...super.viewModel,
            inputAriaLabel: this._inputAriaLabel,
        };
    }

    /**
     * Constructor
     */

    constructor() {
        super();

        document.body.addEventListener('click', () => {
            this._closeOptionsMenu();
        });
    }

    /**
     * State update functions
     */

    _clearOptions() {
        this._setOptions([]);

        // whenever we clear options, we should also reset the active index
        this._setActiveItemIndex(DEFAULT_SELECTED_INDEX);
    }

    _setOptionsOpenState(isOpen) {
        siteStateController.setStateAtAddress(
            isOpen,
            this.optionsMenuOpenAddress
        );
    }

    _setOptionsLoading(areLoading) {
        siteStateController.setStateAtAddress(
            areLoading,
            this.optionsLoadingAddress
        );
    }

    _setOptions(options) {
        siteStateController.setStateAtAddress(options, this.optionsAddress);
        this._setOptionsLoading(false);
    }

    _setActiveItemIndex(newIndex) {
        siteStateController.setStateAtAddress(
            newIndex,
            this.activeItemIndexAddress
        );
    }

    /**
     * Handlers
     */

    /**
     * Auto-wired binding for a click event within this element
     * @param {ClickEvent} ev
     */
    onClickEvent(ev) {
        // intercept a click event within so it doesn't bubble up to the body and close options
        ev.stopPropagation();
    }

    onSuggesterClick() {
        this._openOptionsMenu();
    }

    onTextInputKeyDown(ev) {
        switch (ev.code) {
            case KEY_UP:
            case KEY_ARROW_UP:
                ev.stopPropagation();
                ev.preventDefault();
                this._highlightItemRelative(-1);
                break;
            case KEY_DOWN:
            case KEY_ARROW_DOWN:
                ev.stopPropagation();
                ev.preventDefault();
                this._highlightItemRelative(1);
                break;
            case KEY_ENTER:
            case KEY_TAB:
                this._onTabPressed(ev);
                break;
            case KEY_ESC:
            case KEY_ESCAPE:
                ev.stopPropagation();
                this._closeOptionsMenu();
                break;
        }
    }

    onTextInput(ev, inputValue) {
        this._clearOptions();
        this._performSearch(inputValue);
    }

    _onTabPressed(ev) {
        this._selectActiveItemAndCloseOptions();

        // if the user is progressing backwards through the DOM, we don't need to do the next step
        if (!ev.shiftKey) {
            this._moveFocusToNextElement();
        }
    }

    _moveFocusToNextElement() {
        setTimeout(() => {
            const nextDomEle = Utils.HTMLElementUtils.getNextDomElement(this);
            const nextTabbable = Utils.HTMLElementUtils.getNextTabbable(
                nextDomEle
            );

            if (nextTabbable) {
                nextTabbable.focus();
            }
        }, 0);
    }

    /**
     * Component functionality
     */

    _openOptionsMenu() {
        if (this._menuIsOpen) {
            return;
        }

        this._setOptionsOpenState(true);
    }

    _closeOptionsMenu() {
        if (!this._menuIsOpen) {
            return;
        }

        this._setOptionsOpenState(false);
    }

    _highlightItemRelative(relativeOffset) {
        // gather information
        const optionsCount = this._options.length;

        // calculate new index
        let newIndex;
        if (optionsCount === 0) {
            newIndex = DEFAULT_SELECTED_INDEX;
        } else if (this.activeItemIndex === DEFAULT_SELECTED_INDEX) {
            newIndex = relativeOffset > 0 ? 0 : optionsCount - 1;
        } else {
            newIndex = Utils.MathUtils.mod(
                this.activeItemIndex + relativeOffset,
                optionsCount
            );
        }

        // push update
        this._setActiveItemIndex(newIndex);
    }

    _selectItemByIndex(index) {
        const item = this._getItemByIndex(index);

        if (!item) {
            return;
        }

        // update label text for display when the input is not focused
        // TODO: hard coded "label" here; make this configurable to be truly dynamic
        // TODO: (the data may store the thing we wish to use as the label somewhere else)
        this.labelText = item.label;

        // alert parent observer of change
        const changeEvent = new Event('change');
        changeEvent.selected = item;
        this.dispatchEvent(changeEvent);
    }

    _selectActiveItemAndCloseOptions() {
        const index = this.activeItemIndex === -1 ? 0 : this.activeItemIndex;

        this.selectItemAndCloseOptions(index);
    }

    selectItemAndCloseOptions(index) {
        this._selectItemByIndex(index);
        this._closeOptionsMenu();
    }

    _getItemByIndex(index) {
        const options = this._options;

        if (options.length === 0) {
            return null;
        }

        return options[index];
    }

    /**
     * Searching
     */

    _performSearch(inputText) {
        this.inputText = inputText;

        if (!this.hasEnoughInputForSearch) {
            return;
        }

        this._updateOptionsFromServer(inputText);

        this._setOptionsLoading(true);

        // TODO: can this happen?
        // open the options menu, just in case it's closed
        this._openOptionsMenu();
    }

    get _apiServiceMethod() {
        const apiService = APIService.get(this._apiServiceAttribute);
        if (!apiService) {
            throw new Error(
                `Unable to find an API service registered with id: "${
                this._apiServiceAttribute
                }"`
            ); // eslint-disable-line
        }

        const apiMethod = apiService[this._apiMethodAttribute];
        if (typeof apiMethod !== 'function') {
            throw new Error(
                `No method "${
                this._apiMethodAttribute
                }" found on API service "${this._apiServiceAttribute}"`
            ); // eslint-disable-line
        }

        return apiMethod.bind(apiService);
    }

    /**
     * @param {Array.<any>} params
     * @returns {APIResponse}
     */
    async _callAPI(...params) {
        const method = this._apiServiceMethod;
        const response = await method(...params);

        return response;
    }

    async _updateOptionsFromServer(searchText) {
        const response = await this._callAPI(searchText);

        if (response.wasCancelled) {
            return;
        }

        // once we get here, an async fetch has occurred, and the text input may have changed
        // since we made our initial call... if our current input text is no longer long enough
        // to allow for a search, we want to ignore the late response and exit early
        if (!this.hasEnoughInputForSearch) {
            return;
        }

        this._setOptions(response.data);
    }
}
