import { html } from '../../../../core/scripts/util/util';
import { animalTypeIconMap } from '../../constants/maps';

// prettier-ignore
const abbreviateNumber = n =>
    n >= 1000000 ? (n / 1000000).toFixed(1) + 'M' :
        n >= 1000 ? (n / 1000).toFixed(1) + 'K' :
            n < 0 ? 0 :
                n;

// prettier-ignore
export default vm => {
    // Return the complete template if there is an animalType
    return html`
        ${vm.hasValue ? fundamentalSelectComplete(vm) : fundamentalSelectIncomplete(vm)}
    `;
};

// prettier-ignore
const getPetTypesElementType = vm => {
    return (vm.state.currentBreakpoint === 'sm') ? 'span' : 'button';
}

// prettier-ignore
const fundamentalSelectIncomplete = vm => {
    const hasAnimalType = Boolean(vm.state.animalSearch.filters.animalType.value.length);
    const defaultAnimalTypeLabel = !vm.state.animalSearch.filters.animalType.defaultLabel ? '' : `${vm.state.animalSearch.filters.animalType.defaultLabel}`;

    const petTypesElementType = getPetTypesElementType(vm);

    return html`
        <div class="animalSearchHeader-inner-fundamentalSelectIncomplete-field m-animalSearchHeader-inner-fundamentalSelectIncomplete-field_fixedWidth">
            <span class="animalSearchHeader-inner-fundamentalSelectIncomplete-field-petCount" data-test="Search_Pet_Results_Count">
                ${abbreviateNumber(vm.totalResults)} <span class="u-isHidden@maxMd">Pets</span>
            </span>
        </div>
        <div
            ${vm.state.currentBreakpoint === 'sm' ? 'style="position: static;"' : ''}
            class="animalSearchHeader-inner-fundamentalSelectIncomplete-field"
        >
            <${petTypesElementType} class="animalSearchHeader-inner-fundamentalSelectIncomplete-field-allPetTypesBtn u-isHidden@minMd" type="button" tabindex="-1">${hasAnimalType ? '' : defaultAnimalTypeLabel}</${petTypesElementType}>
            <${petTypesElementType}
                ${vm.id}-open-btn
                id="AnimalTypeSelectOpenBtn"
                class="animalSearchHeader-inner-fundamentalSelectIncomplete-field-allPetTypesBtn u-isHidden@maxMd"
                type="button"
                aria-label="${vm.inputAriaLabel}"
                ${vm.observedState.options.length > 0 ? 'aria-haspopup="true"' : ''}
                data-test="Search_Open_Pet_Type_Filter_Button">
                ${hasAnimalType ? '' : defaultAnimalTypeLabel}${vm.selectedLabels[0]}
            </${petTypesElementType}>
            ${vm.menuIsOpenOrStillAnimating
            ? (vm.state.currentBreakpoint === 'sm' ? '' : desktopSelectMenu(vm))
            : ''
        }
        </div>
    `;
};

// prettier-ignore
const fundamentalSelectComplete = vm => {
    const petTypesElementType = getPetTypesElementType(vm);

    return html`
        <div class="animalSearchHeader-inner-fundamentalSelectComplete-field">
            <span
                ${vm.state.currentBreakpoint === 'sm' ? 'style="position: static;"' : ''}
                class="animalSearchHeader-inner-fundamentalSelectComplete-field-txt"
            >
                <pf-focus-manager focus-first-focusable>
                    <${petTypesElementType}
                        ${vm.id}-open-btn
                        id="AnimalTypeSelectOpenBtn"
                        class="animalSearchHeader-inner-fundamentalSelectComplete-field-txt-petTypeBtn u-noWrap"
                        ${petTypesElementType === 'button' ? 'type="button"' : ''}
                        aria-label="${vm.inputAriaLabel}, ${vm.selectedLabels[0]}"
                        ${vm.observedState.options.length > 0 ? 'aria-haspopup="true"' : ''}
                        data-test="Search_Open_Pet_Type_Filter_Button"
                    >
                        <svg role="img" class="animalSearchHeader-inner-fundamentalSelectComplete-field-icon">
                            <use xlink:href="#icon-${animalTypeIconMap[vm.observedState.value]}" />
                        </svg>
                        <span class="animalSearchHeader-inner-fundamentalSelectComplete-field-txt-wrap" data-test="Search_Pet_Results_Count">
                            <span class="animalSearchHeader-inner-fundamentalSelectComplete-field-txt-light">
                                ${abbreviateNumber(vm.totalResults)}
                            </span> ${vm.selectedLabels[0]}
                        </span>
                    </${petTypesElementType}>
                </pf-focus-manager>
                ${vm.menuIsOpenOrStillAnimating
            ? (vm.state.currentBreakpoint === 'sm' ? '' : desktopSelectMenu(vm, true))
            : ''
        }
            </span>
        </div>`;
};

// prettier-ignore
// pop-up/dropdown
const desktopSelectMenu = (vm, completePosition) => {
    const highlightedIndex = vm.localState.highlightedIndex;
    const focusIsAllowed = vm.localState.focusIsAllowed;

    return html`
        <pf-focus-manager
            trap-tab-focus
            ${focusIsAllowed ? `return-focus-to="[${vm.id}-open-btn]"` : ''}
        >
            <ul
                class="animalSearchHeader-petTypeSelect ${vm.localState.menuIsOpen ? 'u-isOpen' : ''}"
                style="${completePosition ? `left: 50px; top: -100%;` : ''}"
                id="${vm.id}_List_Box"
                role="listbox"
                data-test="Search_Pet_Type_Filter_Menu"
            >
                ${vm.observedState.options.map((option, index) => html`
                    <li
                        ${vm.id}-item-btn
                        pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}');"
                        pf-mix-mouseover="$closest.onItemHovered($event, $element, ${index});"
                        pf-mix-focus="$closest.onItemHovered($event, $element, ${index})"
                        class="animalSearchHeader-petTypeSelect-item ${vm.selectedValues.includes(option.value) ? 'u-isSelected' : ''} ${highlightedIndex === index ? 'u-isHighlighted' : ''}"
                        ${(focusIsAllowed && highlightedIndex === index) ? 'pf-mix-focus-on-render' : ''}
                        type="button"
                        role="option"
                        aria-selected="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
                        tabindex="0"
                        aria-label="${option.label}"
                        data-test="Filters_Open_Menu_Option_List"
                    >
                        <svg role="img">
                            <use xlink:href="#icon-${animalTypeIconMap[option.value]}" />
                        </svg>
                        ${option.label}
                    </li>
                `)}
            </ul>
        </pf-focus-manager>
    `;
}
