import { html } from '../../../../core/scripts/util/util';

export default vm => {
    const shareName = vm.shareName;
    const facebookSharingUrl = vm.facebookSharingUrl;
    const twitterSharingUrl = vm.twitterSharingUrl;
    const pinterestSharingUrl = vm.pinterestSharingUrl;
    const emailTitle = vm.emailTitle;
    const emailBody = vm.emailBody;
    const clipboardUrl = vm.clipboardUrl;

    return html`
        <div
            class="buttonSlide ${vm.localState.trayIsOpen
                ? 'isOpen'
                : ''} ${vm.petTemplate ? 'buttonSlide_petCard' : ''}"
        >
            <div class="buttonSlide-btn">
                <button
                    class="btn btn_slide m-btn_circleLarge"
                    data-url="${clipboardUrl}"
                    ${vm.localState.trayIsOpen
                        ? 'pf-mix-click="$closest.toggleMenu($event, $element);"'
                        : 'pf-mix-click="$closest.toggleMenu($event, $element); trackingBridge.ensighten(\'PetfinderConsumer77\');"'}
                    aria-label="Social Sharing Options"
                >
                    <div class="buttonSlide-btn-iconShow">
                        <span class="icon m-icon_colorDark m-icon_block">
                            <svg role="img" focusable="false">
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="#icon-share_outline"
                                ></use>
                            </svg>
                        </span>
                    </div>
                    <div class="buttonSlide-btn-iconHide">
                        <span class="icon icon_sm m-icon_colorDark">
                            <svg role="img" focusable="false">
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="#icon-close"
                                ></use>
                            </svg>
                        </span>
                    </div>
                </button>
            </div>
            <div class="buttonSlide-tray">
                <div
                    class="buttonSlide-tray-list ${vm.ariaHidden === 'true'
                        ? 'u-isHidden'
                        : ''}"
                >
                    <ul class="hrArray m-hrArray_1x">
                        <li name="facebook">
                            <a
                                buttonSlide-tray-social-link
                                href="$${facebookSharingUrl}"
                                target="_blank"
                                class="icon icon_socialCircle m-icon_colorDark ${vm.petTemplate
                                    ? ''
                                    : 'icon_socialCircle-large'}"
                                title="Share to Facebook"
                                aria-label="Share $${shareName} to Facebook"
                                aria-hidden="${vm.ariaHidden}"
                                tabindex="${vm.tabIndex}"
                            >
                                <svg role="img" focusable="false">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#icon-facebook"
                                    ></use>
                                </svg>
                            </a>
                        </li>
                        <li name="twitter">
                            <a
                                buttonSlide-tray-social-link
                                href="$${twitterSharingUrl}"
                                target="_blank"
                                title="Share to Twitter"
                                class="icon icon_socialCircle m-icon_colorDark ${vm.petTemplate
                                    ? ''
                                    : 'icon_socialCircle-large'}"
                                aria-label="Share $${shareName} to Twitter"
                                aria-hidden="${vm.ariaHidden}"
                                tabindex="${vm.tabIndex}"
                            >
                                <svg role="img" focusable="false">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#icon-twitter"
                                    ></use>
                                </svg>
                            </a>
                        </li>
                        <li name="pinterest">
                            <a
                                buttonSlide-tray-social-link
                                href="${pinterestSharingUrl}"
                                target="_blank"
                                class="icon icon_socialCircle m-icon_colorDark ${vm.petTemplate
                                    ? ''
                                    : 'icon_socialCircle-large'}"
                                title="Share to Pinterest"
                                aria-label="Share $${shareName} to Pinterest"
                                aria-hidden="${vm.ariaHidden}"
                                tabindex="${vm.tabIndex}"
                            >
                                <svg role="img" focusable="false">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#icon-pinterest"
                                    ></use>
                                </svg>
                            </a>
                        </li>
                        <li name="email">
                            <a
                                pf-mix-click="trackingBridge.ensighten('PetfinderConsumer81');"
                                buttonSlide-tray-social-link
                                social-link-email
                                data-url="${clipboardUrl}"
                                href="mailto:?subject=${emailTitle}&body=${emailBody}"
                                title="Share via email"
                                class="icon icon_socialCircle m-icon_colorDark ${vm.petTemplate
                                    ? ''
                                    : 'icon_socialCircle-large'}"
                                aria-label="Share $${shareName} via Email"
                                aria-hidden="${vm.ariaHidden}"
                                tabindex="${vm.tabIndex}"
                            >
                                <svg
                                    role="img"
                                    focusable="false"
                                    social-link-email
                                >
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        social-link-email
                                        xlink:href="#icon-email"
                                    ></use>
                                </svg>
                            </a>
                        </li>
                        <li name="linkShare">
                            <pfdc-clipboard
                                command="copy"
                                text="${clipboardUrl}"
                            >
                                <a
                                    pf-mix-click="trackingBridge.ensighten('PetfinderConsumer82');"
                                    buttonSlide-tray-social-link
                                    buttonSlide-tray-social-link-clipboard
                                    id="#"
                                    text="${clipboardUrl}"
                                    class="icon icon_socialCircle m-icon_colorDark u-noChildPointerEvents ${vm.petTemplate
                                        ? ''
                                        : 'icon_socialCircle-large'}"
                                    title="Copy pet url to clipboard"
                                    aria-label="Copy search url to your clipboard"
                                    aria-hidden="${vm.ariaHidden}"
                                    tabindex="${vm.tabIndex}"
                                    tooltip
                                    tooltip-text="Link Copied"
                                    tooltip-hide-after="3000"
                                    tooltip-position="bottom center"
                                    tooltip-y-offset="22"
                                >
                                    <svg role="img" focusable="false">
                                        <use xlink:href="#icon-link" />
                                    </svg>
                                </a>
                            </pfdc-clipboard>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `;
};
