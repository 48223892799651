import MixinBase from '../../../../core/scripts/lib/wirey/mixins/MixinBase';
import Mixins from '../../../../core/scripts/lib/wirey/Mixins';
import Utils from '../../../../core/scripts/lib/wirey/Utils';
import FocusManager from '../../../../core/scripts/lib/FocusManager';

export default class FocusFirstFocusableOnRenderMixin extends MixinBase {
    static _attributeName = 'mix-focus-first-focusable-on-render';
    focusManager = new FocusManager();

    constructor(ele, value) {
        super(ele, value);
        this.focusManager.focusFirstFocusable(ele);
    }
}

Mixins.add(FocusFirstFocusableOnRenderMixin);
