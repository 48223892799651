import PFDCElement from '../pfdc-element/element';

import Utils from '../../../../core/scripts/lib/wirey/Utils';

// import template from './template.html';
import {
    KEY_DOWN,
    KEY_ARROW_DOWN,
    KEY_UP,
    KEY_ARROW_UP,
    KEY_ESC,
    KEY_ESCAPE,
    KEY_ENTER,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../constants/keyboard';

import template from './option-list-template.html';

export default class PFDCOptionListElement extends PFDCElement {
    static _elementTag = 'pfdc-option-list';

    static template = template;

    /**
     * Getters / Config
     */

    get optionListId() {
        return this.getAttribute('option-list-id') || '';
    }
}
