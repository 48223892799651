import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import GeoSearchComboBox from '../../../../../../core/scripts/react-components/specifics/GeoSearchComboBox';
import CheckboxCard from '../../../specifics/CheckboxCard';
import Popup from '../../../../../../core/scripts/react-components/generics/Popup';
import siteStateController from '../../../../state-controllers/siteStateController';

import { KEY_SPACE_BAR, KEY_SPACE } from '../../../../constants/keyboard';

import {
    analyticsConsumer444,
    analyticsConsumer445,
    analyticsConsumer446,
} from '../../../../analytics/dotcom';
import ensightenTracking from '../../../../../../core/scripts/elements/pf-app/ensighten/ensighten';

import _noop from 'lodash/noop';

class InterceptorForm extends Component {
    state = {
        locationValue: null,
        distanceValue: '100',
        typeValue: 'dogs',
        missingParams: [],
        formIsDisabled: false,
    };

    /**
     * @type {object}
     * @static
     */
    static propTypes = {
        onSubmit: PropTypes.func,
    };

    /**
     * @type {object}
     * @static
     */
    static defaultProps = {
        onSubmit: _noop,
    };

    /**
     * ID base for sub-components
     * @type {string}
     */
    id = 'Interceptor_Form';

    /**
     * Name for the initiating site feature
     * @type {string}
     */
    siteFeatureName = 'interceptor modal';

    /**
     * timeout delay in ms for type popup close
     * @type {number}
     */
    timeoutDelay = 300;

    /**
     * @type {array}
     */
    otherPets = [
        {
            value: 'rabbits',
            label: 'Rabbits',
        },
        {
            value: 'small-furry',
            label: 'Small & Furry',
        },
        {
            value: 'horses',
            label: 'Horses',
        },
        {
            value: 'birds',
            label: 'Birds',
        },
        {
            value: 'scales-fins-others',
            label: 'Scales, Fins, & Other',
        },
        {
            value: 'barnyard',
            label: 'Barnyard',
        },
    ];

    componentDidMount() {
        this._init();
    }

    /**
     * set initial state
     */
    _init() {
        const missingParams = siteStateController.animalSearch.filters.missingRequiredParams;

        this.setState({ missingParams });
        analyticsConsumer444(missingParams);
    }

    /**
     * selection handler for location search field
     * @param {array<string>} value
     */
    _handleLocationChange = value => {
        this.setState({ locationValue: value });
    };

    /**
     * click handler for "use current location" button
     */
    _handleUseLocationClick = () => {
        analyticsConsumer445(this.siteFeatureName);
    };

    /**
     * error handler for location field
     * @param {string} errorSource
     */
    _handleLocationError = errorSource => {
        if (errorSource === 'geolocation') {
            analyticsConsumer446(this.siteFeatureName);
        }
    };

    /**
     * onChange handler for distance field
     * @param {event} event
     */
    _handleDistanceOnChange = event => {
        this.setState({ distanceValue: event.target.value });
    };

    /**
     * onChange handler for type options
     * @param {string} value
     * @param {function} openPopup Optional. Will be provided from <Popup />.
     */
    _handleTypeChange = (value, openPopup = _noop) => {
        this.setState({ typeValue: value });

        const otherPetValues = this.otherPets.map(animalType => animalType.value);

        if (otherPetValues.includes(value)) {
            openPopup();
        }
    };

    /**
     * onClick handler for type
     * @param {function} closePopup provided from <Popup />.
     */
    _handleTypeClick = (closePopup = _noop) => {
        setTimeout(() => {
            closePopup();
        }, this.timeoutDelay);
    };

    /**
     * keydown handler for type options
     * @param {event} event
     * @param {function} closePopup provided from <Popup />.
     */
    _handleTypeKeydown = (event, closePopup) => {
        switch (event.key) {
            case KEY_SPACE_BAR:
            case KEY_SPACE:
                setTimeout(() => {
                    closePopup();
                }, this.timeoutDelay);
                break;
        }
    };

    /**
     * Handler for beginning of fetch callback
     */
    _handleFetchingSuggestions = () => {
        this.setState({ formIsDisabled: true });
    }

    /**
     * Handler for returned results callback
     */
    _handleReturnedSuggestions = () => {
        this.setState({ formIsDisabled: false });
    }

    /**
     * click handler for search button
     * applies filter values to stateController and triggers onSubmit callback
     * @param {event} event
     */
    _handleSearchButtonClick = event => {
        event.preventDefault();

        if (this.state.missingParams.includes('location')) {
            if (this.state.locationValue && siteStateController.animalSearch.filters.sortBy.includes('recently_added')) {
                siteStateController.animalSearch.filters.sortBy = ['nearest'];
            }
            siteStateController.animalSearch.filters.distance = [this.state.distanceValue];
            siteStateController.animalSearch.filters.location = [this.state.locationValue] || [];

            ensightenTracking.eventConsumer037({
                locationSlug: this.state.locationValue,
                initiatingSiteFeature: this.siteFeatureName,
            });
            ensightenTracking.eventConsumer057({
                distance: [this.state.distanceValue],
                initiatingSiteFeature: this.siteFeatureName,
            });
        }

        if (this.state.missingParams.includes('animalType')) {
            siteStateController.animalSearch.filters.animalType = [this.state.typeValue];

            ensightenTracking.eventConsumer038({
                petTypeSlug: this.state.typeValue,
                initiatingSiteFeature: this.siteFeatureName,
            });
        }

        this.props.onSubmit();
    };

    /**
     * @returns {HTMLElement}
     */
    _renderLocationField() {
        return (
            <GeoSearchComboBox
                id={`${this.id}_Location`}
                name="location"
                dataTestInputId="Location_Input"
                extensionClassNames={{
                    ['comboBox_field']: true,
                    ['m-comboBox_iconLocation']: true,
                }}
                listBoxExtensionClassNames={{
                    ['listBox_field']: true,
                }}
                onSelection={this._handleLocationChange}
                onUseLocationClick={this._handleUseLocationClick}
                onError={this._handleLocationError}
                onFetchingSuggestions={this._handleFetchingSuggestions}
                onReturnedSuggestions={this._handleReturnedSuggestions}
            />
        );
    }

    /**
     * @returns {HTMLElement}
     */
    _renderDistanceField() {
        return (
            <div className="select">
                <label htmlFor={`${this.id}_Distance`} className="select-label">
                    Distance
                </label>
                <select
                    id={`${this.id}_Distance`}
                    className="select-select"
                    value={this.state.distanceValue}
                    onChange={this._handleDistanceOnChange}
                >
                    {siteStateController.animalSearch.filters.distanceOptions.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    _renderTypeOptions() {
        return (
            <Fragment>
                <div className="grid grid_gutterLg u-vr4x">
                    <div className="grid-col grid-col_1/2@minLg">
                        <CheckboxCard
                            extensionClassNames={{
                                ['m-checkboxCard_colorDark']: true,
                                ['m-checkboxCard_fullWidth']: true,
                                ['u-displayBlock']: true,
                            }}
                            checked={this.state.typeValue === 'dogs'}
                            id={`${this.id}_Dogs`}
                            iconId="icon-dog"
                            label="Dogs"
                            name={`${this.id}_Type`}
                            type="radio"
                            value="dogs"
                            onChange={checked => this._handleTypeChange('dogs')}
                        />
                    </div>
                    <div className="grid-col grid-col_1/2@minLg">
                        <CheckboxCard
                            extensionClassNames={{
                                ['m-checkboxCard_colorDark']: true,
                                ['m-checkboxCard_fullWidth']: true,
                                ['u-displayBlock']: true,
                            }}
                            checked={this.state.typeValue === 'cats'}
                            id={`${this.id}_Cats`}
                            dataTestId="Animal_Type_Cats_Radio"
                            iconId="icon-cat"
                            label="Cats"
                            name={`${this.id}_Type`}
                            type="radio"
                            value="cats"
                            onChange={checked => this._handleTypeChange('cats')}
                        />
                    </div>
                </div>
                <Popup id={this.id} shouldCloseOnBlur={false}>
                    {popup => (
                        <div {...popup.containerProps} className="fieldPopup">
                            <button
                                {...popup.triggerProps}
                                type="button"
                                className="fieldPopup-trigger"
                                data-test="Other_Pets_Dropdown_Trigger"
                            >
                                Other Pets
                            </button>
                            <div
                                {...popup.popupProps}
                                className={`fieldPopup-dropdown ${popup.isVisible ? '' : 'u-isVisuallyHidden'}`}
                            >
                                <ul {...(popup.isVisible ? {} : { 'aria-hidden': true })}>
                                    {this.otherPets.map(option => (
                                        <li
                                            key={option.value}
                                            className="fieldPopup-dropdown-item"
                                            onClick={() => this._handleTypeClick(popup.closePopup)}
                                        >
                                            <label htmlFor={`${this.id}_${option.value}`} className="radio">
                                                <input
                                                    id={`${this.id}_${option.value}`}
                                                    type="radio"
                                                    name={`${this.id}_Type`}
                                                    onChange={() =>
                                                        this._handleTypeChange(option.value, popup.openPopup)
                                                    }
                                                    onKeyDown={event =>
                                                        this._handleTypeKeydown(event, popup.closePopup)
                                                    }
                                                    onClick={event => event.stopPropagation()}
                                                    aria-checked={this.state.typeValue === option.value}
                                                    {...(popup.isVisible
                                                        ? {}
                                                        : {
                                                            tabIndex: '-1',
                                                            'aria-hidden': true,
                                                        })}
                                                    className="radio-input"
                                                />
                                                <span className="radio-btn" />
                                                <span className="radio-label">{option.label}</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                                <button
                                    type="button"
                                    className="u-isVisuallyHiddenUnlessFocused"
                                    onClick={() => {
                                        popup.closePopup();
                                    }}
                                    {...(popup.isVisible
                                        ? {}
                                        : {
                                            tabIndex: '-1',
                                            'aria-hidden': true,
                                        })
                                    }
                                >
                                    Collapse Options
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </Fragment>
        );
    }

    render() {
        return (
            <form className="u-inline">
                {this.state.missingParams.includes('animalType') && (
                    <Fragment>
                        <h2 id="Animal_Type_Label" className="txt txt_h3 m-txt_regular u-vr4x">
                            What kind of pet are you looking for?
                        </h2>
                        <div className="u-vr6x" role="radiogroup" aria-labelledby="Animal_Type_Label">
                            {this._renderTypeOptions()}
                        </div>
                    </Fragment>
                )}

                {this.state.missingParams.includes('location') && (
                    <Fragment>
                        <h2 className="txt txt_h3 m-txt_regular u-vr4x">Where are you looking?</h2>
                        <div className="u-vr4x">{this._renderLocationField()}</div>
                        <div className="u-vr6x">{this._renderDistanceField()}</div>
                    </Fragment>
                )}

                <div className="u-textRight">
                    <button
                        type="submit"
                        className="btn"
                        data-test="Interceptor_Submit_Button"
                        onClick={this._handleSearchButtonClick}
                        disabled={this.state.formIsDisabled}
                    >
                        Find Pets!
                    </button>
                </div>
            </form>
        );
    }
}

export default InterceptorForm;
