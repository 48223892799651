import { html, ifVal } from '../../../../core/scripts/util/util';

import closedStateControlBar from './partials/closedStateControlBar.html';
import openStateMobile from './partials/openStateMobile.html';
import openStateDesktop from './partials/openStateDesktop.html';
import { selectionsDisplay, optionsListItem } from './partials/common.html';

const DEFAULT_PARTIALS = {
    closedStateControlBar,
    openStateMobile,
    openStateDesktop,
    selectionsDisplay,
    optionsListItem,
};

// First curried argument is an optional object for
// passing in custom template components.
export default (propsArg = {}) => vm => {
    Object.assign(vm, {
        props: Object.assign({}, DEFAULT_PARTIALS, propsArg),
    });

    if (vm.menuIsOpenOrStillAnimating) {
        return vm.state.currentBreakpoint === 'sm' || vm.state.currentBreakpoint === 'md'
            ? vm.props.openStateMobile(vm)
            : vm.props.openStateDesktop(vm);
    } else {
        return vm.props.closedStateControlBar(vm);
    }
};

export const WIDTH_MODES = {
    FULL: 'FULL',
    WIDE: 'WIDE',
    DOUBLE: 'DOUBLE',
};
