/**
 * This element will automatically select the largest ad (at time of writing, by area) available
 * at any particular time upon window resize.
 *
 * Requirements:
 *     Attribute: slot-id - the slot id that GPT is presumably using to identify the ad
 *     Attribute: position-target-id - the leading portion of the position targetting identifier
 *                (without the "_320x100" portion used for size identification)
 *
 * Ad selection limiters (in order of precedence):
 *     * CSS max-width/max-height - if set, the ads that can be selected will be limited by these
 *     * Actual space occupied by ad - if the element occupies non-zero space, ad selection will be limited by these
 *     * No restriction - if none of the above are present (which would mean the ad container occupies a 0x0 space),
 *       no limit will be in place, and the larget available ad will be delivered
 */

import PFDCElement from '../pfdc-element/element';
import { AdProvider } from '../../../../core/scripts/constants/ads';
import { debounce } from '../../../../core/scripts/util/dom';

class PFDCAdContainerElement extends PFDCElement {
    /* *******************************************
     * Class Properties
     ********************************************/
    static _elementTag = 'pfdc-ad-container';

    currentAdId = null;
    isDisconnected = false;

    get slotId() {
        const adId = this.getAttribute('slot-id');
        if (!adId) {
            throw new Error('slot-id attribute was not provided');
        }

        return adId;
    }

    get positionTargetId() {
        const positionTargetId = this.getAttribute('position-target-id');
        if (!positionTargetId) {
            throw new Error('position-target-id attribute was not provided');
        }

        return positionTargetId;
    }

    get maxAdWidth() {
        // attempt to parse max-height css and use that
        const parsedMaxWidth = this.style.maxWidth.match(/(\d+)px/);
        if (parsedMaxWidth) {
            return parseInt(parsedMaxWidth[1]);
        }

        // if an explicit width is found, use that
        if (this.offsetWidth > 0) {
            return this.offsetWidth
        }

        // if no explicit width is found, we'll assume there is no max to be concerned about
        return Number.MAX_SAFE_INTEGER;
    }

    get maxAdHeight() {
        // attempt to parse max-height css and use that
        const parsedMaxHeight = this.style.maxHeight.match(/(\d+)px/);
        if (parsedMaxHeight) {
            return parseInt(parsedMaxHeight[1]);
        }

        // if an explicit height is found, use that
        if (this.offsetHeight > 0) {
            return this.offsetHeight
        }

        // if no explicit height is found, we'll assume there is no max to be concerned about
        return Number.MAX_SAFE_INTEGER;
    }

    get adPoolIds() {
        return (this.getAttribute('ad-pool-ids') || '')
            .split(/\s*,\s*/)
            .filter(item => item != '');
    }


    /* *******************************************
     * Lifecycle
     ********************************************/

    /**
     * Fires when the component has bootstrapped
     */
    onConnected() {
        super.onConnected();

        this._debouncedWindowResizeHandler = debounce(this._handleWindowResize, 250);
        window.addEventListener('resize', this._debouncedWindowResizeHandler);
    }

    onDisconnected() {
        window.removeEventListener('resize', this._debouncedWindowResizeHandler);
        this._debouncedWindowResizeHandler = null;

        super.onDisconnected();

        this.isDisconnected = true;
    }

    /* *******************************************
     * Events
     ********************************************/
    _handleWindowResize = ev => {
        // sometimes the firing of the resize handler occurs before parenting elements have finished re-rendering
        // which results in this function being called sometimes after this element was removed from the DOM
        // this prevents that
        setTimeout(() => {
            if (this.isDisconnected) {
                return;
            }

            this.render();
        }, 0);
    };


    /* *******************************************
     * Core Functionality
     ********************************************/

    _updateCurrentAd() {
        const newAd = AdProvider.getLargestByArea(this.maxAdWidth, this.maxAdHeight, this.adPoolIds);

        if (!newAd) {
            console.warn(`Unable to find ad that could fit within the content area (${this.maxAdWidth}px x ${this.maxAdHeight}px) of: `, this, 'Pool restriction: ', this.adPoolIds);
            return false;
        }

        const newAdId = newAd.id;

        if (this.currentAdId && newAdId === this.currentAdId) {
            // ads are the same size and don't need to be changed
            return false;
        }

        this.currentAdId = newAdId;
        return true;
    }

    /* *******************************************
     * Render
     ********************************************/

    /**
     * @param {{ controller: PFDCAdContainerElement, localState: Object, observedState: Object, observedStateAddress: String, state: Object }} vm
     */
    static template = vm => {
        // TODO: remove need for this pass-thru of search-ad (hard coded attributes being used as flags in pfdc-element)
        const flags = ['search-ad', 'lazy-load']
            .map(x => vm.controller.hasAttribute(x) ? x : null)
            .filter(x => x)
            .join(' ');

        return `
            <pfdc-ad
                slot-id="${vm.controller.slotId}"
                non-responsive
                ${flags}
                override-ad-id="${vm.controller.currentAdId}"
                position-target-id="${vm.controller.positionTargetId}"
            ></pfdc-ad>
        `;
    }

    render(...args) {
        if (this._updateCurrentAd()) {
            super.render(...args);
        }
    }
}

export default PFDCAdContainerElement;
