import { html } from '../../../../core/scripts/util/util';

const optionsLoadingTemplate = vm => {
    return html`
        <ul class="suggester-list"><li class="suggester-list-item">Loading...</li></ul>
    `;
};

const optionsListTemplate = vm => {
    const haveOptionsToShow = vm.observedState.options.length !== 0;
    const optionsAreLoading = vm.observedState.optionsLoading;

    if (!haveOptionsToShow && !optionsAreLoading) {
        return '';
    }

    const idString = vm.controller.optionListId
        ? `id="${vm.controller.optionListId}"`
        : '';

    return html`
        <ul ${idString} role="listbox" class="suggester-list">
            ${vm.observedState.options.map((item, index) => {
                const isFocused = index === vm.observedState.activeItemIndex;

                const selectedItemSnippet = (isFocused)
                    ? 'aria-selected="true"'
                    : 'aria-selected="false"';

                const additionalClasses = (isFocused)
                    ? 'isFocused'
                    : '';

                return html`
                    <li role="option" ${selectedItemSnippet} class="suggester-list-item ${additionalClasses}" data-test="Filters_Open_Menu_Option_List">
                        <a href pf-mix-click="$event.preventDefault(); $closest.selectItemAndCloseOptions(${index})">
                            ${item.label}
                        </a>
                    </li>
                `;
            })}
        </ul>
    `;
};

const template = vm => {
    // menu isn't open
    if (!vm.observedState.optionsMenuOpen) {
        return '';
    }

    // menu is open

    // searching
    if (vm.observedState.optionsLoading) {
        return optionsLoadingTemplate(vm);
    }

    // show options list
    return optionsListTemplate(vm);
};

export default template;
