/**
 * # Custom Element Registry
 * Loads polyfills for custom elements.
 * Loads element class definitions.
 * Defines custom elements.
 */

/**
 * Core Element Imports
 */
import PFAccordionElement from '../../../../core/scripts/elements/pf-accordion/element';
import PFAdElement from '../../../../core/scripts/elements/pf-ad/element';
import PFAppElement from '../../../../core/scripts/elements/pf-app/element';
import PFDynamic from '../../../../core/scripts/elements/pf-dynamic/element';
import PFElement from '../../../../core/scripts/elements/pf-element/element';
import PFEnsightenElement from '../../../../core/scripts/elements/pf-ensighten/element';

/**
 * Dotcom Specific Element Imports
 */
import PFDCTrackingBridge from '../../elements/pfdc-tracking-bridge/element';
import PFDCAdElement from '../../elements/pfdc-ad/element';
import PFDCAnimalIconElement from '../../elements/pfdc-animal-icon/element';
import PFDCAnimalSearchElement from '../../elements/pfdc-animal-search/element';
import PFDCAnimalSearchFiltersElement from '../../elements/pfdc-animal-search-filters/element';
import PFDCAnimalSearchOptinsElement from '../../elements/pfdc-animal-search-optins/element';
import PFDCAnimalSearchResultsElement from '../../elements/pfdc-animal-search-results/element';
import PFDCAnimalTypeSelectElement from '../../elements/pfdc-animal-type-select/element';
import PFDCAnimalTypeSelectRadiosElement from '../../elements/pfdc-animal-type-select-radios/element';
import PFDCBreedAutosuggestElement from '../../elements/pfdc-breed-autosuggest/element';
import PFDCClipboardElement from '../../elements/pfdc-clipboard/element';
import PFDCCountrySelectElement from '../../elements/pfdc-country-select/element';
import PFDCCreateSavedSearchElement from '../../elements/pfdc-create-saved-search/element';
import { PFDCDispatcherSelectElement } from '../../elements/pfdc-dispatcher/element';
import PFDCElementElement from '../../elements/pfdc-element/element';
import PFDCFavoriteBtnElement from '../../elements/pfdc-favorite-btn/element';
import PFDCFooterElement from '../../elements/pfdc-footer/element';
import PFDCGenericAutosuggestElement from '../../elements/pfdc-generic-autosuggest/element';
import PFDCGenericSelectElement from '../../elements/pfdc-generic-select/element';
import PFDCHeaderElement from '../../elements/pfdc-header/element';
import PFDCHeaderDropdownElement from '../../elements/pfdc-header-dropdown/element';
import PFDCHeaderDropdownResourcesElement from '../../elements/pfdc-header-dropdown-resources/element';
import PFDCHelloBarElement from '../../elements/pfdc-hello-bar/element';
import PFDCSearchMatchCtaElement from '../../elements/pfdc-search-match-cta/element';
import PFDCInputElement from '../../elements/pfdc-input/element';
import PFDCLazyLoadElement from '../../elements/pfdc-lazy-load/element';
import PFDCLoginFormElement from '../../elements/pfdc-login-form/element';
import PFDCMobileMenuElement from '../../elements/pfdc-mobile-menu/element';
import PFDCModalElement from '../../elements/pfdc-modal/element';
import PFDCOptinsElement from '../../elements/pfdc-optins/element';
import PFDCOptionListElement from '../../elements/pfdc-option-list/PFDCOptionListElement';
import PFDCPageControlsElement from '../../elements/pfdc-page-controls/element';
import PFDCPasswordValidatorElement from '../../elements/pfdc-password-validator/element';
import PFDCPetCardElement from '../../elements/pfdc-pet-card/element';
import PFDCPostalCodeInputElement from '../../elements/pfdc-postal-code-input/element';
import PFDCRecommendationsElement from '../../elements/pfdc-recommendations/element';
import PFDCRegistrationFormElement from '../../elements/pfdc-registration-form/element';
import PFDCSavedSearchCarouselNavElement from '../../elements/pfdc-saved-search-carousel-nav/element';
import PFDCSavedSearchListControllerElement from '../../elements/pfdc-saved-search-list-controller/element';
import PFDCSavedSearchCardElement from '../../elements/pfdc-saved-search-card/element';
import PFDCSavedSearchListElement from '../../elements/pfdc-saved-search-list/element';
import PFDCSelectNavElement from '../../elements/pfdc-select-nav/element';
import PFDCSocialSlideElement from '../../elements/pfdc-social-slide/element';
import PFDCStickyElement from '../../elements/pfdc-sticky/element';
import PFDCToggleGroupElement from '../../elements/pfdc-toggle-group/element';
import PFDCSuggesterElement from '../../elements/pfdc-suggester/PFDCSuggesterElement';
import PFDCAdContainerElement from '../../elements/pfdc-ad-container/PFDCAdContainerElement';
import PFDCSuggesterInputElement from '../../elements/pfdc-suggester/PFDCSuggesterInputElement';
import PFDCTooltipControllerElement from '../../elements/pfdc-tooltip-controller/element';

export default function initCustomElements() {
    // // Core
    customElements.define('pf-accordion', PFAccordionElement);
    customElements.define('pf-ad', PFAdElement);
    customElements.define('pf-app', PFAppElement);
    customElements.define('pf-dynamic', PFDynamic);
    customElements.define('pf-element', PFElement);
    customElements.define('pf-ensighten', PFEnsightenElement);

    // // Dotcom
    customElements.define('pfdc-tracking-bridge', PFDCTrackingBridge);
    customElements.define('pfdc-ad', PFDCAdElement);
    customElements.define('pfdc-animal-search', PFDCAnimalSearchElement);
    customElements.define('pfdc-animal-search-filters', PFDCAnimalSearchFiltersElement);
    customElements.define('pfdc-animal-search-optins', PFDCAnimalSearchOptinsElement);
    customElements.define('pfdc-animal-search-results', PFDCAnimalSearchResultsElement);
    customElements.define('pfdc-animal-type-select', PFDCAnimalTypeSelectElement);
    customElements.define('pfdc-animal-type-select-radios', PFDCAnimalTypeSelectRadiosElement);
    customElements.define('pfdc-breed-autosuggest', PFDCBreedAutosuggestElement);
    customElements.define('pfdc-clipboard', PFDCClipboardElement);
    customElements.define('pfdc-country-select', PFDCCountrySelectElement);
    customElements.define('pfdc-create-saved-search', PFDCCreateSavedSearchElement);
    customElements.define('pfdc-dispatcher-select', PFDCDispatcherSelectElement);
    customElements.define('pfdc-element', PFDCElementElement);
    customElements.define('pfdc-favorite-btn', PFDCFavoriteBtnElement);
    customElements.define('pfdc-footer', PFDCFooterElement);
    customElements.define('pfdc-generic-autosuggest', PFDCGenericAutosuggestElement);
    customElements.define('pfdc-generic-select', PFDCGenericSelectElement);
    customElements.define('pfdc-header', PFDCHeaderElement);
    customElements.define('pfdc-header-dropdown', PFDCHeaderDropdownElement);
    customElements.define('pfdc-header-dropdown-resources', PFDCHeaderDropdownResourcesElement);
    customElements.define('pfdc-hello-bar', PFDCHelloBarElement);
    customElements.define('pfdc-search-match-cta', PFDCSearchMatchCtaElement);
    customElements.define('pfdc-input', PFDCInputElement);
    customElements.define('pfdc-lazy-load', PFDCLazyLoadElement);
    customElements.define('pfdc-login-form', PFDCLoginFormElement);
    customElements.define('pfdc-mobile-menu', PFDCMobileMenuElement);
    customElements.define('pfdc-modal', PFDCModalElement);
    customElements.define('pfdc-optins', PFDCOptinsElement);
    customElements.define('pfdc-page-controls', PFDCPageControlsElement);
    customElements.define('pfdc-password-validator', PFDCPasswordValidatorElement);
    customElements.define('pfdc-pet-card', PFDCPetCardElement);
    customElements.define('pfdc-postal-code-input', PFDCPostalCodeInputElement);
    customElements.define('pfdc-recommendations', PFDCRecommendationsElement);
    customElements.define('pfdc-registration-form', PFDCRegistrationFormElement);
    // TODO: Verify remove? Saved search in user settings (part of home-web)
    customElements.define('pfdc-saved-search-card', PFDCSavedSearchCardElement);
    customElements.define('pfdc-saved-search-carousel-nav', PFDCSavedSearchCarouselNavElement);
    customElements.define('pfdc-saved-search-list', PFDCSavedSearchListElement);
    customElements.define('pfdc-saved-search-list-controller', PFDCSavedSearchListControllerElement);
    customElements.define('pfdc-select-nav', PFDCSelectNavElement);
    //
    customElements.define('pfdc-social-slide', PFDCSocialSlideElement);
    customElements.define('pfdc-sticky', PFDCStickyElement);
    customElements.define('pfdc-toggle-group', PFDCToggleGroupElement);
    customElements.define('pfdc-tooltip-controller', PFDCTooltipControllerElement);
    customElements.define(PFDCAnimalIconElement.elementTag, PFDCAnimalIconElement);
    customElements.define(PFDCSuggesterInputElement.elementTag, PFDCSuggesterInputElement);
    customElements.define(PFDCOptionListElement.elementTag, PFDCOptionListElement);
    customElements.define(PFDCSuggesterElement.elementTag, PFDCSuggesterElement);
    customElements.define(PFDCAdContainerElement.elementTag, PFDCAdContainerElement);
}
