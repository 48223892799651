import { html } from '../../../../../core/scripts/util/util';

export default (vm) => {
    return html`
            <div class="filterList-hd-controls-backBtn">
            <button
                pf-mix-click="$closest.onCloseButtonClicked(); $event.stopPropagation();"
                pf-mix-keydown="$closest.onCloseBtnKeyDown($event, $element)"
                aria-label="Back to all filters"
            >
                <span class="icon icon_sm m-icon_colorPrimaryS2">
                    <svg role="img" focusable="false" aria-hidden="true">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevronLeft"></use>
                    </svg>
                </span>
            </button>
        </div>
        <div class="filterList-hd-controls-title">
            ${vm.props.selectionsDisplay(vm)}
        </div>
        <div class="filterList-hd-controls-closeBtn">
            <button
                pf-mix-click="$closest.onToggleMoreFiltersBtnClicked($event, $element);"
                pf-mix-keydown="$closest.onToggleMoreFiltersBtnKeyDown($event, $element)"
            >
                <span class="icon icon_sm m-icon_colorPrimaryS2">
                    <svg role="img" focusable="false" aria-hidden="true">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use>
                    </svg>
                </span>
            </button>
        </div>
    `;
}
