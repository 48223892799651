import { html } from '../../../core/scripts/util/util';

export default vm => {
    return html`
    <div class="m-txt_alignCenter">
        <ul class="hrArray m-hrArray_2x u-vr4x">
            <li name="facebook">
                <a href="http://www.facebook.com/sharer/sharer.php?u=$${vm.state.animalSearch.results.url}"
                target="_blank"
                class="icon icon_socialCircleWhite_lg m-icon_colorWhite"
                title="Share to Facebook"
                aria-label="Share $${vm.state.animalSearch.results.name} to Facebook">
                    <svg role="img" focusable="false">
                        <use xlink:href="#icon-facebook" />
                    </svg>
                </a>
            </li>
            <li name="twitter">
                <a href="http://www.twitter.com/intent/tweet?text=&url=$${encodeURIComponent(vm.state.animalSearch.results.url)}"
                class="icon icon_socialCircleWhite_lg m-icon_colorWhite"
                title="Share to Twitter"
                aria-label="Share $${vm.state.animalSearch.results.name} to Twitter">
                    <svg role="img" focusable="false">
                        <use xlink:href="#icon-twitter" />
                    </svg>
                </a>
            </li>
            <li name="pinterest">
                <a href="http://www.pinterest.com/pin/create/button/?url=$${vm.state.animalSearch.results.url}&description=$${encodeURIComponent(vm.state.animalSearch.results.url)}"
                target="_blank" class="icon icon_socialCircleWhite_lg m-icon_colorWhite"
                title="Share to Pinterest"
                aria-label="Share $${vm.state.animalSearch.results.name} to Pinterest">
                    <svg role="img" focusable="false">
                        <use xlink:href="#icon-pinterest" />
                    </svg>
                </a>
            </li>
            <li name="email">
                <a href="mailto:?subject=$${encodeURIComponent(vm.state.animalSearch.results.name)}&body=$${encodeURIComponent(vm.state.animalSearch.results.url)}"
                class="icon icon_socialCircleWhite_lg m-icon_colorWhite"
                title="Share via email"
                aria-label="Share $${vm.state.animalSearch.results.name} via email">
                    <svg role="img" focusable="false">
                        <use xlink:href="#icon-email" />
                    </svg>
                </a>
            </li>
            <li name="linkShare">
                <pfdc-clipboard
                    command="copy"
                    text="$${vm.state.animalSearch.results.url}">
                    <a id="Petcard_$${vm.state.animalSearch.results.name}_Copy_Link"
                    href="$${vm.state.animalSearch.results.url}"
                    class="icon icon_socialCircleWhite_lg m-icon_colorWhite u-noChildPointerEvents"
                    title="Copy petfinder url to clipboard"
                    aria-label="Copy $${vm.state.animalSearch.results.name} search url to your clipboard"
                    tooltip
                    tooltip-text="Link Copied"
                    tooltip-hide-after="3000"
                    tooltip-position="bottom center"
                    tooltip-y-offset="18"
                    tooltip-z-index="7">
                        <svg role="img" focusable="false">
                            <use xlink:href="#icon-link" />
                        </svg>
                    </a>
                </pfdc-clipboard>
            </li>
        </ul>
    </div>
    `;
};
