import siteStateController from '../../state-controllers/siteStateController';

export default class SearchParamsModel {
    /**
     * @type {string}
     */
    _token = '';

    /**
     * @param {string} value
     */
    set token(value) {
        this._token = value;
    }

    /**
     * @type {string}
     */
    _adoptionStatus = '';

    /**
     * @param {string} value
     */
    set adoptionStatus(value) {
        this._adoptionStatus = value;
    }

    /**
     * @type {string}
     */
    _savedSearch = '';

    /**
     * @param {string} value
     */
    set savedSearch(value) {
        this._savedSearch = value;
    }

    /**
     * Return a collection of the static (non-changing) parameters associated with searching
     */
    get _staticFilterValues() {
        return {
            token: this._token,
            adoptionStatus: this._adoptionStatus,
            savedSearch: this._savedSearch,
        };
    }

    /**
     * Get the animal search params off the state.
     * @method getSearchParams
     * @return {Object} search params
     */
    getSearchParams() {
        // create filter values object
        const searchParams = {};

        // get pagination state and copy values
        Object.assign(searchParams, {
            page: siteStateController.animalSearch.pagination.currentPage + 1,
            limit:
                siteStateController.animalSearch.pagination.state.countPerPage,
        });

        // copy static filter values
        Object.assign(searchParams, this._staticFilterValues);

        // get filters state and copy values
        const filterState = siteStateController.animalSearch.filters.state;
        for (const key in filterState) {
            searchParams[key] = filterState[key].value;
        }

        // manually map animal type
        searchParams.type = siteStateController.animalSearch.filters.animalType;

        // manually mapping transportable here to be string of 0 for backend so we can more
        // easily do the inversion of display for transportable
        searchParams.transportable = siteStateController.animalSearch.filters.transportable ? true : '0';

        return searchParams;
    }
}
