import { html, ifTruthy } from '../../../core/scripts/util/util';

export default vm => {
    if (
        vm.state.animalSearch.results.count <
        vm.state.animalSearch.pagination.countPerPage &&
        !vm.state.ui.resultsLoading
    ) {
        const animalType = vm.state.animalSearch.filters.animalType.value;

        return template(animalType.length > 0 && animalType[0]);
    }

    return '';
};

const template = (animalType = null) => html`
    ${emptyRecommendationModule(animalType)}
    ${ad('SearchResults4', 'petsearch_partial_middle', 'LEADERBOARD, LARGE_MOBILE_BANNER')}
    ${emptyRecommendationModule2()}
`;

const emptyRecommendationModule = (animalType = null) => html`
    <div class="u-vr6x">
        <h2
            pf-recommendations-screen-reader-header
            class="txt txt_h1 m-txt_alignCenter u-isVisuallyHiddenJaws"
            tabindex="-1"
        ></h2>
        <pfdc-recommendations
            class="recommendations recommendations_search s-notObserved"
            component="empty_srp_one"
            show-placeholder
            lazy-load
            template="recommendationsTwoRowSearch"
            placeholder-template="recommendationsTwoRowSearchPlaceholder"
            ${animalType && `animal-type="${animalType}"`}
        >
        </pfdc-recommendations>
    </div>
`;

const emptyRecommendationModule2 = () => html`
    <div>
        <h2
            pf-recommendations-screen-reader-header
            class="txt txt_h1 m-txt_alignCenter u-isVisuallyHiddenJaws"
            tabindex="-1"
        ></h2>
        <pfdc-recommendations
            class="recommendations recommendations_search s-notObserved"
            component="empty_srp_two"
            show-placeholder
            lazy-load
            template="recommendationsTwoRowSearch"
            placeholder-template="recommendationsTwoRowSearchPlaceholder"
        >
        </pfdc-recommendations>
    </div>
`;

const ad = (adId, positionTargetId, adPoolIds = '') => html`
    <div class="u-vr6x">
        <pfdc-ad-container
            slot-id="${adId}"
            position-target-id="${positionTargetId}"
            search-ad
            ${adPoolIds && `ad-pool-ids="${adPoolIds}"`}
            style="max-height: 100px"
        ></pfdc-ad-container>
    </div>
`;
