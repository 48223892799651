import APIService from '../../../../core/scripts/services/APIService';

import Utils from '../../../../core/scripts/lib/wirey/Utils';

import filterToServerMap from '../../constants/animalSearchFilterToServerMap';
import FacetsModel from './FacetsModel';
import Deferred from '../../../../core/scripts/lib/Deferred';

class AnimalSearchAPIService extends APIService {
    static url = '/search/';

    searchesExecuted = 0;

    _lastInitiatedSearchDeferred = null;
    _nextSearchDeferred = null;

    _ensureNextSearchDeferredExists(resetLastInitiated = false) {
        if (!this._nextSearchDeferred) {
            this._nextSearchDeferred = new Deferred();
        }

        // if the last is equal to the next, that means we have a search in flight; do not clear, allow it to be resolved by the next search
        if (
            resetLastInitiated &&
            this._lastInitiatedSearchDeferred !== this._nextSearchDeferred
        ) {
            this._lastInitiatedSearchDeferred = null;
        }
    }

    /**
     * Desired behavior:
     *  - we have no current and no next: create both, assign to same deferred
     *  - we have no current but
     */
    get lastInitiatedSearch() {
        if (!this._lastInitiatedSearchDeferred) {
            this._ensureNextSearchDeferredExists();
            this._lastInitiatedSearchDeferred = this._nextSearchDeferred;
        }

        return this._lastInitiatedSearchDeferred.promise;
    }

    get nextSearch() {
        this._ensureNextSearchDeferredExists();
        return this._nextSearchDeferred.promise;
    }

    // eslint-disable-next-line valid-jsdoc
    /**
     * Find pets
     * @param { { animalType: string, adoptionStatus: string, location: string, offset: number, count: number, filters: [{ filterId: string, value: string|number|boolean|null }] } } searchParams
     */
    async search(searchParams, requestInterceptor) {
        this._ensureNextSearchDeferredExists(true);

        const transformedSearchParams = Utils.ObjectUtils.mapTransform(
            searchParams,
            {},
            filterToServerMap,
            false
        );

        const options = {
            params: transformedSearchParams,
            lateResponseCancelling: true,
        };

        this.searchesExecuted++;
        const response = await this.queryAndProcess(options, requestInterceptor);

        if (response.wasCancelled) {
            return response;
        }

        // remap data to our desired format
        // TODO: use a model!
        if (!response.error) {
            response.data = {
                items: response.data.result.animals,
                totalResults: response.data.result.pagination.total_count,
                totalPages: response.data.result.pagination.total_pages,
                url: response.data.url,
                shareableUrl: response.data.shareableUrl,
                name: response.data.name,
                facets: new FacetsModel(response.data.result.facets),
                summary: response.data.summary,
            };
        } else {
            // TODO: set error state true/add error messaging to search UI
        }

        // NOTE: grabbing a local reference to the next deferred here so we can clear
        // the reference before resolving... potential issues otherwise if we would ever
        // want to chain onto the next from a .then() callback triggered as a result of the
        // resolution of the "next" deferred currently in hand
        const nextSearchDeferred = this._nextSearchDeferred;

        this._nextSearchDeferred = null;
        nextSearchDeferred.resolve(response);

        // NOTE: intentionally doing this AFTER "next" resolution in case any next
        // logic compares against "last" data
        this._lastInitiatedSearchDeferred = nextSearchDeferred;

        return response;
    }
}

const animalSearchAPIService = new AnimalSearchAPIService();
APIService.register('animalSearch', animalSearchAPIService);

export default animalSearchAPIService;
