import PFDCElement from '../pfdc-element/element';

import Utils from '../../../../core/scripts/lib/wirey/Utils';
import RenderManager from '../../../../core/scripts/lib/wirey/RenderManager';

import template from './template.html';
import {
    KEY_DOWN,
    KEY_ARROW_DOWN,
    KEY_UP,
    KEY_ARROW_UP,
    KEY_ESC,
    KEY_ESCAPE,
    KEY_ENTER,
} from '../../constants/keyboard';
import FocusManager from '../../../../core/scripts/lib/FocusManager';
import { isDescendant } from '../../../../core/scripts/util/dom';
import without from 'lodash/without';

const requestNextAnimationFrame = fn =>
    requestAnimationFrame(() => requestAnimationFrame(() => fn()));
// fn => requestAnimationFrame(requestAnimationFrame(fn));

/**
 * Generic custom select
 *
 * This element assumes the following will be available on its view model
 * (which is usually provided by using observe-state)
 *
 * {
 *     options: [
 *         {
 *             label: '',
 *             value: '',
 *         }
 *     ],
 *     value: '',
 * }
 */

export class PFDCGenericSelectElement extends PFDCElement {
    static _elementTag = 'pfdc-generic-select';

    static template = template;

    /**
     * Getters / Config
     */

    /**
     * Render with morphdom instead of innerHTML.
     *
     * Focus management and screenreaders operate on persistent DOM state.
     * Replacing innerHTML blows away that state (ie. whatever was being read, focus, allyjs)
     *
     * innerHTML also causes infinite loops with pf-focus-manager which
     * assumes a diffing algo that only adds/removes stuff when nessecary.
     */
    get renderFunction() {
        return this.renderImmediateMorphdom;
    }

    get viewModel() {
        return {
            ...super.viewModel,
            selectedValues: this.selectedValues,
            selectedLabels: this.selectedLabels.length
                ? this.selectedLabels
                : [],
            selectedIndices: this.selectedIndices.length
                ? this.selectedIndices
                : [],
            canSelectMultiple: this.canSelectMultiple,
            menuIsOpenOrStillAnimating:
                this.menuIsOpen || this.localState.menuIsAnimating,
            ariaDescribedById: this.ariaDescribedById,
            inputAriaLabel: this.inputAriaLabel,
            sortBySelectLabel: this.sortBySelectLabel,
            placeholder: this.placeholder,
            themeClass: this.themeClass,
            id:
                this.id.toLowerCase() ||
                this.tagName === 'PFDC-TOGGLE-GROUP' ||
                console.error('element requires id:', this),
        };
    }

    get menuIsOpen() {
        return this.localState.menuIsOpen;
    }

    get currentAvailableOptions() {
        return this.observedState.options;
    }

    get allAvailableOptions() {
        return this.currentAvailableOptions;
    }

    /**
     * @method selectedValues
     * @return {Array}
     */
    get selectedValues() {
        return this.observedState.value;
    }

    /**
     * @method selectedLabels
     * @return {Array}
     */
    get selectedLabels() {
        return this.selectedValues
            .map(value =>
                this.allAvailableOptions.find(option => option.value === value)
            )
            .filter(option => option && 'label' in option)
            .map(option => option.label);
    }

    /**
     * @method selectedIndices
     * @return {Array}
     */
    get selectedIndices() {
        return this.selectedValues
            .map(value =>
                this.allAvailableOptions.findIndex(
                    option => option.value === value
                )
            )
            .filter(value => value > -1);
    }

    get defaultLocalState() {
        return {
            menuIsOpen: false,
            menuIsAnimating: false,

            // if an outside element is activated, don't do any more focusing
            // so we don't steal their focus.
            focusIsAllowed: true,

            highlightedIndex: this.selectedIndices[0] || -1,
        };
    }

    // In case displayed options are filtered.
    // All by default.
    get displayedOptions() {
        return this.currentAvailableOptions;
    }

    get selectedValueFormat() {
        return this.getAttribute('generic-select-selected-value-format');
    }

    get customSelectedValueText() {
        const selectedValueFormat = this.selectedValueFormat;

        if (!selectedValueFormat) {
            return '';
        }

        const searchRegex = /::([a-zA-Z0-9.]+)::/g;
        const selectedValueText = Utils.StringUtils.keyedReplace(
            selectedValueFormat,
            searchRegex,
            1,
            this.viewModel
        );

        return selectedValueText;
    }

    get highlightedItem() {
        return this.displayedOptions[this.localState.highlightedIndex] || null;
    }

    /**
     * Attribute getters
     */

    get canSelectMultiple() {
        return this.hasAttribute('select-multiple');
    }

    get placeholder() {
        return this.getAttribute('select-placeholder') || '';
    }

    get sortBySelectLabel() {
        return this.getAttribute('sort-by-select-label') || false;
    }

    get menuAnimationTime() {
        return this.getAttribute('menu-animation-time') || 0;
    }

    get inputAriaLabel() {
        return this.getAttribute('input-aria-label') || '';
    }

    get ariaDescribedById() {
        return this.getAttribute('aria-describedby-id') || '';
    }

    get themeClass() {
        return this.getAttribute('select-theme-class') || '';
    }

    setListElement(ele) {
        this.listElement = ele;
    }

    setScrollElement(ele) {
        this.scrollElement = ele;
    }

    // =======================================================
    // Custom Element Lifecycle
    // =======================================================

    /**
     * Emulates dom select API, where parent can listen for change events.
     * @property value
     * @type {}
     */
    value = null;

    onDocumentClickedHandler = this.onDocumentClicked.bind(this);

    listElement = null;
    scrollElement = null;

    onConnected() {
        this.value = this.observedState.value;
        this.focusManager = new FocusManager();
        super.onConnected();
    }

    // =======================================================
    // Event Handlers
    // =======================================================

    /**
     * Handler for native html select shown to mobile users.
     *
     * @method onNativeSelectChanged
     * @param {Object} ev
     * @param {HTMLElement} element
     */
    onNativeSelectChanged(ev, element) {
        ev.preventDefault();
        ev.stopPropagation();

        // select tags don't store multi values >:(
        // so we get them manually
        const selectedValues = Array.from(element.children).reduce(
            (acc, el) => (el.selected ? acc.concat(el.value) : acc),
            []
        );

        // if "any" placeholder is selected
        const selectedValuesOrEmpty =
            selectedValues[0] === '' ? [] : selectedValues;

        this.dispatchValueChange(selectedValuesOrEmpty);
    }

    /**
     * Upon detecting outside click, close menu, stop further focusing.
     * We're doing it globally since focusout's relatedTarget doesnt work
     * consistently across browsers.
     *
     * @method onDocumentClicked
     * @param {Object} ev
     */
    onDocumentClicked(ev) {
        const { target } = ev;

        if (target && this.menuIsOpen && !isDescendant(this, target)) {
            const focusTarget = this.focusManager.getFocusTarget(target);

            if (!focusTarget) {
                console.warn('Unable to find focusTarget:', focusTarget);
            }

            const targetIsFocusable =
                focusTarget &&
                this.focusManager.isElementFocusable(focusTarget);
            const newFocusIsAllowedValue = !targetIsFocusable;

            this.patchLocalState({
                focusIsAllowed: newFocusIsAllowedValue,
            });

            // TODO: marked for deletion; this portion is particularly suspect, as it's quite intentionally delaying for multiple frames...
            // TODO: if the alternate code below doesn't cause issues (accessibility, focus, etc.), this can be safely removed
            // requestAnimationFrame(() =>
            //     requestAnimationFrame(() => {
            //         this.closeMenu();

            //         requestAnimationFrame(() => {
            //             requestNextAnimationFrame(() => {
            //                 this.patchLocalState({
            //                     focusIsAllowed: true,
            //                 });
            //             })
            //         });

            //     })
            // );

            this.closeMenu();

            if (newFocusIsAllowedValue !== true) {
                this.patchLocalState({
                    focusIsAllowed: true,
                });
            }
        }
    }

    /**
     * Click handler for menu options.
     *
     * Dropdown lists bind this to `mouseup` as to not recieve
     * click events from the enter key, which we handle via onSelectKeyup.
     *
     * @method onItemClicked
     * @param {Object} ev
     * @param {HTMLElement} element
     * @param {Number} itemIndex
     */
    onItemClicked(ev, element, itemValue) {
        ev.stopPropagation();
        this.selectItemByValue(unescape(itemValue));
    }

    /**
     * Click handler for removing items. (eg. shelter chiclet tags)
     *
     * @method onItemClicked
     * @param {Object} ev
     * @param {HTMLElement} element
     * @param {Number} itemIndex
     */
    // TODO: itemIndex is no longer being used and can be refactored out if desired
    onRemoveItemClicked(ev, element, itemIndex, itemValue) {
        ev.stopPropagation();
        this.selectItemByValue(unescape(itemValue));
    }

    /**
     * @method onItemHovered
     * @param {Object} ev
     * @param {HTMLElement} element
     * @param {Number} itemIndex
     */
    onItemHovered(ev, element, itemIndex) {
        this.highlightIndex(itemIndex, true);
    }

    /**
     * @method onItemFocused
     * @param {Object} ev
     * @param {HTMLElement} element
     * @param {Number} itemIndex
     */
    onItemFocused(ev, element, itemIndex) {
        this.highlightIndex(itemIndex, true);
    }

    /**
     * Click handler for optional clear button.
     *
     * @method onClearBtnClicked
     * @param {Object} ev
     * @param {HTMLElement} element
     */
    onClearBtnClicked(ev, element) {
        ev.preventDefault();
        ev.stopPropagation();

        this.clearSelectedValues();
    }

    /**
     * Handles keyboard activation of clear button.
     *
     * @param {Object} ev
     * @param {HTMLElement} element
     */
    onClearBtnKeyDown(ev, element) {
        if (ev.key === KEY_ENTER) {
            this.clearSelectedValues();
            ev.stopPropagation();
        }
    }

    /**
     * On click within the select
     *
     * @param {Event} e
     */
    onClickEvent(e) {
        this.openSelectMenu(e);
    }

    /**
     * On key down from within the select
     *
     * @param {Event} e
     */
    onKeydownEvent(e) {
        this.processKeyEvent(e);
    }

    /**
     * Click handler for button that opens a menu.
     *
     * @method onOpenBtnClicked
     * @param {Event} e
     */
    openSelectMenu(e) {
        if (this.menuIsOpen) {
            this.closeMenu();
        } else {
            this.openMenuAndHighlightSelectedItem();
        }
    }

    /**
     * @method onSelectKeyup
     * @param {Object} ev
     */
    processKeyEvent(ev) {
        switch (ev.key) {
            case KEY_ESC:
            case KEY_ESCAPE:
                this.handleEscKey();
                return;
            case KEY_ENTER:
                ev.preventDefault();
                ev.stopPropagation();
                this.selectedElement = document.activeElement; // necessary for handling screen reader focus after change render
                this.openSelectOrSelectItem();
                return;
            case KEY_UP:
            case KEY_ARROW_UP:
                ev.preventDefault();
                this.incrementHighlightedItem(-1);
                return;
            case KEY_DOWN:
            case KEY_ARROW_DOWN:
                ev.preventDefault();
                this.incrementHighlightedItem(1);
                return;
        }
    }

    handleEscKey() {
        if (this.menuIsOpen) {
            this.closeMenu();
        }
    }

    openSelectOrSelectItem() {
        if (this.menuIsOpen) {
            this.selectDisplayedItemByIndex(this.localState.highlightedIndex);
        } else {
            this.openMenuAndHighlightSelectedItem();
        }
    }

    // =======================================================
    // State Updates / Actions
    // =======================================================

    /**
     * @method selectDisplayedItemByIndex
     * @param {Number} itemIndex
     */
    selectDisplayedItemByIndex(itemIndex) {
        if (itemIndex === -1) {
            return;
        }
        const selectedOption = this.displayedOptions[itemIndex];

        if (!selectedOption) {
            console.error(
                `Selected option not found for index "${itemIndex}"`,
                this.displayedOptions
            );
            return;
        }

        this.selectItemByValue(selectedOption.value);
    }

    /**
     * @method ensureValueSelectedIfAvailable
     */
    ensureValueSelectedIfAvailable() {
        // if the menu isn't open, leave things as they are
        if (!this.menuIsOpen) {
            return;
        }

        // attempt to get the highlighted item
        let itemToSelect = this.highlightedItem;

        // if no highlighted item, attempt to get the first item
        if (!itemToSelect) {
            if (this.displayedOptions[0]) {
                itemToSelect = this.displayedOptions[0];
            } else {
                return;
            }
        }

        this.selectItemByValue(itemToSelect.value);
    }

    /**
     * @method selectItemByValue
     * @param {String} itemValue
     */
    selectItemByValue(itemValue) {
        const newValue = this.canSelectMultiple
            ? this.multiSelectStateFromValue(itemValue)
            : itemValue;

        // TODO: temporary hack for hotfix -- we need to prevent re-focus ONLY when a selection has been made for elements
        // with the attribute present indicating such a behavior should occur; fix this in the future
        if (this.hasAttribute('prevent-return-focus-on-change')) {
            this.querySelector('pf-focus-manager').removeAttribute(
                'return-focus-to'
            );
        }

        this.dispatchValueChange(newValue);
    }

    /**
     * Toggles selection of item in this.selectedValues
     * Returns new array.
     *
     * @method selectItemByValue
     * @param {String} itemValue
     * @return {Array} newSelectedValues - Array of String
     */
    multiSelectStateFromValue(itemValue) {
        const selectedValues = this.selectedValues;

        const index = selectedValues.indexOf(itemValue);
        const newSelectedValues =
            index === -1
                ? selectedValues.concat([itemValue]) // add
                : without(selectedValues, itemValue); // remove

        return newSelectedValues;
    }

    /**
     * @method selectItemByValue
     * @param {Array} newValue - Array of String
     */
    dispatchValueChange(newValue) {
        // ensure array
        if (newValue) {
            this.value = Array.isArray(newValue) ? newValue : [newValue];
        } else {
            this.value = [];
        }

        // alert parent observer of change
        const changeEvent = new Event('change');
        changeEvent.selected = newValue;
        this.dispatchEvent(changeEvent);

        this.afterDispatchValueChange();
    }

    /**
     * @method afterDispatchValueChange
     */
    afterDispatchValueChange() {
        if (this.canSelectMultiple) {
            return;
        }
        this.closeMenu();
    }

    /**
     * @method clearSelectedValues
     */
    clearSelectedValues() {
        this.dispatchValueChange([]);
    }

    /**
     * @method incrementHighlightedItem
     * @param {Number} offset
     */
    incrementHighlightedItem(offset) {
        const currentIndex = this.localState.highlightedIndex;
        let newIndex = currentIndex + offset;
        if (newIndex < 0) {
            newIndex = this.displayedOptions.length - 1;
        } else if (newIndex >= this.displayedOptions.length) {
            newIndex = 0;
        }

        this.highlightIndex(newIndex);
    }

    /**
     * @method highlightIndex
     * @param {Number} newIndex
     * @param {Bool} skipFocus
     */
    highlightIndex(newIndex, skipFocus = false) {
        this.patchLocalState({
            highlightedIndex: newIndex,
        });

        if (skipFocus) {
            return;
        }

        this.focusHighlightedItem(newIndex);
    }

    /**
     * @method focusHighlightedItem
     * @param {Number} newIndex
     */
    focusHighlightedItem(newIndex) {
        const listElement = this.querySelector(`#${this.id}_List_Box`);
        if (listElement && listElement.children.length > 0) {
            const elToFocus = listElement.children[newIndex];
            if (elToFocus) {
                this.focusManager.focusFirstFocusable(elToFocus);
            }
        }
    }

    /**
     * Open menu and focus first item.
     *
     * @method openMenuAndHighlightSelectedItem
     */
    openMenuAndHighlightSelectedItem() {
        this.openMenu(() => {
            const indexToHighlight = this.selectedIndices.length
                ? this.selectedIndices[0]
                : 0;

            this.highlightIndex(indexToHighlight);
        });
    }

    /**
     * Akin to React's CSSTransitionGroup.
     * - toggle element on `menuIsOpenOrStillAnimating`
     * - toggle animated css class on `menuIsOpen`
     *
     * @method setMenuIsOpenToAnimated
     * @param {Bool} bool
     * @param {Function} callback
     */
    setMenuIsOpenToAnimated(bool, callback) {
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
            this.timeoutID = undefined;
        }

        this.patchLocalState({
            menuIsAnimating: true,
        });
        // wait for next frame so the DOM sees that
        // a CSS class (using this condition) is 'added'.
        requestAnimationFrame(() =>
            requestAnimationFrame(() => {
                this.patchLocalState({
                    menuIsOpen: bool,
                });

                // TODO: utilize CSS Transition end event
                this.timeoutID = setTimeout(() => {
                    this.patchLocalState({ menuIsAnimating: false });
                    if (typeof callback === 'function') {
                        requestAnimationFrame(() => callback());
                    }
                    this.timeoutID = undefined;
                }, this.menuAnimationTime);
            })
        );
    }

    /**
     * open/close without animation.
     *
     * @method setMenuIsOpenTo
     * @param {Bool} bool
     * @param {Function} callback
     */
    setMenuIsOpenTo(bool, callback) {
        this.patchLocalState({
            menuIsOpen: bool,
        });

        // TODO: if the use of afterNextRender() turns up no bugs, remove this
        // if (typeof callback === 'function') {
        //     requestAnimationFrame(() =>
        //         requestAnimationFrame(() => callback())
        //     );
        // }

        if (callback) {
            RenderManager.afterNextRender(callback);
        }
    }

    /**
     * @method openMenu
     * @param {Function} callback - optional
     */
    openMenu(callback = null) {
        this.setMenuIsOpenTo(true, callback);

        document.addEventListener('click', this.onDocumentClickedHandler, true);
    }

    /**
     * @method closeMenu
     * @param {Function} callback - optional
     */
    closeMenu(callback = null) {
        this.setMenuIsOpenTo(false, callback);

        document.removeEventListener(
            'click',
            this.onDocumentClickedHandler,
            true
        );
    }
}

export default PFDCGenericSelectElement;
