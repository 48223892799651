export default vm => {
    // sm-md
    return `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Breed</div>
        <pfdc-breed-autosuggest
            id="breed-autosuggest"
            pf-mix-id="breed-autosuggest"
            pf-mix-change="$closest.onBreedChanged($event.selected)"
            observe-state="animalSearch.filters.breed"
            select-multiple
            input-aria-label="Search By Breed"
            autosuggest-placeholder="Search By Breed"
            autosuggest-unit-label-singular="Breed"
            autosuggest-unit-label-plural="Breeds"
        ></pfdc-breed-autosuggest>
    `;
};
