import APIService from '../../../../core/scripts/services/APIService';
import APIResponse from '../../../../core/scripts/services/APIResponse';

class BreedsAPIService extends APIService {
    static url = '/pet-search/breeds/';

    async getBreedsForSpecies(animalTypeSlug, speciesName) {
        // both fields are required
        if (!animalTypeSlug || !speciesName) {
            return new APIResponse(
                null,
                `Not all required params provided: "animalTypeSlug: ${animalTypeSlug}", speciesName: "${speciesName}"`
            );
        }

        const options = {
            method: 'GET',
            params: {
                typeSlug: animalTypeSlug,
                species: speciesName,
            },
            lateResponseCancelling: true,
        };

        const response = await this.queryAndProcess(options);

        if (response.data && response.data.options) {
            response.data = response.data.options;
        }

        return response;
    }
}

const instance = new BreedsAPIService();
APIService.register('breeds', instance);

export default instance;
