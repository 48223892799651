export default vm => {
    // sm-md
    return `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Species</div>
        <pfdc-generic-select
            id="species-select"
            pf-mix-change="$closest.onSpeciesChanged($element.value)"
            observe-state="animalSearch.filters.species"
            input-aria-label="Select Species"
            select-placeholder="Any"
        ></pfdc-generic-select>
    `;
};
