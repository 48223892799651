import APIService from '../../../../core/scripts/services/APIService';
import APIResponse from '../../../../core/scripts/services/APIResponse';

import Utils from '../../../../core/scripts/lib/wirey/Utils';

import filterToServerMap from '../../constants/animalSearchFilterToServerMap';

class AnimalSearchFiltersAPIService extends APIService {
    static url = '/pet-search/filter-options/';

    async getFilters(searchFilters) {
        const animalType = searchFilters.animalType[0];
        const location = searchFilters.location[0];

        try {
            const options = {
                params: {
                    animalTypeSlug: animalType,
                    locationSlug: location,
                },
            };

            // TODO: this should probably be converted to use queryAndProcess()
            const response = await this.query(options);

            const json = await response.json();

            const transformedFilters = Utils.ObjectUtils.mapTransform(
                json.filters,
                {},
                filterToServerMap,
                true
            );

            return new APIResponse(transformedFilters);
        } catch (err) {
            return new APIResponse(null, err);
        }
    }
}

const instance = new AnimalSearchFiltersAPIService();
APIService.register('animalSearchFilters', instance);

export default instance;
