import PFDCElement from '../pfdc-element/element';
import template from './template.html';

/**
 * # `pfdc-social-slide` Element
 *
 * Creates a share button that slides open to a tray of circular social buttons.
 */

const COMPONENT_TAG_NAME = 'buttonSlide-tray';
const ATTR_SHARE_BTNS = `${COMPONENT_TAG_NAME}-social-link`;
const SEL_SHARE_BTNS = `[${ATTR_SHARE_BTNS}]`;

export class PFDCSocialSlideElement extends PFDCElement {
    static _elementTag = 'pfdc-social-slide';

    static template = template;

    // Render with morphdom instead of innerHTML to enable slide animation
    get renderFunction() {
        return this.renderImmediateMorphdom;
    }

    get socialButtons() {
        return this.querySelectorAll(SEL_SHARE_BTNS);
    }

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            trayIsOpen: false,
        };
    }

    get viewModel() {
        return {
            ...super.viewModel,
            ariaHidden: this.ariaHidden,
            tabIndex: this.tabIndex,
            petTemplate: this.hasAttribute('pet-template'),

            shareName: this.getAttribute('share-name'),
            facebookSharingUrl: this.getAttribute('facebook-sharing-url'),
            twitterSharingUrl: this.getAttribute('twitter-sharing-url'),
            pinterestSharingUrl: this.getAttribute('pinterest-sharing-url'),
            emailTitle: this.getAttribute('email-title'),
            emailBody: this.getAttribute('email-body'),
            clipboardUrl: this.getAttribute('clipboard-url'),
        };
    }

    get ariaHidden() {
        if (!this.localState.trayIsOpen) {
            return 'true';
        } else {
            return 'false';
        }
    }

    get tabIndex() {
        if (!this.localState.trayIsOpen) {
            return -1;
        } else {
            return '';
        }
    }

    onInit() {
        super.onInit();
    }

    toggleMenu(ev, element) {
        this.patchLocalState({
            trayIsOpen: !this.localState.trayIsOpen,
        });
    }
}

export default PFDCSocialSlideElement;
