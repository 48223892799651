import { html } from '../../../core/scripts/util/util';

// prettier-ignore
export default vm => {
    return html`
        ${searchInput(vm)}
        ${optionsList(vm)}
    `;
};

// prettier-ignore
const searchInput = vm => {
    const state = vm.state;
    const locationFilterSet = vm.observedState.value[0] || false;
    const locationDisplayName = state.animalSearch.results.searchSummary.searchLocationName;
    return html`
        <div class="field field_animalSearchHeader u-isHidden@minMd">
            <div class="field-input">
                ${!locationFilterSet ? 'No location' : locationDisplayName}
            </div>
        </div>
        <div class="field field_animalSearchHeader u-isHidden@maxMd">
            <p id="${vm.id}_Description" class="u-isVisuallyHidden" aria-hidden="true">This is an autocomplete field. We will populate or filter a list of suggestions as you type. We will notify you of the number of suggestions that match your query as you type. If suggestions are found, press the tab key to focus the suggestions list. Select a suggestion item to set it as the value of this field. Press escape in the suggestions list to close the list and return to the search input field.</p>
            <input
                ${vm.id}-search-input
                id="${vm.id}_SearchInput"
                class="field-input"
                pf-mix-change="$event.stopPropagation()"
                pf-mix-click="$event.stopPropagation()"
                pf-mix-keydown="$closest.onSearchInputKeydown($event, $element)"
                pf-mix-keyup="$closest.onSearchInputKeyup($event, $element)"
                pf-mix-input="$closest.onSearchInputted($event, $element)"
                pf-mix-focus="$closest.openMenu(null)"
                value="${vm.localState.searchText}"
                placeholder="${vm.placeholder}"
                type="text"
                name="location"
                spellcheck="false"
                aria-label="${vm.inputAriaLabel}"
                aria-describedby="${vm.id}_Description">
        </div>
    `;
};

// prettier-ignore
const optionsList = vm => {
    if (vm.localState.loading) {
        return html`
            <div class="optionsList m-optionsList_offsetBottomSm m-optionsList_pointerTopLeft m-optionsList_full m-optionsList_rounded m-optionsList_offset">
                <ul autocomplete-list class="optionsList-list">
                    <li class="optionsList-item ">Loading...</li>
                </ul>
            </div>
        `;
    }
    if (vm.localState.noResults) {
        return html`
            <div class="optionsList m-optionsList_offsetBottomSm m-optionsList_pointerTopLeft m-optionsList_full m-optionsList_rounded m-optionsList_offset">
                <ul autocomplete-list class="optionsList-list">
                    <li class="optionsList-item">No ${vm.unitLabelPlural.toLowerCase()} found</li>
                </ul>
            </div>
        `;
    }
    if (vm.displayedOptions.length) {
        if (vm.localState.menuIsOpen) {
            return html`
                <div class="optionsList m-optionsList_offsetBottomSm m-optionsList_pointerTopLeft m-optionsList_full m-optionsList_rounded m-optionsList_offset">
                    <ul autocomplete-list class="optionsList-list">
                        ${vm.displayedOptions.map((option, index) => html`
                            ${optionsListItem(vm, option, index)}
                        `)}
                    </ul>
                </div>
            `;
        } else {
            return '';
        }
    }
};

// prettier-ignore
const optionsListItem = (vm, option, index) => html`
    <li class="optionsList-item ${vm.selectedValues.includes(option.value) ? 'isActive' : ''}">
        <button pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}')" type="button">
            ${option.label}
        </button>
    </li>
`;
