import { html } from '../../../../../core/scripts/util/util';

// headers
import filtersHeaderSm from './filters-header-sm.html';
import filtersBodySmMd from './filters-body-sm-md.html';

export default vm => {
    const headerHtml = filtersHeaderSm(vm);
    const bodyHtml = (vm.localState.showMoreFilters)
        ? filtersBodySmMd(vm)
        : '';

    return html`
        ${headerHtml}
        ${bodyHtml}
    `;
};
