import { PFDCGenericAutosuggestElement } from '../pfdc-generic-autosuggest/element';

import template from './template.html';
import { animalTypeBreedLabelMap } from '../../constants/maps.js';

// TODO: Deprecate this custom element and use <pfdc-generic-autosuggest> with a custom
// template in frontend/src/dotcom/scripts/templates/
export class PFDCBreedAutosuggestElement extends PFDCGenericAutosuggestElement {
    static _elementTag = 'pfdc-breed-autosuggest';
    static template = template;

    get viewModel() {
        return {
            ...super.viewModel,
            // TODO: can this be moved to be within a template somewhere?
            animalTypeLabel:
                animalTypeBreedLabelMap[
                    this.stateController.animalSearch.filters.animalType
                ],
        };
    }

    onItemClicked(ev, element, itemValue) {
        ev.stopPropagation();
        this.selectedElement = element;
        this.selectItemByValue(unescape(itemValue));
    }

    // overriding parent class; used to delay screen reader descriptions until after search render
    afterDispatchValueChange() {
        requestAnimationFrame(() =>
            requestAnimationFrame(() => {
                this.selectedElement.focus();
            })
        );
    }
}

export default PFDCBreedAutosuggestElement;
