import { html } from '../../../../core/scripts/util/util';

export default vm => {
    // Construct aria-label for pagination. Ex. "Select page, PAGE 1/4"
    const paginationAriaLabel = `Select page, PAGE ${vm.state.pagination.currentPageDisplay}/${vm.state.pagination.totalPages}`;

    return html`
        <div class="grid grid_gutterLg">
            <div class="grid-col grid-col_1/3 u-isHidden@maxMd">
                ${(!vm.isFirstPage) ? html`
                    <button class="fieldBtn fieldBtn_altHover m-fieldBtn_tight m-fieldBtn_full" pf-mix-click="$closest.goToPreviousPage();pageChangeScrollTarget.scrollIntoView(true);pageChangeFocusTarget.focus();trackingBridge.ensighten('Consumer071');">
                        <span class="fieldBtn-icon">
                            <span class="icon icon_sm m-icon_colorPrimary">
                                <svg role="img">
                                    <use xlink:href="#icon-chevronLeft"/>
                                </svg>
                            </span>
                        </span>
                        <span class="fieldBtn-label">Previous</span>
                    </button>
                ` : ''}
            </div>

            <div class="grid-col grid-col_1/2 grid-col_1/3@minMd">
                ${(!vm.isOnlyPage) ? html`
                    <pfdc-generic-select
                        id="page-select"
                        select-theme-class="animalSearchSelect_checkmarks"
                        pf-mix-change="$closest.goToPage($element.value.0);pageChangeScrollTarget.scrollIntoView(true);pageChangeFocusTarget.focus();trackingBridge.ensighten('Consumer072');"
                        observe-state="animalSearch.pagination"
                        aria-label="${paginationAriaLabel}"
                        input-aria-label="${paginationAriaLabel}"
                        generic-select-selected-value-format="PAGE ::observedState.currentPageDisplay::/::observedState.totalPages::"
                        prevent-return-focus-on-change
                    >
                    </pfdc-generic-select>
                ` : ''}
            </div>

            <div class="grid-col grid-col_1/2 grid-col_1/3@minMd">
                ${(!vm.isLastPage) ? html`
                    <button class="fieldBtn fieldBtn_altHover m-fieldBtn_iconRt m-fieldBtn_tight m-fieldBtn_full" type="button" pf-mix-click="$closest.goToNextPage();pageChangeScrollTarget.scrollIntoView(true);pageChangeFocusTarget.focus();trackingBridge.ensighten('Consumer070');">
                        <span class="fieldBtn-label">Next</span>
                        <span class="fieldBtn-icon">
                            <span class="icon icon_sm m-icon_colorPrimary">
                                <svg role="img">
                                    <use xlink:href="#icon-chevronRight"/>
                                </svg>
                            </span>
                        </span>
                    </button>
                ` : ''}
            </div>
        </div>
    `;
};
