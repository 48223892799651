import { PFDCGenericSelectElement } from '../pfdc-generic-select/element';

import template from './template.html';
import {
    KEY_DOWN,
    KEY_ARROW_DOWN,
    KEY_UP,
    KEY_ARROW_UP,
    KEY_ESC,
    KEY_ESCAPE,
    KEY_ENTER,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../constants/keyboard';

/**
 * Generic custom select
 *
 * This element assumes the following will be available on its view model
 * (which is usually provided by using observe-state)
 *
 * {
 *     options: [
 *         {
 *             label: '',
 *             value: '',
 *         }
 *     ],
 *     value: '',
 * }
 */

export class PFDCToggleGroupElement extends PFDCGenericSelectElement {
    static _elementTag = 'pfdc-toggle-group';

    static template = template;

    // Render with morphdom instead of innerHTML.
    get renderFunction() {
        return this.renderImmediateMorphdom;
    }

    get viewModel() {
        return {
            ...super.viewModel,
            legend: this.getAttribute('toggle-group-legend') || '',
            underline: this.hasAttribute('toggle-group-underline'),
            multiline: this.hasAttribute('toggle-group-multi-line'),
        };
    }
}

export default PFDCToggleGroupElement;
