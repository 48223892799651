import PFDCElement from '../pfdc-element/element';
import ensightenTracking from '../../../../core/scripts/elements/pf-app/ensighten/ensighten';

import template from './template.html';
import { html } from '../../../../core/scripts/util/util';
import { KEY_ENTER } from '../../constants/keyboard';

import animalSearchFiltersStateController from '../../state-controllers/animal-search/animalSearchFiltersStateController';

/**
 * Animal Search Filters
 *
 */

export class PFDCAnimalSearchFiltersElement extends PFDCElement {
    static _elementTag = 'pfdc-animal-search-filters';
    static template = template;

    get viewModel() {
        return {
            ...super.viewModel,
            totalResults: this.stateController.animalSearch.results
                .totalResults,
            selectedAnimalTypeLabel: this.stateController.animalSearch.filters.selectedLabelsFor(
                'animalType'
            ),
            transportHref: this.dataset.transportHref,
        };
    }

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            currentBreakpoint: this.state.currentBreakpoint,
            showMoreFilters: false,
            moreLessFiltersToggled: this._moreLessFiltersToggled,
        };
    }

    get shouldForceScreenreaderDomRefresh() {
        return true;
    }

    onBreakpointChanged(currentBreakpoint) {
        this.patchLocalState({
            currentBreakpoint,
        });
    }

    // =======================================================
    // Event Handlers
    // =======================================================

    ensighten(eventId) {
        ensightenTracking[`event${eventId}`]();
    }

    onToggleMoreFiltersBtnClicked() {
        this.toggleMoreFilters();
    }

    /**
     * Handles keyboard activation of toggle button.
     *
     * @param {Object} ev
     * @param {HTMLElement} element
     */
    onToggleMoreFiltersBtnKeyDown(ev, element) {
        if (ev.key === KEY_ENTER) {
            this.toggleMoreFilters();
        }
    }

    onResetClicked() {
        animalSearchFiltersStateController.clearAllUserFilters();
        ensightenTracking.eventConsumer063({ source: 'modal' });
    }

    // =======================================================
    // State Updates / Actions
    // =======================================================

    toggleMoreFilters() {
        this.patchLocalState({
            moreLessFiltersToggled: true,
            showMoreFilters: !this.localState.showMoreFilters,
        });
    }
}

export default PFDCAnimalSearchFiltersElement;
