import TemplateRegistry from '../../../../core/scripts/lib/wirey/TemplateRegistry';
import animalSearchResultsTemplate from '../../templates/animalSearchResults.html';
import animalSearchFooterTemplate from '../../templates/animalSearchFooter.html';
import shelterSelectorTemplate from '../../templates/shelterSelectorTemplate.html';
import colorSelectorTemplate from '../../templates/colorSelectorTemplate.html';
import createPetSearchSlideoutsTemplate from '../../templates/createPetSearchSlideoutsTemplate.html';
import createPetSearchCardTemplate from '../../templates/createPetSearchCardTemplate.html';
import createPetSearchModalTemplate from '../../templates/createPetSearchModalTemplate.html';
import locationAutosuggestModalTemplate from '../../templates/locationAutosuggestModalTemplate.html';
import locationAutosuggestHeaderTemplate from '../../templates/locationAutosuggestHeaderTemplate.html';
import loadingResultsIndicatorTemplate from '../../templates/loadingResultsIndicatorTemplate.html';
import srpRecommendationsTemplate from '../../templates/srpRecommendations.html';
import countryNotListedTemplate from '../../templates/countryNotListed.html';

export default function initWireyTemplates() {
    TemplateRegistry.register(
        'animalSearchResults',
        animalSearchResultsTemplate
    );
    TemplateRegistry.register('animalSearchFooter', animalSearchFooterTemplate);
    TemplateRegistry.register('shelterSelector', shelterSelectorTemplate);
    TemplateRegistry.register('colorSelector', colorSelectorTemplate);
    TemplateRegistry.register(
        'createPetSearchSlideouts',
        createPetSearchSlideoutsTemplate
    );
    TemplateRegistry.register(
        'createPetSearchCard',
        createPetSearchCardTemplate
    );
    TemplateRegistry.register(
        'createPetSearchModal',
        createPetSearchModalTemplate
    );
    TemplateRegistry.register(
        'locationAutosuggestModal',
        locationAutosuggestModalTemplate
    );
    TemplateRegistry.register(
        'locationAutosuggestHeader',
        locationAutosuggestHeaderTemplate
    );
    TemplateRegistry.register(
        'loadingResultsIndicator',
        loadingResultsIndicatorTemplate
    );
    TemplateRegistry.register('srpRecommendations', srpRecommendationsTemplate);
    TemplateRegistry.register('countryNotListed', countryNotListedTemplate);
}
