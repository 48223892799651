import ContextContainer from '../../../core/scripts/context';
import { getRandomItemsFromArray } from '../../../core/scripts/util/util';

/**
 * Reference to default parameters for the wp api pet-search-results
 * We want a single random result
 * @type {Object}
 */
const CONTENT_TOUT_DEFAULT_PARAMS = {
    'filter[feature-type]': 'pet-search-results',
    per_page: 50, // eslint-disable-line
};

export default class ContentToutProvider {
    static async getContentTout(animalType) {
        const params = {};
        if (animalType) {
            params['filter[pet-type]'] = animalType;
        }

        // TODO: should this be here?
        // Get content tout
        const contentToutParams = Object.assign(
            {},
            CONTENT_TOUT_DEFAULT_PARAMS,
            params
        );

        const contentToutResponse = await ContextContainer.wordpressRepository.getContentTouts(
            contentToutParams
        );

        // If there are no touts, return undefined
        if (
            !Array.isArray(contentToutResponse) ||
            !contentToutResponse.length
        ) {
            return undefined;
        }

        const randomTout = getRandomItemsFromArray(contentToutResponse, 1);
        return randomTout[0];
    }
}
