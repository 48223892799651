export default vm => {
    // sm-md
    const smmdTemplate = `
        <pfdc-toggle-group
            select-multiple
            toggle-group-legend="Age"
            pf-mix-change="$closest.onAgeChanged($element.value)"
            observe-state="animalSearch.filters.age"
        ></pfdc-toggle-group>
    `;

    const lgxlTemplate = `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Age</div>
        <pfdc-generic-select
            select-multiple
            id="age-select"
            pf-mix-change="$closest.onAgeChanged($element.value)"
            observe-state="animalSearch.filters.age"
            input-aria-label="Select Age"
            select-placeholder="Any"
        >
        </pfdc-generic-select>
    `;

    switch (vm.state.currentBreakpoint) {
        case 'sm':
            return smmdTemplate;
        case 'md':
        case 'lg':
        case 'xl':
            return lgxlTemplate;
        default:
            console.warn('breakpoint not set; unable to determine correct age filter template');
            return smmdTemplate;
    }
};
