import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _noop from 'lodash/noop';

import { isEventRelatedTargetWithinCurrentTarget } from '../../../util/react';
import {
    KEY_ESC,
    KEY_ESCAPE,
} from '../../../constants/keyboard';
import { focusFirstFocusable } from '../../../lib/focusManager/index';

export default class Popup extends Component {
    /**
     * @type {Object}
     */
    state = {
        popupVisible: false,
    };

    /**
     * @static
     * @type {Object}
     */
    static propTypes = {
        id: PropTypes.string.isRequired,
        children: PropTypes.func.isRequired,
        shouldCloseOnBlur: PropTypes.bool,
    };

    /**
     * @static
     * @type {Object}
     */
    static defaultProps = {
        shouldCloseOnBlur: true,
    };

    /**
     * trigger ref
     * @type {Object}
     */
    _triggerRef = React.createRef();

    /**
     * popup ref
     * @type {Object}
     */
    _popupRef = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        if (this.state.popupVisible && !prevState.popupVisible) {
            focusFirstFocusable(this._popupRef.current);
        }
    }

    get popupId() {
        return `${this.props.id}-popup`;
    }

    get rootStyle() {
        return {
            '--state': this.state.popupVisible ? 'open' : 'closed',
        };
    }

    _openPopup = () => {
        this.setState({ popupVisible: true });
    };

    /**
     * @param {Boolean} shouldFocusTrigger
     */
    _closePopup = (shouldFocusTrigger = true) => {
        this.setState({ popupVisible: false });

        if (shouldFocusTrigger) {
            this._triggerRef.current.focus();
        }
    };

    /**
     * @param {Event} event
     */
    _handleKeyDown = event => {
        switch (event.key) {
            case KEY_ESC, KEY_ESCAPE:
                this._closePopup();
                break;
        }
    };

    /**
     * @param {Event} event
     */
    _handleTriggerClick = event => {
        this.state.popupVisible ? this._closePopup() : this._openPopup();
    };

    /**
     * @param {Event} event
     */
    _handleBlur = event => {
        this.blurTimeoutId = setTimeout(() => {
            this._closePopup(false);
        });
    };

    /**
     * @param {Event} event
     */
    _handleFocus = event => {
        clearTimeout(this.blurTimeoutId);
    };

    /**
     * Build the container props, this allows render props to use these
     * @returns {Object}
     */
    _buildContainerProps() {
        // Only include focus events props if shouldCloseOnBlur remains true
        const focusEvents = this.props.shouldCloseOnBlur
            ? {
                onFocus: this._handleFocus,
                onBlur: this._handleBlur,
            }
            : {};

        return {
            onKeyDown: this._handleKeyDown,
            style: this.rootStyle,
            ...focusEvents,
        };
    }

    /**
     * Build the trigger props, this allows render props to use these
     * @returns {Object}
     */
    _buildTriggerProps() {
        return {
            id: this.props.id,
            ref: this._triggerRef,
            'aria-expanded': this.state.popupVisible,
            'aria-haspopup': 'true',
            'aria-controls': this.popupId,
            onClick: this._handleTriggerClick,
        };
    }

    /**
     * Build the popup props, this allows render props to use these
     * @returns {Object}
     */
    _buildPopupProps() {
        return {
            id: this.popupId,
            ref: this._popupRef,
        };
    }

    render() {
        if (typeof this.props.children !== 'function') {
            throw new Error(
                'Popup: You need to provide a render function to the children prop'
            );
        }

        return (
            <Fragment>
                {this.props.children({
                    openPopup: this._openPopup,
                    closePopup: this._closePopup,
                    isVisible: this.state.popupVisible,
                    containerProps: this._buildContainerProps(),
                    triggerProps: this._buildTriggerProps(),
                    popupProps: this._buildPopupProps(),
                })}
            </Fragment>
        );
    }
}
