import { html } from '../../../core/scripts/util/util';
import { helpers } from '../../../core/scripts/util/helpers';

// READ ME
// ---
// Do not remove the inline style tags on the media-bd elements in the below template
// they fix a flex bug with fixed positioning by setting explicit widths.
//

export default vm => {
    const state = vm.state;
    const haveSearchResults = vm.contextElement.stateController.animalSearch.results.state.count >= 1;
    if (!haveSearchResults) { return html``; }

    return html`
        <div class="createSavedSearch createSavedSearch_slideOut ${state.animalSearch.savedSearch.teaseSlideout ? 'u-isActive' : ''} ${state.animalSearch.savedSearch.createSearchSlideoutOpen ? '' : 'u-isInactive'}"
            pf-mix-click="$closest.onToggleSavedSearchFormClicked($event, $element)"
            pf-mix-keyup="$closest.onToggleSavedSearchFormClicked($event, $element)"
            role="button"
            ${state.animalSearch.savedSearch.createSearchSlideoutOpen ? 'tabindex="0"' : ''}
            aria-label="Set an email alert for ${vm.searchName}"
            aria-hidden="${state.animalSearch.savedSearch.createSearchSlideoutOpen ? 'false' : 'true'}">
            <pfdc-element observe-state="animalSearch.savedSearch">
                <div pf-mix-state-show="formPosting">
                    <svg class="createSavedSearch-loader" role="img" focusable="false" viewBox="25 25 50 50">
                        <circle class="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke-width="5"
                            stroke="#fff"
                            stroke-dasharray="39.25, 117.75"
                        />
                    </svg>
                </div>
            </pfdc-element>
            <div class="media m-media_alignMiddle m-media_gutterLg">
                <div class="media-img">
                    <div class="createSavedSearch-icon">
                        <span class="icon icon_createSavedSearch m-icon_colorWhite">
                            <svg role="img" focusable="false">
                                <use xlink:href="#icon-alertBell" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="media-bd" style="min-width: 250px; width: 250px;">
                    <div>
                        <span class="txt m-txt_colorWhite m-txt_uppercase m-txt_sm m-txt_heavy">Set an email alert for</span><br /> <span class="txt m-txt_colorWhite m-txt_lg">${vm.searchName}</span>
                    </div>
                </div>
            </div>
        </div>

        ${createSearchForm(vm)}
        ${searchSetSlideout(vm)}
    `;
};

const createSearchForm = vm => {
    const state = vm.state;
    const showCountry = (vm.contextElement.stateController.state.user.country === '' && !vm.contextElement.stateController.state.userAuth);
    const showEmail = vm.contextElement.stateController.state.user.country === '';
    return html`
        <div class="createSavedSearch createSavedSearch_actionCard ${state.animalSearch.savedSearch.createSearchFormOpen ? 'u-isActive' : 'u-isInactive'} ${state.animalSearch.savedSearch.formPosting ? 'createSavedSearch_posting' : ''}" aria-hidden="${state.animalSearch.savedSearch.createSearchFormOpen ? 'false' : 'true'}" ${state.animalSearch.savedSearch.createSearchFormOpen ? '' : 'style="display: none;"'}>

            <pfdc-element observe-state="animalSearch.savedSearch">
                <div pf-mix-state-show="formPosting">
                    <svg class="createSavedSearch-loader" role="img" focusable="false" viewBox="25 25 50 50">
                        <circle class="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke-width="5"
                            stroke="#fff"
                            stroke-dasharray="39.25, 117.75"
                        />
                    </svg>
                </div>
            </pfdc-element>

            <form pf-mix-submit="$closest.onCreateFormSubmit($event)" novalidate>
                <div class="actionCard actionCard_horizontal m-actionCard_overflowVisible">
                    <div class="actionCard-body">
                        <div class="media m-media_gutterLg">
                            <div class="media-img">
                                <div class="createSavedSearch-icon">
                                    <span class="icon icon_createSavedSearch m-icon_colorWhite">
                                        <svg role="img" focusable="false">
                                            <use xlink:href="#icon-alertBell" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="media-bd" style="min-width: 450px; width: 450px;">
                                <div class="createSavedSearch-header u-vr3x">
                                    <div class="txt m-txt_colorWhite m-txt_lg" tabindex="-1">Set an email alert for &ldquo;${vm.searchName}&rdquo;</div>
                                    <button
                                        pf-mix-click="$closest.onToggleSavedSearchFormClicked($event, $element)"
                                        class="createSavedSearch-closeBtn"
                                        type="button"
                                        aria-label="Cancel and close set alert form">
                                        <span class="icon icon_sm m-icon_colorWhite">
                                            <svg role="img" focusable="false">
                                                <use xlink:href="#icon-close" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div class="createSavedSearch-form">
                                    <div class="txt m-txt_colorWhite u-vr3x">
                                        <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="global"></span></pfdc-element>
                                    </div>
                                    <div class="grid grid_gutterLg u-vr3x ${!showCountry && !showEmail ? 'u-isHidden' : '' }">
                                        <div class="grid-col grid-col_1/2 ${showCountry ? '' : 'u-isHidden'}">
                                            <pfdc-generic-select
                                                id="Create_Saved_Search_Slideout_Country_Field"
                                                pf-mix-change="$closest.onCountryChanged($event, $element)"
                                                observe-state="animalSearch.savedSearch.country"
                                                aria-describedby-id="CountryNotListedMessage"
                                                input-aria-label="Select Country"
                                                select-placeholder="Select Country"
                                            ></pfdc-generic-select>

                                            <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="country"></span></pfdc-element>
                                        </div>
                                        <div class="grid-col ${showCountry ? 'grid-col_1/2' : 'grid-col_1/1'} ${showEmail ? '' : 'u-isHidden'}">
                                            <pfdc-input class="field ${state.animalSearch.savedSearch.emailInputValue ? 'u-hasInput' : ''}" is-dynamic>
                                                <label for="Create_Saved_Search_Slideout_Email" class="field-label">Email</label>
                                                <input pf-mix-change="$closest.onEmailInputChanged($event, $element)" pf-input-input id="Create_Saved_Search_Slideout_Email" class="field-input" type="email" spellcheck="false" name="saved_search[email]" value="${state.animalSearch.savedSearch.emailInputValue}" required aria-describedby="Create_Saved_Search_Slideout_Email_Error_Description" />

                                                <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="email"></span></pfdc-element>
                                            </pfdc-input>
                                        </div>
                                    </div>

                                    <pfdc-element render-on-state-change="ui.countryNotListed" template="countryNotListed"></pfdc-element>

                                    <pfdc-animal-search-optins
                                        class="optins optins_savedSearchSlideout"
                                        observe-state="animalSearch.savedSearch"
                                        data-form-id="saved-search"
                                        data-corporation="pf,pu"
                                        data-cbox-ids="Create_Saved_Search_Slideout_Petfinder,Create_Saved_Search_Slideout_Sponsor"
                                        data-cbox-names="saved_search[emailOptIn][petfinder],saved_search[emailOptIn][sponsor]"
                                    ></pfdc-animal-search-optins>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actionCard-footer">
                        <button type="submit" class="actionCard-footer-cta">Submit</button>
                    </div>
                </div>
            <form>
        </div>
    `;
};

const searchSetSlideout = vm => {
    return html`
        <pfdc-element render-on-state-change="animalSearchState.state.searchSetSlideoutOpen">
            <div pfdc-create-saved-search-set-slideout class="createSavedSearch createSavedSearch_slideOut ${vm.state.animalSearch.savedSearch.searchSet ? '' : 'u-isInactive'}" aria-hidden="${vm.state.animalSearch.savedSearch.searchSet ? 'false' : 'true'}" tabindex="-1">
                <div class="media m-media_alignMiddle m-media_gutterLg">
                    <div class="media-img">
                        <div class="createSavedSearch-icon">
                            <span class="icon icon_createSavedSearch m-icon_colorWhite">
                                <svg role="img" focusable="false">
                                    <use xlink:href="#icon-alertBell_checked" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="media-bd" style="min-width: 250px; width: 250px;">
                        <div>
                            <pfdc-element observe-state="animalSearch.savedSearch">
                                <span class="txt m-txt_colorWhite m-txt_lg m-txt_tight m-txt_alignCenter" pf-mix-state-show="searchSetSlideoutOpen">Your new pet email alert has been set</span>
                                <span class="txt m-txt_colorWhite m-txt_lg m-txt_tight m-txt_alignCenter" pf-mix-state-hide="searchSetSlideoutOpen">You already have a new pet alert set for this search</span>
                            </pfdc-element>
                        </div>
                    </div>
                </div>
            </div>
        </pfdc-element>
    `;
};
