export default class Deferred {
    _resolveFn = null;
    _rejectFn = null;

    promise = null;

    constructor() {
        this.promise = new Promise((res, rej) => {
            this._resolveFn = res;
            this._rejectFn = rej;
        });
    }

    resolve(...args) {
        this._resolveFn(...args);
    }

    reject(...args) {
        this._rejectFn(...args);
    }
}
