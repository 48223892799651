import { html } from '../../../core/scripts/util/util';

// prettier-ignore
export default vm => {
    return html`
        <div class="${(vm.displayedOptions.length === 0) ? '' : 'resultsShown'}">
            <pf-focus-manager
                focus-element="[${vm.id}_SearchInput]"
                ${vm.localState.focusIsAllowed ? `return-focus-to="[${vm.id}_SearchInput]"` : ''}>
                ${searchInput(vm)}
                ${optionsList(vm)}
            </pf-focus-manager>
        </div>
    `;
};

// prettier-ignore
const searchInput = vm => {
    return html`
        <div class="field field_icon m-field_shadowed m-field_colorPrimary m-field_bold m-field_iconLeft m-field_iconLocation m-field_noLabel">
            <p id="${vm.id}_Description" class="u-isVisuallyHidden" aria-hidden="true">This is an autocomplete field. We will populate or filter a list of suggestions as you type. We will notify you of the number of suggestions that match your query as you type. If suggestions are found, press the tab key to focus the suggestions list. Select a suggestion item to set it as the value of this field. Press escape in the suggestions list to close the list and return to the search input field.</p>
            <input
                ${vm.id}-search-input
                id="${vm.id}_SearchInput"
                class="field-input ${vm.localState.loading || vm.localState.noResults || vm.localState.menuIsOpen ? 'm-field-input_hasDropdown' : ''}"
                pf-mix-change="$event.stopPropagation()"
                pf-mix-click="$event.stopPropagation()"
                pf-mix-keydown="$closest.onSearchInputKeydown($event, $element)"
                pf-mix-keyup="$closest.onSearchInputKeyup($event, $element)"
                pf-mix-input="$closest.onSearchInputted($event, $element)"
                pf-mix-focus="$closest.openMenu(null)"
                value="${vm.localState.searchText}"
                placeholder="${vm.placeholder}"
                type="text"
                name="location"
                spellcheck="false"
                aria-label="${vm.inputAriaLabel}"
                aria-describedby="${vm.id}_Description">
        </div>
    `;
};

// prettier-ignore
const optionsList = vm => {
    if (vm.localState.loading) {
        return html`
            <div class="optionsList m-optionsList_full m-optionsList_roundedBottom">
                <ul autocomplete-list class="optionsList-list">
                    <li class="optionsList-item ">Loading...</li>
                </ul>
            </div>
        `;
    }
    if (vm.localState.noResults) {
        return html`
            <div class="optionsList m-optionsList_full m-optionsList_roundedBottom">
                <ul autocomplete-list class="optionsList-list">
                    <li class="optionsList-item ">No ${vm.unitLabelPlural.toLowerCase()} found</li>
                </ul>
            </div>
        `;
    }
    if (vm.displayedOptions.length) {
        if (vm.localState.menuIsOpen) {
            return html`
                <div class="optionsList m-optionsList_full m-optionsList_roundedBottom">
                    <ul autocomplete-list id="${vm.id}_List_Box" class="optionsList-list" role="listbox">
                        ${vm.displayedOptions.map((option, index) => html`
                            ${optionsListItem(vm, option, index)}
                        `)}
                    </ul>
                </div>
            `;
        } else {
            return '';
        }
    }
};

// prettier-ignore
const optionsListItem = (vm, option, index) => html`
    <li class="optionsList-item ${vm.selectedValues.includes(option.value) ? 'isActive' : ''}" pf-mix-focusin="$closest.onItemHovered($event, $element, ${index})" role="option">
        <button class="optionsList-item-btn m-txt_md ${vm.localState.highlightedIndex === index ? 'u-isFocused' : ''}" pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}')" type="button">
            ${option.label}
        </button>
    </li>
`;
