import { html } from '../../../../../core/scripts/util/util';

export default (vm) => {
    return html`
        <div class="filterList-btn ${vm.props.expandableList ? 'filterList-btn_expandable' : ''}">
            <div class="split m-split_fullHeight">
                ${vm.props.selectionsDisplay(vm)}
                <div class="hrArray m-hrArray_4x m-hrArray_alignMiddle">
                    <div class="${vm.observedState.options.length ? '' : 'u-isHidden'}">
                        <button
                            class="txt txt_link m-txt_heavy m-txt_colorPrimaryS1"
                            pf-mix-click="$closest.onClearBtnClicked($event, $element)"
                            pf-mix-keydown="$event.stopPropagation()"
                        >Clear</button>
                    </div>
                    <span class="icon icon_sm m-icon_colorDark">
                        <svg role="img" aria-hidden="true">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevronUp"></use>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    `;
}
