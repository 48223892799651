import PFDCElement from '../pfdc-element/element';
import ResultsStateController from '../../state-controllers/generic/ResultsStateController';

/**
 * Animal Type Select
 *
 * This is the animalType select in the Fundamental Search Bar.
 *
 * If no prior selection, it displays 'All Pet Types'.
 * You can't re-select 'All Pet Types' once changed.
 */

export class PFDCAnimalSearchResultsElement extends PFDCElement {
    static _elementTag = 'pfdc-animal-search-results';
    static _requireTemlpate = true;
    // static template = template;

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            currentBreakpoint: this.state.currentBreakpoint,
        };
    }

    // get viewModel() {
    //     return {
    //         ...super.viewModel,
    //         currentBreakpoint: ,
    //     }
    // }

    onBreakpointChanged(currentBreakpoint) {
        this.patchLocalState({
            currentBreakpoint,
        });
    }
}

export default PFDCAnimalSearchResultsElement;
