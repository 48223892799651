import { html, ifTruthy } from '../../../core/scripts/util/util';

const sneezeGuard = showProgress => {
    return html`
        <div class="sneezeGuard">
            ${ifTruthy(showProgress, winky)}
        </div>
    `;
};

const winky = html`
    <div class="winky u-vr10x u-vrTop12x"></div>
`;

export default vm => {
    const isLoading = vm.observedState;

    if (!isLoading) {
        return '';
    }

    const showProgress = vm.controller.hasAttribute('show-progress');
    return sneezeGuard(showProgress);
};
