import Mixins from '../../../../core/scripts/lib/wirey/Mixins';
import MixinBase from '../../../../core/scripts/lib/wirey/mixins/MixinBase';
import Utils from '../../../../core/scripts/lib/wirey/Utils';

export default class StateShowMixin extends MixinBase {
    static _attributeName = 'mix-state-show';

    constructor(ele, value) {
        super(ele, value);

        let ref = this._ele;
        do {
            if (!ref.observedState) {
                continue;
            }

            const dereferenced = Utils.ObjectUtils.dereference(
                value,
                ref.observedState
            );
            if (!dereferenced.success) {
                continue;
            }

            if (!dereferenced.result) {
                this._ele.innerHTML = '';
            }

            return;
        } while ((ref = ref.parentElement));
    }
}

Mixins.add(StateShowMixin);
