import { html } from '../../../../../core/scripts/util/util';

import openStateMobile from './openStateMobile.html';

export default (vm, props) => {
    return html`
        <pf-focus-manager
            trap-focus
            lock-scroll
            focus-first-focusable
            class="modal m-modal_noFlex"
        >
            <!-- BEGIN: Content -->
            ${openStateMobile(vm, props)}
            <!-- END: Content -->
        </pf-focus-manager>
    `;
}
