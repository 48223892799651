import { html, ifTruthy } from '../../../../core/scripts/util/util';

import genericTemplate, { WIDTH_MODES } from '../pfdc-generic-autosuggest/genericTemplate.html';
import openStateMobileWithModal from '../pfdc-generic-autosuggest/partials/openStateMobileWithModal.html';

const breedSelectionsDisplay = vm => {
    const visibleBreedsCount = vm.observedState.options.filter(item => item.display !== false).length;

    return html`
        <div class="txt ${vm.selectedLabels.length ? 'u-isHidden' : ''}">
            ${vm.animalTypeLabel} (${visibleBreedsCount})
        </div>

        <p class="u-isVisuallyHidden" aria-live="polite">${vm.localState.clientFilteredOptions.length} breed results found</p>

        ${ifTruthy(vm.selectedLabels.length, html`
            <div class="txt m-txt_heavy m-txt_colorPrimaryS1 filterList-textTruncate">
                ${vm.selectedLabels.length === 1 ? vm.selectedLabels[0] : `${vm.selectedLabels.length} ${vm.unitLabelPlural}`}
            </div>
        `)}
    `;
};

export default genericTemplate({
    widthMode: WIDTH_MODES.WIDE,
    openStateMobile: openStateMobileWithModal,
    selectionsDisplay: breedSelectionsDisplay,
});
