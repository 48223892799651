
import { html } from '../../../../core/scripts/util/util';

const template = vm => {
    const instructionsId = `${vm.controller.randomId}-instructions`;
    const searchLocation = vm.state.animalSearch.results.searchSummary.searchLocationName;
    const searchLocationMsg = searchLocation ? `Current search location is ${searchLocation}.` : '';

    return html`
        <input
            type="text"
            class="suggester-input"
            placeholder="${vm.controller.placeholder}"

            pf-mix-focus-on-render
            pf-mix-keydown="$closest.onTextInputKeyDown($event)"
            pf-mix-input="$closest.onTextInput($event, $element.value)"
            pf-mix-value="${vm.controller.valueAddress}"

            ${''/* accessibility */}
            role="combobox"
            aria-label="${vm.controller.placeholder}"
            aria-describedby="${instructionsId}"
            aria-owns="${vm.controller.listId}"
            aria-expanded="false"
            aria-autocomplete="both"
            ${''/* aria-activedescendant="" */}
            data-test="Suggester_Search_Input"
        />

        <span id="${instructionsId}" style="display: none">
            ${searchLocationMsg}
            When autocomplete results are available use up and down arrows to review and
            enter to select.  Touch device users, explore by touch or with swipe gestures.
        </span>
    `;
};

export default template;
