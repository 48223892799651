import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import SiteStateController from '../../state-controllers/siteStateController';
import Cookies from 'js-cookie';
import renderDogCta from './search-match-dog-cta.html.js';
import renderCatCta from './search-match-cat-cta.html.js';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-search-match-cta';

/**
 * Stores reference string selectors for the queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CLOSE_BTN: `[${ELEMENT_TAG_NAME}-close]`,
};

/**
 * Duration in days before the cookie expires
 * @const
 * @type {number}
 */
const COOKIE_EXPIRATION = 30;

/**
 * A CTA that appears above the filters on animal (dog or cat only) search.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-search-match-cta></pfdc-search-match-cta>
 * ---
 *
 * @extends PFElement
 */
export class PFDCSearchMatchCtaElement extends PFElement {
    /**
     * The name of the currently selected animal type
     * @type {string}
     */
    _activeAnimalType = SiteStateController.animalSearch.filters.animalType[0];

    /**
     * The name of the session cookie
     * @type {string}
     */
    _cookieName = `search_match_${this._activeAnimalType}_cta_closed`;

    onInit() {
        // // Do not initialize if animal type is not dog/cat
        if (this._activeAnimalType !== 'dogs' && this._activeAnimalType !== 'cats') {
            return;
        }

        // // If the cookie is not set, render the component
        if (!this._isCookieSet) {
            this.render();
            this._setupChildren();
            this._setupHandlers();
            this._initGoogleOptimize();
        }
    }

    render() {
        // Only render IF user does NOT have an adopter profile
        if (!this._userHasAdopterProfile) {
            if (this._activeAnimalType === 'dogs') {
                this.innerHTML = renderDogCta();
            } else if (this._activeAnimalType === 'cats') {
                this.innerHTML = renderCatCta();
            }
        }
    }

    /**
     * Sets up all the necessary children (elements).
     *
     * @private
     */
    _setupChildren() {
        /**
         * Reference to the search & match cta close button
         * @type {element}
         */
        this.closeBtn = this.querySelector(ELEMENT_SELECTORS.CLOSE_BTN);
    }

    /**
     * Sets up all necessary handlers.
     *
     * @private
     */
    _setupHandlers() {
        this.closeBtn.addEventListener('click', this._onCloseButtonClick);
    }

    /**
     * TODO: extract this out into a more generic method to allow for reuse
     */
    _initGoogleOptimize() {
        try {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: 'optimize.activate' });
        } catch (error) {
            console.error('Purdata instance does not exist on Window');
        }
    }

    /**
     * Getter to determine if the search & match CTA cookie is set.
     *
     * @readonly
     * @private
     * @returns {Boolean}
     */
    get _isCookieSet() {
        return Boolean(Cookies.get(this._cookieName));
    }

    /**
     * Does user have an adopter profile?
     *
     * @readonly
     * @private
     * @returns {boolean}
     */
    get _userHasAdopterProfile() {
        // if user NOT logged in we know there is no adoption profile
        if (!window.PF.pageConfig.user_auth) {
            return false;
        }

        // if user IS logged in, have they completed an adoption profile?
        if (window.PF.pageConfig.completed_adoption_profiles.length < 1) {
            return false;
        }

        return true;
    }

    /**
     * Creates the configured cookie.
     *
     * @private
     */
    _setCookie() {
        Cookies.set(this._cookieName, {
            expires: COOKIE_EXPIRATION,
            path: '/',
        });
    }

    /**
     * Removes the CTA from the page.
     *
     * @private
     */
    _removeCta() {
        this.innerHTML = '';
    }

    /**
     * Click handler for the CTA close button.
     *
     * @private
     */
    _onCloseButtonClick = () => {
        this._removeCta();
        this._setCookie();
    };
}

export default PFDCSearchMatchCtaElement;
