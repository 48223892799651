import { PFDCGenericSelectElement } from '../pfdc-generic-select/element';

import template from './template.html';

/**
 * Animal Type Select
 *
 * This is the animalType select in the Fundamental Search Bar.
 *
 * If no prior selection, it displays 'All Pet Types'.
 * You can't re-select 'All Pet Types' once changed.
 */

export class PFDCAnimalTypeSelectElement extends PFDCGenericSelectElement {
    static _elementTag = 'pfdc-animal-type-select';

    static template = template;

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            currentBreakpoint: this.state.currentBreakpoint,
            selectedLabels: this.selectedLabels.length
                ? this.selectedLabels
                : [this.defaultLabel],
        };
    }

    get defaultLabel() {
        return this.observedState.defaultLabel || '';
    }

    get viewModel() {
        return {
            ...super.viewModel,
            hasValue: this.hasValue,
            totalResults: this.stateController.animalSearch.results
                .totalResults,
        };
    }

    get hasValue() {
        const val = this.observedState.value;
        return Array.isArray(val) ? val.length > 0 : val != null;
    }

    constructor() {
        super();
        this.subscribe(() => this.render(), [
            'animalSearch.results.totalResults',
            'animalSearch.filters.location',
        ]);
    }

    onConnected() {
        super.onConnected();
        if (this.hasValue) {
            this.classList.add(
                'animalSearchHeader-inner-fundamentalSelectComplete'
            );
        } else {
            this.classList.add(
                'animalSearchHeader-inner-fundamentalSelectIncomplete'
            );
        }
    }

    onBreakpointChanged(currentBreakpoint) {
        this.patchLocalState({
            currentBreakpoint,
        });
    }

    onCloseButtonClicked() {
        this.closeMenu();
    }
}

export default PFDCAnimalTypeSelectElement;
