import APIService from './APIService';

// WARNING: This APIService should not be used moving forward it is using an older endpoint
// TODO: Components using this will need to be refactored when the older enpoint is deprecated
class SearchOrganizationsAPIService extends APIService {
    static url = '/v2/search/organizations/';

    /**
     * Search organizations
     * @param {String} searchText fuzzy search that matches on name, city, state.
     */
    async _search(searchText) {
        const options = {
            params: {
                // eslint-disable-next-line camelcase
                name_substring: searchText,
            },
            lateResponseCancelling: true,
        };

        return this.queryAndProcess(options);
    }

    /**
     * Search and return in our select option format.
     * @param {String} searchText
     */
    async fetchAsOptions(searchText) {
        const response = await this._search(searchText);

        // remap data to our desired format
        if (response.data && response.data.organizations) {
            response.data = response.data.organizations.map(org => {
                const city = org.location.address.city || '';
                const state = org.location.address.state || '';

                const subLabel =
                    city || state
                        ? `${city}${city && state ? ', ' : ''}${state}`
                        : '';

                return {
                    value: org.display_id,
                    label: org.name,
                    subLabel,
                };
            });
        }

        return response;
    }
}

const instance = new SearchOrganizationsAPIService();
APIService.register('searchOrganizations', instance);
export default instance;
