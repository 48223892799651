import { html } from '../../../core/scripts/util/util';
import shareList from './shareList.html';

export default vm => {
    const shareListCompiledTemplate = shareList(vm);

    return html`
    <pfdc-modal id="Share_Pet_Modal"
        class="modal m-modal_bgColorPrimaryS2"
        role="dialog"
        aria-labelledby="Share_Modal_Header"
        style="display: none;">
        <div pf-modal-content class="modal-content m-modal-content_padded" style="display: none;">
            <div class="media-img u-vrTop4x">
                <div class="imgMask imgMask_circleXxl m-imgMask_borderWhite m-imgMask_center m-imgMask_placeholder m-imgMask_transparentPlaceholder"></div>
            </div>

            <div class="vrArray">
                <div class="m-txt_alignCenter">
                    <div id="Share_Modal_Header"
                    class="txt txt_h1 m-txt_tight m-txt_colorWhite u-vr3x u-vrTop7x">
                        Share ${vm.state.animalSearch.results.name}
                    </div>
                </div>

                ${shareListCompiledTemplate}

            </div>
        </div>

        <pf-element class="fullscreenCarousel-close"
            on-click="ui.deactivate"
            data-target="#Share_Pet_Modal">
            <button class="modal-closeBtn modal-closeBtn_colorWhite"
                type="button"
                aria-label="Close this dialog">Close this dialog</button>
        </pf-element>
    </pfdc-modal>
    `;
};
