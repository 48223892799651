import initHypernovaRenderReact from '../utils/hypernova-render-react';

import InterceptorModal from '../../react-components/specifics/InterceptorModal';

export default function initReactServerComponents() {
    const components = {
        InterceptorModal,
    };

    initHypernovaRenderReact(components);
}
