import { html } from '../../../../core/scripts/util/util';
import { helpers } from '../../../../core/scripts/util/helpers';

export default vm => {
    return html`
        ${vm.observedState.optinRules.map((item, index) => {
            
            const shouldDisplayCheckbox = item['display_checkbox'];
            const checkboxName = vm.localState.checkboxNames[index];
            const displayCopy = item['display_copy'];
            // TODO: are checkbox ids needed? what is/was their original purpose? if not, remove the id="..." below

            return html`
                <label for="$${vm.localState.checkboxIds ? vm.localState.checkboxIds[index] : vm.localState.checkboxNames[index]}" class="checkbox checkbox_round m-checkbox_white u-vr3x ${item['display_copy'] === '' ? 'u-isHidden' : ''} ${item['display_checkbox'] ? '' : 'm-checkbox_hidden'}">
                    ${shouldDisplayCheckbox ? html`
                        <input 
                            type="checkbox"
                            id="$${vm.localState.checkboxIds ? vm.localState.checkboxIds[index] : vm.localState.checkboxNames[index]}"
                            class="checkbox-input"
                            pf-mix-change="$closest.onOptInChanged('$${checkboxName}', $element)"
                            ${vm.observedState.optinValues[checkboxName] ? 'checked="checked"' : ''}
                        />
                    ` : ''}
                    <span class="checkbox-btn"></span>
                    <span class="checkbox-label" ${shouldDisplayCheckbox ? '' : 'tabindex="0"'}>
                        ${displayCopy}
                    </span>
                </label>
            `;
        })}
    `;
};
