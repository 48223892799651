import { PFDCGenericSelectElement } from '../pfdc-generic-select/element';

import genericTemplate from './genericTemplate.html';

import APIService from '../../../../core/scripts/services/APIService';
import Utils from '../../../../core/scripts/lib/wirey/Utils';
import _debounce from 'lodash/debounce';
import {
    KEY_DOWN,
    KEY_ARROW_DOWN,
    KEY_UP,
    KEY_ARROW_UP,
    KEY_ENTER,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../constants/keyboard';

/**
 *
 * @class PFDCGenericAutosuggestElement
 * @extends PFDCGenericSelectElement
 */
export class PFDCGenericAutosuggestElement extends PFDCGenericSelectElement {
    static _elementTag = 'pfdc-generic-autosuggest';
    static template = genericTemplate();

    get viewModel() {
        return {
            ...super.viewModel,
            contextElement: this,
            displayedOptions: this.displayedOptions,
            placeholder: this.placeholder,
            unitLabelSingular: this.unitLabelSingular,
            unitLabelPlural: this.unitLabelPlural,
            totalResults: this.stateController.animalSearch.results.totalResults,
            selectedAnimalTypeLabel: this.stateController.animalSearch.filters.selectedLabelsFor('animalType'),
            lettersPresent: Object.keys(this.filteredOptionsIndex),
        };
    }

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            searchText: '',
            clientFilteredOptions: this.allAvailableOptions,
            loading: false,
            noResults: false,
            insufficientInput: false,
        };
    }

    get allAvailableOptions() {
        // TODO: ensure this clone is necessary (it wont' be if cloning is re-enabled at the
        // StateController.state getter level)
        // TODO: this clone is shallow, and not fully safe anyway... be cautious
        let options = this.observedState.options.slice(0);

        if (this.observedState.selectedOptionsCache) {
            options = options.concat(this.observedState.selectedOptionsCache);
        }

        return options;
    }

    // not necessarily filtered
    get displayedOptions() {
        return this.useApi
            ? this.currentAvailableOptions
            : this.localState.clientFilteredOptions;
    }

    get apiService() {
        const apiServiceId = this.apiServiceId;
        if (!apiServiceId) {
            return;
        }

        const apiService = APIService.get(apiServiceId);
        if (!apiService) {
            throw new Error(`Could not find requested API service (${apiServiceId}); has it been
                registered with APIService?`);
        }

        return apiService;
    }

    get useApi() {
        return Boolean(this.apiServiceId);
    }

    /**
     * Attribute getters
     */

    get apiServiceId() {
        return this.getAttribute('autosuggest-api-service');
    }

    get apiMethod() {
        return this.getAttribute('autosuggest-api-method');
    }

    get pathsToMatch() {
        return (this.getAttribute('autosuggest-match-paths') || 'label').split(
            / *, */
        );
    }

    get placeholder() {
        return this.getAttribute('autosuggest-placeholder') || '';
    }

    get unitLabelSingular() {
        return this.getAttribute('autosuggest-unit-label-singular') || '';
    }

    get unitLabelPlural() {
        return this.getAttribute('autosuggest-unit-label-plural') || this.unitLabelSingular;
    }

    get autoCloseOnSelect() {
        return this.hasAttribute('autosuggest-close-on-select');
    }

    get minSearchTextLength() {
        return (
            parseInt(
                this.getAttribute('autosuggest-min-search-text-length'),
                10
            ) || 0
        );
    }

    filteredOptionsIndex = {};

    /**
     * Create debounced version of update search text to call later
     * @type {function}
     */
    debouncedUpdateSearchText = _debounce(
        this.updateSearchText.bind(this),
        500
    );

    /**
     * Custom Element Lifecycle
     */
    async onConnected() {
        super.onConnected();

        this.numUpdates = 0;

        this.indexOptions();
    }

    /**
     * @param {Array} options
     * @param {string} searchText
     * @returns {Array}
     */
    getFilteredOptions(options, searchText) {
        const searchTextLowered = searchText.toLowerCase();

        if (!searchTextLowered) {
            return options;
        }

        // TODO: implement actual search algorithm
        return options.filter(option => {
            const pathsToMatch = this.pathsToMatch;
            for (let i = 0; i < pathsToMatch.length; i++) {
                const path = pathsToMatch[i];
                const valueAtPath = Utils.ObjectUtils.access(path, option);

                if (!valueAtPath) {
                    continue;
                }

                if (this.matchComparator(searchTextLowered, valueAtPath)) {
                    return true;
                }
            }

            return false;
        });
    }

    /**
     * Function used to compare matches
     * @param {string} searchText
     * @param {string} compareTo
     * @returns {boolean}
     */
    matchComparator(searchText, compareTo) {
        return compareTo.toLowerCase().indexOf(searchText) !== -1;
    }

    // =======================================================
    // Event Handlers
    // =======================================================

    onSearchInputKeydown(ev, element) {
        switch (ev.key) {
            case KEY_UP:
            case KEY_ARROW_UP:
            case KEY_DOWN:
            case KEY_ARROW_DOWN:
                // don't move cursor, select items instead
                ev.preventDefault();
                return;
        }
    }

    onSearchInputKeyup(ev, element) {
        switch (ev.key) {
            case KEY_SPACE:
            case KEY_SPACE_BAR:
                ev.stopPropagation();
                return;
        }
    }

    onSearchInputted(ev, element) {
        const searchText = element.value;

        switch (ev.key) {
            default:
                // TODO: keyCode is deprecated
                // 8 is backspace, allow; otherwise, ignore keystrokes outside of desired range
                if (ev.keyCode !== 8 && (ev.keyCode < 32 || ev.keyCode > 126)) {
                    return;
                }

                this.patchLocalState({
                    searchText,
                });

                this.debouncedUpdateSearchText(searchText);
        }
    }

    onCloseButtonClicked(ev, element) {
        this.closeMenu();
    }

    /**
     * Handles keyboard activation of close button.
     *
     * @param {Object} ev
     * @param {HTMLElement} element
     */
    onCloseBtnKeyDown(ev, element) {
        if (ev.key === KEY_ENTER) {
            this.closeMenu();
            ev.stopPropagation();
        }
    }

    // =======================================================
    // State Updates / Actions
    // =======================================================

    haveSufficientInput(searchText) {
        return searchText.length >= this.minSearchTextLength;
    }

    async updateSearchText(searchText) {
        // determine whether or not we should perform a search
        if (!this.haveSufficientInput(searchText)) {
            this.setObservedStateAtAddress([], 'options');

            this.patchLocalState({
                loading: false,
                noResults: false,
                insufficientInput: true,
            });

            return;
        }

        if (this.useApi) {
            if (this.apiMethod in this.apiService === false) {
                throw new Error(
                    `Method "${this.apiMethod}" not found on "${this.apiServiceId}" API service`
                );
            }

            this.patchLocalState({
                loading: true,
                noResults: false,
            });

            // if our observed state has an isLoading value defined, update the value accordingly
            if ('isLoading' in this.observedState) {
                this.patchObservedState({
                    isLoading: true,
                });
            }

            const apiResponse = await this.apiService[this.apiMethod](
                searchText
            );

            if (apiResponse.wasCancelled) {
                return;
            }

            let options;

            if (apiResponse.error) {
                console.error(apiResponse.errorMessage);
                options = [];
            } else {
                options = apiResponse.data;
            }

            this.patchLocalState({
                loading: false,
                noResults: !options.length,
            });

            // if our observed state has an isLoading value defined, update the value accordingly
            if ('isLoading' in this.observedState) {
                this.patchObservedState({
                    isLoading: false,
                });
            }

            this.setObservedStateAtAddress(options, 'options');
        } else {
            this.updateOptions(searchText);
        }
    }

    /**
     * Creates an index of the options in observedState
     *
     * NOTE: this assumes the options are already sorted alphabetically
     */
    indexOptions() {
        const options = this.displayedOptions;

        let previousLetter = '';
        for (let i = 0; i < options.length; i++) {
            const currentLetter = options[i].label.charAt(0).toLowerCase();
            if (currentLetter !== previousLetter) {
                this.filteredOptionsIndex[currentLetter] = i;
                previousLetter = currentLetter;
            }
        }
    }

    updateOptions(searchText) {
        let filteredOptions;

        // if doing an api lookup, do no further filtering
        if (this.useApi) {
            filteredOptions = this.currentAvailableOptions;
        } else {
            filteredOptions = this.getFilteredOptions(
                this.currentAvailableOptions,
                searchText
            );
        }

        // Updating an live region on pet search to let screen reader know how many results were
        // found
        if (this.numUpdates > 0) {
            this.stateController.ui.setStateAtAddress(
                filteredOptions.length
                    ? `${filteredOptions.length} results found.`
                    : 'No results found.',
                'accessibility.autosuggest.liveRegion'
            );
        }
        this.numUpdates++;

        this._localStateController.setStateAtAddress(
            filteredOptions,
            'clientFilteredOptions'
        );
        this._localStateController.setStateAtAddress(
            filteredOptions.length === 0,
            'noResults'
        );
    }

    onObservedStateChange(payload) {
        if ('options' in payload.observedModifications) {
            this.onOptionsChanged();
        }

        super.onObservedStateChange(payload);
    }

    onOptionsChanged() {
        this.indexOptions();
        this.updateOptions(this.localState.searchText);
    }

    selectItemByValue(itemValue) {
        super.selectItemByValue(itemValue);
        if (this.observedState.selectedOptionsCache) {
            this._addItemToSelectedOptionsCache(itemValue);
        }
    }

    _addItemToSelectedOptionsCache(itemValue) {
        // no need to cache if we're not doing api lookups
        if (!this.useApi) {
            return;
        }

        // find and cache item if found
        const option = this.allAvailableOptions.find(
            item => item.value === itemValue
        );
        if (!option) {
            return;
        }

        const selectedOptionsCache = this.observedState.selectedOptionsCache;

        // if we already have the item
        if (selectedOptionsCache.find(item => item.value === option.value)) {
            return;
        }

        const nextIndex = selectedOptionsCache.length;
        this.setObservedStateAtAddress(
            option,
            `selectedOptionsCache.${nextIndex}`
        );
    }

    // overriding parent class; intentionally not calling super.afterDispatchValueChange()
    afterDispatchValueChange() {
        if (this.autoCloseOnSelect) {
            // Close menu and patch search text state to the selected option on selection
            this.closeMenu();
            this.patchLocalState({
                searchText: this.selectedLabels[0],
                loading: false,
                noResults: false,
            });
        } else {
            return;
        }
    }

    // Override parent; Just open.
    // For initial focus, we're instead doing `focus-element="[${vm.id}-search-input]"`
    openMenuAndHighlightSelectedItem() {
        this.openMenu(() => {
            const input = this.querySelector('input');
            input.focus();
        });
    }

    // Extending parent.
    // Return focus to input.
    incrementHighlightedItem(offset) {
        super.incrementHighlightedItem(offset);
        // TODO: get rid of selectors/queries and instead have elements register with the
        // autocomplete
        const inputEl = this.querySelector(`[${this.id}-search-input]`);
        if (inputEl) {
            // TODO: documentation and move to utility function
            requestAnimationFrame(() =>
                requestAnimationFrame(() => {
                    inputEl.focus();
                })
            );
        }
    }

    /**
     * @method clearSelectedValues
     */
    clearSelectedValues() {
        super.clearSelectedValues();
        this.updateSearchText('');
    }
}

export default PFDCGenericAutosuggestElement;
