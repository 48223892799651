export default vm => {
    // sm, md
    const smmdTemplate = `
        <pfdc-toggle-group
            select-multiple
            toggle-group-legend="Size"
            pf-mix-change="$closest.onSizeChanged($element.value)"
            observe-state="animalSearch.filters.size"
        ></pfdc-toggle-group>
    `;

    const lgxlTemplate = `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Size</div>
        <pfdc-generic-select
            id="animal-size"
            select-multiple
            observe-state="animalSearch.filters.size"
            pf-mix-change="$closest.onSizeChanged($element.value)"
            select-placeholder="Any"
            input-aria-label="Size"
            select-theme-class="m-animalSearchSelect_mediumWide"
        ></pfdc-generic-select>
    `;

    switch (vm.state.currentBreakpoint) {
        case 'sm':
            return smmdTemplate;
        case 'md':
        case 'lg':
        case 'xl':
            return lgxlTemplate;
        default:
            console.warn('breakpoint not set; unable to determine correct size filter template');
            return smmdTemplate;
    }
};
