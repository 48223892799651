import { html, ifTruthy } from '../../../../core/scripts/util/util';

// prettier-ignore
export default vm => html`
    <fieldset>
        <legend class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-widthFull u-vr1x">
            ${vm.legend}
        </legend>
        <div class="toggleGroup ${vm.underline ? 'toggleGroup_underline' : ''} ${vm.multiline ? 'm-toggleGroup_multiLine' : ''}">
            ${vm.observedState.options.map((option, index) => {
                const optionIsSelected = vm.selectedValues.includes(option.value) || (vm.selectedValues.length === 0 && option.default === true);
                return html`
                    <label class="toggleGroup-option">
                        <input
                            class="toggleGroup-option-input"
                            type="${vm.canSelectMultiple ? 'checkbox' : 'radio'}"
                            value="${option.label}"
                            ${ifTruthy(optionIsSelected, 'checked')}
                            pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}')"
                            pf-mix-change="$event.stopPropagation()"
                            pf-mix-keydown="$event.stopPropagation()"
                            name=""
                            aria-label="$${option.label}"
                        />
                        <div class="toggleGroup-option-btn">${option.label}</div>
                    </label>
                `;
            })}
         </div>
    </fieldset>
`;
