import { html, ifTruthy } from '../../../core/scripts/util/util';
import genericTemplate, {
    WIDTH_MODES,
} from '../../../dotcom/scripts/elements/pfdc-generic-autosuggest/genericTemplate.html';
import { optionLabel } from '../../../dotcom/scripts/elements/pfdc-generic-autosuggest/partials/common.html';
import openStateMobileWithModal from '../../../dotcom/scripts/elements/pfdc-generic-autosuggest/partials/openStateMobileWithModal.html';

// prettier-ignore
const masterDef = require('../../media/images/color-swatches/master-def.json');

// prettier-ignore
const ALPHA_NUMERIC_REGEX = /[a-zA-Z0-9]+/g;

// prettier-ignore
const colorOptionsListItem = (vm, option, index) => {
    // retrieve/transform/find needed values
    const animalTypeValue = vm.state.animalSearch.filters.animalType.value[0];

    const transformedColorName = option.value
        .match(ALPHA_NUMERIC_REGEX)
        .join('-')
        .toLowerCase();

    const def = masterDef[animalTypeValue][transformedColorName];

    if (!def) {
        console.warn(`No spritesheet color entry found for type: '${animalTypeValue}', color: '${option.value}') (${transformedColorName})`);
    }

    // vars for usage in template
    const imgUrl = `/assets/www/media/images/color-swatches/${animalTypeValue}.png`;
    let leftOffset = (def) ? -def.x : 0;
    let topOffset = (def) ? -def.y : 0;

    // extra offsetting to try and better position the swatch; this is hard tied to the current positioning and size of the swatches (100x60)
    leftOffset -= 50;
    topOffset -= 10;


    return html`
        <li
            class="filterList-list-item ${vm.selectedValues.includes(option.value) ? 'isActive' : ''} ${vm.localState.highlightedIndex === index ? 'isHighlighted' : ''}"
            pf-mix-focusin="$closest.onItemHovered($event, $element, ${index})"
            role="option"
            aria-selected="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
        >
            <label class="filterList-list-chk-label" pf-mix-click="$event.stopPropagation();">
                <div class="filterList-list-chk-inner" data-test="Filters_Open_Menu_Option_List">
                    <div class="filterList-list-title u-widthFull">
                        <div class="split m-split_justifyFlexStart">
                            <div class="u-hr7x">
                                <div class="media-img">
                                    <div class="imgMask imgMask_circleSm m-imgMask_noObjectFit">
                                        <div style="width: 50px; height: 50px; background: url('${imgUrl}') ${leftOffset}px ${topOffset}px;"></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                ${optionLabel(vm, option.label)}
                                ${ifTruthy(option.subLabel, html`<div>${option.subLabel}</div>`)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="split">
                            <div class="filterList-list-qty u-hr7x">${option.count != null ? `(${option.count})` : ''}</div>
                            <div>
                                <input
                                    type="checkbox"
                                    class="filterList-list-chk u-isVisuallyHidden" ${vm.selectedValues.includes(option.value) ? 'checked' : ''}
                                    role="checkbox"
                                    aria-checked="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
                                    aria-label="${option.label}, ${vm.selectedValues.includes(option.value) ? 'selected' : 'not selected'}"
                                    pf-mix-change="$event.stopPropagation()"
                                    pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}')"
                                />
                                <div class="filterList-list-chk-svg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </label>
        </li>
    `
};

// prettier-ignore
export default genericTemplate({
    widthMode: WIDTH_MODES.DOUBLE,
    optionsListItem: colorOptionsListItem,
    openStateMobile: openStateMobileWithModal,
});
