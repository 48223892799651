export default vm => {
    // sm, md
    const smmdTemplate = `
        <pfdc-toggle-group
            select-multiple
            toggle-group-legend="Gender"
            pf-mix-change="$closest.onGenderChanged($element.value)"
            observe-state="animalSearch.filters.gender"
        ></pfdc-toggle-group>
    `;

    const lgxlTemplate = `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Gender</div>
        <pfdc-generic-select
            id="gender-select"
            select-multiple
            pf-mix-change="$closest.onGenderChanged($element.value)"
            observe-state="animalSearch.filters.gender"
            input-aria-label="Select Gender"
            select-placeholder="Any"
        ></pfdc-generic-select>
    `;

    switch (vm.state.currentBreakpoint) {
        case 'sm':
            return smmdTemplate;
        case 'md':
        case 'lg':
        case 'xl':
            return lgxlTemplate;
        default:
            console.warn('breakpoint not set; unable to determine correct gender filter template');
            return smmdTemplate;
    }
};
