export default class FacetsModel {
    static facetMap = {
        breed_id: 'breed',
        species_id: 'species',
        age: 'age',
        sex: 'gender',
        size: 'size',
        color: 'color',
        coat_length: 'coatLength',
        attributes: 'attribute',
        behaviors: 'household',
    };

    species = {};
    breed = {};
    age = {};
    gender = {};
    size = {};
    color = {};
    coatLength = {};
    attribute = {};
    household = {};

    constructor(facetsArr) {
        for (let i = 0; i < facetsArr.length; i++) {
            const facetsItem = facetsArr[i];
            const mappedId = FacetsModel.facetMap[facetsItem.facet];

            if (!mappedId) {
                console.warn(`No facet mapping for facet: ${facetsItem.facet}`);
                continue;
            }

            this[mappedId] = this._facetArrayToValueCountObject(facetsItem.values);
        }
    }

    _facetArrayToValueCountObject(values) {
        const obj = {};
        for (let i = 0; i < values.length; i++) {
            const item = values[i];
            obj[item.value] = {
                count: item.count || 0,
            };
        }

        return obj;
    }
}
