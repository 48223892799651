import PFDCElementElement from '../pfdc-element/element';
import template from './template.html';

import optinsConfigService from '../../config-services/optinsConfigService';

/**
 * @extends PFDCElementElement
 */
export class PFDCAnimalSearchOptinsElement extends PFDCElementElement {
    static _elementTag = 'pfdc-animal-search-optins';
    static template = template;

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            currentBreakpoint: this.state.currentBreakpoint,
            checkboxIds: this.dataset.cboxIds.split(','),
            checkboxNames: this.checkboxNames,
            rules: [],
        };
    }

    get checkboxNames() {
        return this.dataset.cboxNames.split(',');
    }

    onConnected() {
        super.onConnected();

        // if we have no optins, get some
        if (this.observedState.optinRules.length === 0) {
            this.getAndSetOptinRulesState();
        }
    }

    onObservedStateChange(payload) {
        if (payload.flattenedObservedModifications.country) {
            this.getAndSetOptinRulesState();
        }
        super.onObservedStateChange();
    }

    getAndSetOptinRulesState() {
        if (!this.observedState.country.value[0]) {
            return;
        }

        const requestParams = {
            formId: this.dataset.formId,
            country: this.observedState.country.value[0],
            corporation: this.dataset.corporation.split(','),
        };

        const response = optinsConfigService.getOptinRulesFromConfig(
            requestParams
        );
        this.setObservedStateAtAddress(response, 'optinRules');

        this._setDefaultOptinValues();
    }

    _setDefaultOptinValues() {
        const names = this.checkboxNames;
        const ruleDefinitions = this.observedState.optinRules;

        const optinValues = {};

        for (let i = 0; i < names.length; i++) {
            const name = names[i];
            const ruleDefinition = ruleDefinitions[i];
            const defaultValue = ruleDefinition['pre_check_checkbox'];

            optinValues[name] = defaultValue;
        }

        this.setObservedStateAtAddress(optinValues, 'optinValues');
    }
}

export default PFDCAnimalSearchOptinsElement;
