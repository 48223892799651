import {
    html,
} from '../../../core/scripts/util/util';
import contextContainer from '../../../core/scripts/context';

export default vm => {
    const state = vm.state;
    const showCountry = (vm.contextElement.stateController.state.user.country === '' && !vm.contextElement.stateController.state.userAuth);
    const showEmail = vm.contextElement.stateController.state.user.country === '';
    return html `
        <div class="tier_full">
            <div class="tier-innerTight m-txt_alignCenter">
                <div class="createSavedSearchModal_actionCard ${state.animalSearch.savedSearch.searchSet ? 'u-isInactive' : 'u-isActive'} ${state.animalSearch.savedSearch.formPosting ? 'createSavedSearch_posting' : ''}" aria-hidden="${state.animalSearch.savedSearch.searchSet ? 'true' : 'false'}">

                <pfdc-element observe-state="animalSearch.savedSearch">
                    <div pf-mix-state-show="formPosting">
                        <svg class="createSavedSearch-loader" role="img" focusable="false" viewBox="25 25 50 50">
                            <circle class="path"
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                stroke-width="5"
                                stroke="#fff"
                                stroke-dasharray="39.25, 117.75"
                            />
                        </svg>
                    </div>
                </pfdc-element>

                    <form pf-mix-submit="$closest.onCreateFormSubmit($event)" novalidate>
                        <div class="media-img">
                            <div class="createSavedSearch-icon">
                                <span class="icon icon_xxl m-icon_colorWhite">
                                    <svg role="img">
                                        <use xlink:href="#icon-alertBell" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div class="media-bd">
                            <div class="u-vr3x">
                                <div class="txt m-txt_xl m-txt_colorWhite m-txt_light" tabindex="-1">Set an email alert for &ldquo;${vm.searchName}&rdquo;</div>
                            </div>
                            <div class="u-vr4x">
                                <div class="createSavedSearch-form">
                                    <div class="txt m-txt_colorWhite u-vr3x">
                                        <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="global"></span></pfdc-element>
                                    </div>
                                </div>

                                <div id="Create_Saved_Search_Modal_Error_Description"
                                    class="txt txt_error m-txt_colorWhite">
                                    <pfdc-generic-select
                                        id="Create_Saved_Search_Modal_Country_Field"
                                        pf-mix-change="$closest.onCountryChanged($event, $element)"
                                        observe-state="animalSearch.savedSearch.country"
                                        aria-describedby-id="CountryNotListedMessage"
                                        input-aria-label="Select Country"
                                        select-placeholder="Select Country"
                                    ></pfdc-generic-select>

                                    <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="country"></span></pfdc-element>
                                </div>

                                <pfdc-input class="field" is-dynamic>
                                    <label for="Create_Saved_Search_Modal_Email" class="field-label">Email</label>
                                    <input pf-mix-change="$closest.onEmailInputChanged($event, $element)" pf-input-input id="Create_Saved_Search_Modal_Email" class="field-input" type="email" spellcheck="false" name="saved_search[email]" value="${state.animalSearch.savedSearch.emailInputValue}" required aria-describedby="Create_Saved_Search_Modal_Email_Error_Description" />

                                    <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="email"></span></pfdc-element>
                                </pfdc-input>
                            </div>

                            <pfdc-element class="u-textLeft" render-on-state-change="ui.countryNotListed" template="countryNotListed"></pfdc-element>

                            <pfdc-animal-search-optins
                                class="optins optins_savedSearchCard"
                                observe-state="animalSearch.savedSearch"
                                data-form-id="saved-search"
                                data-corporation="pf,pu"
                                data-cbox-ids="Create_Saved_Search_Modal_Petfinder,Create_Saved_Search_Modal_Sponsor"
                                data-cbox-names="saved_search[emailOptIn][petfinder],saved_search[emailOptIn][sponsor]"
                            ></pfdc-animal-search-optins>

                            <button class="btn btn_white m-btn_full" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                <pfdc-element observe-state="animalSearch.savedSearch">
                    <div pf-mix-state-show="searchSet">
                        ${searchSetSlideout(vm)}
                    </div>
                </pfdc-element>
            </div>

            <pf-element class="fullscreenCarousel-close"
                on-click="ui.deactivate"
                data-target="#Save_Search_Modal">
                <button pf-mix-click="trackingBridge.ensighten('Consumer084')" class="modal-closeBtn modal-closeBtn_colorWhite"
                    type="button"
                    aria-label="Close this dialog">Close this dialog</button>
            </pf-element>
        </div>
    `;
};

const searchSetSlideout = vm => {
    const state = vm.state;
    return html `
        <div pfdc-create-saved-search-set-slideout class="createSavedSearchModal_success">
            <div class="media media_stack@maxMd m-media_gutterLg m-media_alignMiddle">
                <div class="media-img">
                    <div class="createSavedSearch-icon">
                        <span class="icon icon_xxl m-icon_colorWhite">
                            <svg role="img" focusable="false">
                                <use xlink:href="#icon-alertBell_checked" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="media-bd">
                    <div class="u-vr6x">
                        <div class="txt m-txt_xxl m-txt_colorWhite m-txt_light">Your new pet email alert has been set</div>
                        <div class="txt m-txt_colorWhite">For ${vm.searchName}</div>
                    </div>
                    <pf-element
                        on-click="ui.deactivate"
                        data-target="#Save_Search_Modal">
                        <button type="button" class="btn btn_clear u-vr3x"
                        aria-label="Finish and close the save search modal">
                                Done
                        </button>
                    </pf-element>
                   <a href="/user/search-alerts/" pf-mix-click="trackingBridge.ensighten('Consumer088')">
                        <span class="icon icon_sm m-icon_colorWhite m-icon_gutterSm">
                            <svg role="img">
                                <use xlink:href="#icon-edit" />
                            </svg>
                        </span>
                        <span class="txt m-txt_colorWhite m-txt_lg m-txt_tight m-txt_alignCenter">Edit</span>
                    </a>
                </div>
            </div>
        </div>
    `;
};

const globalErrors = errors => html `
    ${errors.map(error => html`
        ${error}
    `)}
`;

const fieldErrors = errors => html `
    ${errors.map(error => html`
        ${error}
    `)}
`;
