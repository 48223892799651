import { PFDCElementElement } from '../pfdc-element/element';
import template from './template.html';

/**
 * TODO
 */
export class PFDCAnimalTypeSelectRadiosElement extends PFDCElementElement {
    static _elementTag = 'pfdc-animal-type-select-radios';
    static template = template;

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            currentBreakpoint: this.state.currentBreakpoint,
            showOtherPets: false,
        };
    }

    get viewModel() {
        return {
            ...super.viewModel,
            contextElement: this,
        };
    }

    onConnected() {
        super.onConnected();
    }

    onBreakpointChanged(currentBreakpoint) {
        this.patchLocalState({
            currentBreakpoint,
        });
    }

    onOtherPetsClick(ev, element) {
        this.patchLocalState({
            showOtherPets: !this.localState.showOtherPets,
        });
    }
}

export default PFDCAnimalTypeSelectRadiosElement;
