import { html, ifTruthy } from '../../../../../core/scripts/util/util';
import Utils from '../../../../../core/scripts/lib/wirey/Utils';
const replaceAt = Utils.StringUtils.replaceAt;

// prettier-ignore
const selectionsDisplay = vm => {
    return html`
        <div class="txt ${vm.selectedLabels.length ? 'u-isHidden' : ''}">
            ${`All ${vm.unitLabelPlural} (${vm.observedState.options.length})`}
        </div>

        ${ifTruthy(vm.selectedLabels.length, html`
            <div class="txt m-txt_heavy m-txt_colorPrimaryS1 filterList-textTruncate">
                ${vm.selectedLabels.length === 1 ? vm.selectedLabels[0] : `${vm.selectedLabels.length} ${vm.unitLabelPlural}`}
            </div>
        `)}
    `;
};

// prettier-ignore
const searchInput = vm => html`
    <div class="filterList-search">
        <p id="${vm.id}_SearchInput_Description" class="u-isVisuallyHidden" aria-hidden="true">This is an autocomplete field. You can use tab to navigate the list and enter to select list items. We will populate or filter a list of suggestions as you type. We will notify you of the number of suggestions that match your query as you type. If suggestions are found, press the tab key to focus the suggestions list. Press enter to set the focused suggestion item as the value of this field. Press escape in the suggestions list to close the list and return to the search input field.</p>
        <label for="${vm.id}_SearchInput" class="u-isVisuallyHidden">${vm.inputAriaLabel}</label>
        <input
            ${vm.id}-search-input
            type="text"
            id="${vm.id}_SearchInput"
            pf-mix-change="$event.stopPropagation()"
            pf-mix-click="$event.stopPropagation()"
            pf-mix-keydown="$closest.onSearchInputKeydown($event, $element)"
            pf-mix-keyup="$closest.onSearchInputKeyup($event, $element)"
            pf-mix-input="$closest.onSearchInputted($event, $element)"
            pf-mix-blur="trackingBridge.ensighten('Consumer067_279_280')"
            value="${vm.localState.searchText}"
            placeholder="${vm.placeholder}"
            spellcheck="false"
            autocomplete="off"
            aria-autocomplete="list"
            aria-describedby="${vm.id}_SearchInput_Description"
            data-test="Filters_Open_Menu_Search_Input"
        >
        <div class="filterList-search-icon">
            <span class="icon m-icon_colorDark">
                <svg role="img" focusable="false" aria-hidden="true">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-search"></use>
                </svg>
            </span>
        </div>
    </div>
`;

// prettier-ignore
const optionsList = vm => {
    if (vm.localState.loading) {
        return optionsListMessageDesktop('Loading...');
    }
    if (vm.localState.noResults) {
        return optionsListMessageDesktop(`No ${vm.unitLabelPlural.toLowerCase()} found`);
    }
    return html`
        <ul autocomplete-list
            pf-mix-init="$closest.setListElement($element)"
            class="filterList-list"
            id="${vm.id}_List_Box"
            role="listbox"
            aria-multiselectable="${vm.canSelectMultiple ? 'true' : 'false'}"
        >
            ${vm.displayedOptions.map((option, index) => {

            if (option.display === false) {
                return '';
            }

            return html`
                    ${vm.props.optionsListItem(vm, option, index)}
                `;
        })}
        </ul>
    `;
};

// prettier-ignore
const optionsListItem = (vm, option, index) => html`
    <li
        class="filterList-list-item ${vm.selectedValues.includes(option.value) ? 'isActive' : ''} ${vm.localState.highlightedIndex === index ? 'isHighlighted' : ''}"
        pf-mix-focusin="$closest.onItemHovered($event, $element, ${index})"
        role="option"
        aria-selected="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
    >
        <label class="filterList-list-chk-label" pf-mix-click="$event.stopPropagation();">
            <div class="filterList-list-chk-inner" data-test="Filters_Open_Menu_Option_List">
                <div class="filterList-list-title">
                    ${optionLabel(vm, option.label)}
                    ${ifTruthy(option.subLabel, html`<div>${option.subLabel}</div>`)}
                </div>
                <div>
                    <div class="split">
                        <div class="filterList-list-qty u-hr7x">${option.count != null ? `(${option.count})` : ''}</div>
                        <div>
                            <input
                                type="checkbox"
                                class="filterList-list-chk u-isVisuallyHidden" ${vm.selectedValues.includes(option.value) ? 'checked' : ''}
                                role="checkbox"
                                aria-checked="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
                                aria-label="${option.label}, ${vm.selectedValues.includes(option.value) ? 'selected' : 'not selected'}"
                                pf-mix-change="$event.stopPropagation()"
                                pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}')"
                            />
                            <div class="filterList-list-chk-svg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </label>
    </li>
`;

// prettier-ignore
// do we need a seperate mobile style?
const optionsListMessageDesktop = message => html`
    <ul autocomplete-list class="filterList-list">
        <li class="filterList-list-item">
            <div class="filterList-list-btn">
                <div class="filterList-list-title filterList-list-title_userMessage">
                    ${message}
                </div>
            </div>
        </li>
    </ul>
`;

// prettier-ignore
const optionLabel = (vm, label) => {
    return boldSearchTextInLabel(vm.localState.searchText, label);
};

// prettier-ignore
// replace matching str with bold str
const boldSearchTextInLabel = (searchText, label) => {
    if (!searchText) {
        return label;
    }
    const regx = new RegExp('(' + searchText + ')', 'gi');
    const newStr = label.replace(regx, boldText('$1'));

    return html`${newStr}`;
};

// prettier-ignore
// bolds matching first letter of each word
const boldAlphaLettersInLabel = (letter, label) => {
    const words = label.split(' ');
    const newStr = words
        .map(word =>
            word.toLowerCase()[0] === letter
                ? replaceAt(word, 0, boldText(word[0]))
                : word
        )
        .join(' ');

    return html`${newStr}`;
};

// prettier-ignore
const boldText = text => html`<span class="filterList-list-title_bold">${text}</span>`;

// prettier-ignore
export {
    selectionsDisplay,
    searchInput,
    optionsList,
    optionsListItem,
    optionsListMessageDesktop,
    optionLabel,
    boldSearchTextInLabel,
    boldAlphaLettersInLabel,
};
