import initShared from '../shared';
import initTcombForm from '../utils/load-tcomb';
import initCustomElements from './custom-elements';
import initReactClientComponents from './react-client-components';
import initReactServerComponents from './react-server-components';
import initWireyMixins from './wirey-mixins';
import initWireyTemplates from './wirey-templates';
import AutoLogout from '../../../../core/scripts/services/AutoLogout';

async function bootstrap() {
    await initShared();
    initCustomElements();
    initTcombForm();
    initReactClientComponents();
    initReactServerComponents();
    initWireyMixins();
    initWireyTemplates();

    AutoLogout.init();
}

bootstrap();
