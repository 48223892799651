export const animalTypeIconMap = {
    dogs: 'dog',
    cats: 'cat',
    rabbits: 'rabbit',
    'small-furry': 'smallFurry',
    'scales-fins-others': 'fish',
    birds: 'bird',
    horses: 'horse',
    barnyard: 'barnyard',
};

export const animalTypeLabelMap = {
    '': {
        singular: 'All Pet Types',
        plural: 'All Pet Types',
    },
    dogs: {
        singular: 'Dog',
        plural: 'Dogs',
    },
    cats: {
        singular: 'Cat',
        plural: 'Cats',
    },
    rabbits: {
        singular: 'Rabbit',
        plural: 'Rabbits',
    },
    'small-furry': {
        singular: 'Small & Furry',
        plural: 'Small & Furry',
    },
    'scales-fins-others': {
        singular: 'Scales, Fins, or Other',
        plural: 'Scales, Fins, & Others',
    },
    birds: {
        singular: 'Bird',
        plural: 'Birds',
    },
    horses: {
        singular: 'Horse',
        plural: 'Horses',
    },
    barnyard: {
        singular: 'Barnyard',
        plural: 'Barnyard',
    },
};

export const animalTypeBreedLabelMap = {
    '': 'All Pet Breeds',
    dogs: 'All Dog Breeds',
    cats: 'All Cat Breeds',
    rabbits: 'All Rabbit Breeds',
    'small-furry': 'All Small & Furry Breeds',
    'scales-fins-others': 'All Scales, Fins, & Other Breeds',
    birds: 'All Bird Breeds',
    horses: 'All Horse Breeds',
    barnyard: 'All Barnyard Breeds',
};

//=======================================================
// Color Swatches:
//=======================================================

const dogsColorSwatchesMap = {
    'Apricot / Beige': 'apricot-beige',
    Bicolor: 'bicolor',
    Black: 'black',
    Brindle: 'brindle',
    'Brown / Chocolate': 'brown-chocolate',
    Golden: 'golden',
    'Gray / Blue / Silver': 'gray-blue-silver',
    Harlequin: 'harlequin',
    'Merle (Blue)': 'merle-blue',
    'Merle (Red)': 'merle-red',
    'Red / Chestnut / Orange': 'red-chestnut-orange',
    Sable: 'sable',
    'Tricolor (Brown, Black, &amp; White)': 'tricolor-brown-black-white',
    'White / Cream': 'white-cream',
    'Yellow / Tan / Blond / Fawn': 'yellow-tan-blond-fawn',
};

const catsColorSwatchesMap = {
    'Black & White / Tuxedo': 'black-white-tuxedo',
    Black: 'black',
    'Blue Cream': 'blue-cream',
    'Blue Point': 'blue-point',
    'Brown / Chocolate': 'brown-chocolate',
    'Buff / Tan / Fawn': 'buff-tan-fawn',
    Calico: 'calico',
    'Chocolate Point': 'chocolate-point',
    'Cream / Ivory': 'cream-ivory',
    'Cream Point': 'cream-point',
    'Dilute Calico': 'dilute-calico',
    'Dilute Tortoiseshell': 'dilute-tortoiseshell',
    'Flame Point': 'flame-point',
    'Gray / Blue / Silver': 'gray-blue-silver',
    'Lilac Point': 'lilac-point',
    'Orange / Red': 'orange-red',
    'Seal Point': 'seal-point',
    Smoke: 'smoke',
    'Tabby (Brown / Chocolate)': 'tabby-brown-chocolate',
    'Tabby (Buff / Tan / Fawn)': 'tabby-buff-tan-fawn',
    'Tabby (Gray / Blue / Silver)': 'tabby-gray-blue-silver',
    'Tabby (Leopard / Spotted)': 'tabby-leopard-spotted',
    'Tabby (Orange / Red)': 'tabby-orange-red',
    'Tabby (Tiger Striped)': 'tabby-tiger-striped',
    Torbie: 'torbie',
    Tortoiseshell: 'tortoiseshell',
    White: 'white',
};

const rabbitsColorSwatchesMap = {
    Agouti: 'agouti',
    Black: 'black',
    'Blue / Gray': 'blue-gray',
    'Brown / Chocolate': 'brown-chocolate',
    Cream: 'cream',
    Lilac: 'lilac',
    'Orange / Red': 'orange-red',
    Sable: 'sable',
    'Silver Marten': 'silver-marten',
    Tan: 'tan',
    Tortoiseshell: 'tortoiseshell',
    White: 'white',
};

const smallFurryColorSwatchesMap = {
    Agouti: 'agouti',
    Albino: 'albino',
    'Black Sable': 'black-sable',
    Black: 'black',
    'Blue / Gray': 'blue-gray',
    'Brown / Chocolate': 'brown-chocolate',
    Calico: 'calico',
    Champagne: 'champagne',
    Cinnamon: 'cinnamon',
    Cream: 'cream',
    'Orange / Red': 'orange-red',
    Sable: 'sable',
    Tan: 'tan',
    Tortoiseshell: 'tortoiseshell',
    'White (Dark-Eyed)': 'dark-eyed-white',
    White: 'white',
};

const scalesFinsOthersColorSwatchesMap = {
    Black: 'black',
    Blue: 'blue',
    Brown: 'brown',
    Gray: 'gray',
    Green: 'green',
    Iridescent: 'iridescent',
    Orange: 'orange',
    Purple: 'purple',
    Red: 'red',
    Tan: 'tan',
    White: 'white',
    Yellow: 'yellow',
};

const birdsColorSwatchesMap = {
    'Black / White': 'black-white',
    Black: 'black',
    Blue: 'blue',
    Brown: 'brown',
    Buff: 'buff',
    Gray: 'gray',
    Green: 'green',
    Olive: 'olive',
    Orange: 'orange',
    Pink: 'pink',
    'Purple / Violet': 'purple-violet',
    Red: 'red',
    'Rust / Rufous': 'rust-rufous',
    Tan: 'tan',
    White: 'white',
    Yellow: 'yellow',
};

const horsesColorSwatchesMap = {
    Appaloosa: 'appaloosa',
    Bay: 'bay',
    'Bay Roan': 'bay-roan',
    Black: 'black',
    'Blue Roan': 'blue-roan',
    Brown: 'brown',
    Buckskin: 'buckskin',
    Champagne: 'champagne',
    'Chestnut / Sorrel': 'chestnut-sorrel',
    Cremello: 'cremello',
    'Dapple Gray': 'dapple-gray',
    Dun: 'dun',
    Gray: 'gray',
    Grullo: 'grullo',
    Liver: 'liver',
    Paint: 'paint',
    Palomino: 'palomino',
    Perlino: 'perlino',
    Piebald: 'piebald',
    Pinto: 'pinto',
    'Red Roan': 'red-roan',
    'Silver Bay': 'silver-bay',
    'Silver Buckskin': 'silver-buckskin',
    'Silver Dapple': 'silver-dapple',
    White: 'white',
};

const barnyardColorSwatchesMap = {
    Agouti: 'agouti',
    Black: 'black',
    'Black & White': 'black-white',
    Brindle: 'brindle',
    Brown: 'brown',
    Gray: 'gray',
    Pink: 'pink',
    Red: 'red',
    Roan: 'roan',
    Spotted: 'spotted',
    Tan: 'tan',
    White: 'white',
};

export const colorSwatchesMap = {
    dogs: dogsColorSwatchesMap,
    cats: catsColorSwatchesMap,
    rabbits: rabbitsColorSwatchesMap,
    'small-furry': smallFurryColorSwatchesMap,
    'scales-fins-others': scalesFinsOthersColorSwatchesMap,
    birds: birdsColorSwatchesMap,
    horses: horsesColorSwatchesMap,
    barnyard: barnyardColorSwatchesMap,
};
