import APIService from '../../../core/scripts/services/APIService';

import APIResponse from '../../../core/scripts/services/APIResponse';

import pfCacheService, {
    PF_CACHE_MAP,
} from '../../../core/scripts/lib/pfCacheService';

import {
    COUNTRY_NOT_LISTED,
    COUNTRY_NOT_LISTED_VALUE,
} from '../../../core/scripts/constants/geography';
import { Config } from '../../../core/scripts/lib/Config';

class GeographyAPIService extends APIService {
    static url = '/v2/geography/';

    async _searchLocations(q, lat, lng) {
        const options = {
            urlAppend: 'search/',
            method: 'GET',
            params: {
                q,
                lat,
                lng,
            },
            lateResponseCancelling: true,
        };

        return await this.queryAndProcess(options);
    }

    async _requestCountriesFromAPI() {
        const options = {
            urlAppend: 'countries/',
            method: 'GET',
            params: {
                limit: 300,
                sort: 'name',
            },
        };

        // TODO: this should probably be converted to use queryAndProcess()
        return this.query(options);
    }

    async _getCountries() {
        if (!pfCacheService.has(PF_CACHE_MAP.COUNTRY_JSON_PROMISE)) {
            const jsonPromise = this._requestCountriesFromAPI().then(
                response => {
                    return response.json();
                }
            );

            pfCacheService.add(PF_CACHE_MAP.COUNTRY_JSON_PROMISE, jsonPromise);
        }

        const jsonPromise = pfCacheService.get(
            PF_CACHE_MAP.COUNTRY_JSON_PROMISE
        );
        return (await jsonPromise).countries;
    }

    /**
     * Gets locations and structures for use with generic-autosuggest
     *
     * @param  {string}  q Search query
     * @async
     * @return {Promise}
     */
    async getLocationsForAutosuggest(q) {
        const lat = await Config.usersLatitude;
        const lng = await Config.usersLongitude;
        const response = await this._searchLocations(q, lat, lng);

        // remap data to our desired format
        if (!response.error && response.data && response.data.locations) {
            response.data = response.data.locations.map(item => {
                return {
                    ...item,
                    label: item.display_name,
                    value: item.slug,
                };
            });
        } else {
            // gracefully handle error by setting data to an empty array
            // TODO: anything else we want to do here?
            response.data = [];
        }

        return response;
    }

    async getCountriesForGenericSelect() {
        // try {
        const countries = await this._getCountries();

        const countryNotListed = [
            // Divider option
            {
                label: '-------------------',
                value: '',
            },
            {
                label: COUNTRY_NOT_LISTED,
                value: COUNTRY_NOT_LISTED_VALUE,
            },
        ];

        const mappedJson = countries.map(item => {
            return {
                ...item,
                label: item.name,
                value: item.code,
            };
        });
        return new APIResponse(mappedJson.concat(countryNotListed));
        // } catch (error) {
        //     return new APIResponse(null, error);
        // }
    }

    /**
     *
     * @param  {string} countryCode
     * @param  {Object} additionalParams
     * @async
     * @return {Promise}
     * See API docs for additionalParams, note that by default the full list of states is not returned (see 'limit')
     */
    async _searchStates(countryCode, additionalParams = {}) {
        const options = {
            urlAppend: `countries/${countryCode}/states/`,
            method: 'GET',
            params: {
                ...additionalParams,
            },
            lateResponseCancelling: true,
        };

        return await this.queryAndProcess(options);
    }

    /**
     *
     * @param  {string} countryCode
     * @param  {Object} additionalParams
     * @async
     * @return {Promise}
     */
    async getStatesFromCountryCode(countryCode, additionalParams) {
        const response = await this._searchStates(countryCode, additionalParams);

        // remap data to our desired format, maintain reference to associated country
        if (!response.error && response.data && response.data.states) {
            response.data = response.data.states.map(item => {
                return {
                    name: item.name,
                    code: item.code,
                    countryCode,
                };
            });
        } else {
            response.data = [];
        }
        return response;
    }
}

const instance = new GeographyAPIService();
APIService.register('geography', instance);

export default instance;
