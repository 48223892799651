import { html } from '../../../core/scripts/util/util';
import { helpers } from '../../../core/scripts/util/helpers';
import contextContainer from '../../../core/scripts/context';

export default vm => {
    const state = vm.state;
    const userAuth = state.userAuth;
    return html`
    <pfdc-element
        observe-state="animalSearch.savedSearch"
        disable-observed-state-rendering
        render-on-state-change="animalSearchState.state.savedSearch.pristine, animalSearchState.state.savedSearch.createSearchFormOpen, animalSearchState.state.savedSearch.searchSet">

        <div pf-mix-state-show="pristine">
            <div class="createSavedSearchCard u-posRelative">
                <pfdc-element observe-state="animalSearch.savedSearch">
                    <div pf-mix-state-show="formPosting">
                        <svg class="createSavedSearch-loader" role="img" focusable="false" viewBox="25 25 50 50">
                            <circle class="path"
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                stroke-width="5"
                                stroke="#fff"
                                stroke-dasharray="39.25, 117.75"
                            />
                        </svg>
                    </div>
                </pfdc-element>
                <div class="actionCard actionCard_horizontal actionCard_btn" pf-mix-click="$closest.onToggleSavedSearchFormClicked($event, $element)"
                pf-mix-keyup="$closest.onToggleSavedSearchFormClicked($event, $element)" role="button" aria-label="Save a search for ${vm.searchName}" tabindex="0">
                    <div class="actionCard-body">
                        <h3 class="txt m-txt_xl m-txt_colorWhite">Save a search for &ldquo;${vm.searchName}&rdquo;</h3>
                    </div>
                    <div class="actionCard-footer">
                        <button
                            type="button"
                            class="actionCard-footer-cta"
                            tabindex="-1"
                            aria-hidden="true">
                            <span>Save Search</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div pf-mix-state-show="createSearchFormOpen">
            <pfdc-element observe-state="animalSearch.savedSearch">
                <div pf-mix-state-show="formPosting">
                    <svg class="createSavedSearch-loader" role="img" focusable="false" viewBox="25 25 50 50">
                        <circle class="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke-width="5"
                            stroke="#fff"
                            stroke-dasharray="39.25, 117.75"
                        />
                    </svg>
                </div>
            </pfdc-element>

            <form pf-mix-submit="$closest.onCreateFormSubmit($event)" novalidate>
                <div class="actionCard actionCard_horizontal m-actionCard_overflowVisible">
                    <div class="actionCard-body">
                        <div class="media media_stack@maxMd m-media_gutterLg u-posRelative">
                            <div class="media-img">
                                <div class="createSavedSearch-icon">
                                    <span class="icon icon_createSavedSearch m-icon_colorWhite">
                                        <svg role="img" focusable="false">
                                            <use xlink:href="#icon-alertBell" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="media-bd">
                            <button
                                pf-mix-click="$closest.onToggleSavedSearchFormClicked($event, $element)"
                                class="createSavedSearch-closeBtn u-isHidden@minMd"
                                type="button"
                                aria-label="Cancel and close set alert form">
                                <span class="icon icon_sm m-icon_colorWhite">
                                    <svg role="img" focusable="false">
                                        <use xlink:href="#icon-close" />
                                    </svg>
                                </span>
                            </button>
                                <div class="createSavedSearch-header u-vr3x">
                                    <div class="txt m-txt_colorWhite m-txt_lg" tabindex="-1">Set an email alert for new pets matching this search</div>
                                    <button
                                        pf-mix-click="$closest.onToggleSavedSearchFormClicked($event, $element)"
                                        class="createSavedSearch-closeBtn u-isHidden@maxMd"
                                        type="button"
                                        aria-label="Cancel and close set alert form">
                                        <span class="icon icon_sm m-icon_colorWhite">
                                            <svg role="img" focusable="false">
                                                <use xlink:href="#icon-close" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div class="createSavedSearch-form">
                                    <div class="txt m-txt_colorWhite u-vr3x">
                                        <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="global"></span></pfdc-element>
                                    </div>
                                    <div class="grid grid_gutterLg u-vr3x">
                                        <div class="grid-col grid-col_1/1 grid-col_1/2@minMd">
                                            <pfdc-generic-select
                                                id="Create_Saved_Search_Result_Card_Country_Field"
                                                pf-mix-change="$closest.onCountryChanged($event, $element)"
                                                observe-state="animalSearch.savedSearch.country"
                                                aria-describedby-id="CountryNotListedMessage"
                                                input-aria-label="Select Country"
                                                select-placeholder="Select Country"
                                            ></pfdc-generic-select>

                                            <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="country"></span></pfdc-element>
                                        </div>
                                        <div class="grid-col grid-col_1/1 grid-col_1/2@minMd">
                                            <pfdc-input class="field ${state.animalSearch.savedSearch.emailInputValue ? 'u-hasInput' : ''}" is-dynamic>
                                                <label for="Create_Saved_Search_Result_Card_Email" class="field-label">Email</label>
                                                <input pf-mix-change="$closest.onEmailInputChanged($event, $element)" pf-input-input id="Create_Saved_Search_Result_Card_Email" class="field-input" type="email" spellcheck="false" name="saved_search[email]" value="${state.animalSearch.savedSearch.emailInputValue}" required aria-describedby="Create_Saved_Search_Slideout_Email_Error_Description" />

                                                <pfdc-element observe-state="animalSearch.savedSearch.errors" class="txt txt_error m-txt_colorWhite"><span pf-mix-state-value="email"></span></pfdc-element>
                                            </pfdc-input>
                                        </div>
                                    </div>

                                    <pfdc-element class="u-textLeft" render-on-state-change="ui.countryNotListed" template="countryNotListed"></pfdc-element>

                                    <pfdc-animal-search-optins
                                        class="optins optins_savedSearchCard"
                                        observe-state="animalSearch.savedSearch"
                                        data-form-id="saved-search"
                                        data-corporation="pf,pu"
                                        data-cbox-ids="Create_Saved_Search_Card_Petfinder,Create_Saved_Search_Card_Sponsor"
                                        data-cbox-names="saved_search[emailOptIn][petfinder],saved_search[emailOptIn][sponsor]"
                                    ></pfdc-animal-search-optins>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actionCard-footer">
                        <button type="submit" class="actionCard-footer-cta">Submit</button>
                    </div>
                </div>
            <form>
        </div>

        <div pf-mix-state-show="searchSet">
            <div pfdc-create-saved-search-set-slideout>
                <div class="actionCard actionCard_horizontal">
                    <div class="actionCard-body m-actionCard-body_center">
                        <div class="media media_stack@maxMd m-media_gutterLg m-media_alignMiddle m-media_grow">
                            <div class="media-img">
                                <div class="createSavedSearch-icon">
                                    <span class="icon icon_createSavedSearch m-icon_colorWhite">
                                        <svg role="img" focusable="false">
                                            <use xlink:href="#icon-alertBell_checked" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="media-bd">
                                <span class="txt m-txt_colorWhite m-txt_lg m-txt_tight m-txt_alignCenter">Your new pet email alert has been set</span>
                            </div>
                        </div>
                    </div>

                    ${userAuth ? html`
                        <div class="actionCard-footer">
                            <a href="/user/search-alerts/" class="actionCard-footer-cta" aria-label="Navigate to search alert settings" pf-mix-click="trackingBridge.ensighten('Consumer088')">
                                <span class="icon icon_md m-icon_colorWhite">
                                    <svg role="img">
                                        <use xlink:href="#icon-edit" />
                                    </svg>
                                </span>
                                <span>Edit</span>
                            </a>
                        </div>
                    ` : ''}
                </div>
            </div>
        </div>
    </pfdc-element>
    `;
};
