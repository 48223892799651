import { html } from '../../../core/scripts/util/util';
import genericTemplate, { WIDTH_MODES } from '../../../dotcom/scripts/elements/pfdc-generic-autosuggest/genericTemplate.html';
import openStateMobileWithModal from '../../../dotcom/scripts/elements/pfdc-generic-autosuggest/partials/openStateMobileWithModal.html';
import zip from 'lodash/zip';

const shelterSelectionsDisplay = vm => {
    const selectedLabelIndexPairs = zip(vm.selectedLabels, vm.selectedIndices, vm.selectedValues);

    return html`
        <div class="split-item split-item_grow">
            <!-- Spacing out the height of the box for IE11 which doesnt center with min-height -->
            ${!vm.selectedLabels.length ? html`<div style="width:0; height: 55px;"></div>` : ''}

            <div class="txt ${vm.selectedLabels.length ? 'u-isHidden' : ''}">

            </div>
            <div class="filterList-hd-controls-title-viewPort txt m-txt_colorPrimaryS1 ${!vm.selectedLabels.length ? 'u-isHidden' : ''}">
                ${selectedLabelIndexPairs.map(shelterChiclet)}
            </div>
        </div>
    `;
};

const shelterChiclet = ([label, index, value]) => {
    return html`
        <div class="filteredItem" pf-mix-click="$event.stopPropagation()">
            <div class="filteredItem-title">
                ${label}
            </div>
            <button
                class="filteredItem-del"
                pf-mix-click="$closest.onRemoveItemClicked($event, $element, ${index}, '${escape(value)}')"
                aria-label="Remove"
            >
                <span class="icon icon_xs m-icon_colorWhite">
                    <svg role="img">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use>
                    </svg>
                </span>
            </button>
        </div>
    `;
}

export default genericTemplate({
    widthMode: WIDTH_MODES.DOUBLE,
    selectionsDisplay: shelterSelectionsDisplay,
    openStateMobile: openStateMobileWithModal,
    expandableList: true,
});
