import Mixins from '../../../../core/scripts/lib/wirey/Mixins';
import '../../lib/wirey-mixins/pf-mix-add-class-on-child-focus';
import '../../lib/wirey-mixins/FocusFirstFocusableOnRenderMixin';
import '../../lib/wirey-mixins/StateShowMixin';
import '../../lib/wirey-mixins/StateHideMixin';
import '../../lib/wirey-mixins/StateValueMixin';

export default function initWireyMixins() {
    Mixins.setPrefix('pf-');
    Mixins.init();
}
