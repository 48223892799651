import React from 'react';
import PropTypes from 'prop-types';
import Svg from '../../../../../core/scripts/react-components/generics/Svg';
import buildClassNames from 'classnames';

/**
 * Renders a checkbox card, this component is designed to be controlled by a parent component
 * @param {Object} props
 * @returns {string}
 */
export default function CheckboxCard(props) {
    /**
     * Build classnames for CheckboxCard
     * @private
     * @returns {string}
     */
    function _rootClassNames() {
        const { extensionClassNames } = props;

        return buildClassNames({
            checkboxCard: true,
            ...extensionClassNames,
        });
    }

    return (
        <label htmlFor={props.id} data-test={props.dataTestId} className={_rootClassNames()}>
            <input
                id={props.id}
                className="checkboxCard-input"
                name={props.name}
                type={props.type}
                checked={props.checked}
                value={props.value}
                aria-checked={props.checked}
                aria-label={props.label}
                aria-describedby={`${props.id}-description`}
                onChange={ev => props.onChange(ev.target.checked)}
            />
            <span className="checkboxCard-bd">
                {props.iconId && (
                    <span className="checkboxCard-bd-icon">
                        <Svg xlinkHref={props.iconId} />
                    </span>
                )}
                <span className="checkboxCard-bd-label">{props.label}</span>
                {props.description && (
                    <span id={`${props.id}-description`} className="checkboxCard-bd-description">
                        {props.description}
                    </span>
                )}
            </span>
        </label>
    );
}

CheckboxCard.propTypes = {
    checked: PropTypes.bool.isRequired,
    description: PropTypes.string,
    iconId: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
    dataTestId: PropTypes.string,
    value: PropTypes.string.isRequired,
    extensionClassNames: PropTypes.objectOf(PropTypes.bool),
};

/**
 * Layout grouping component for CheckboxCard
 * @param {Object} props
 * @returns {string}
 */
export function CheckboxCardList(props) {
    return (
        <div className="checkboxCardList">
            {props.inputs.map(input => {
                let checked;

                switch (props.type) {
                    case 'checkbox':
                        checked = props.value[input.id];
                        break;
                    case 'radio':
                        checked = props.value === input.value;
                        break;
                }

                return (
                    <div key={input.id} className="checkboxCardList-item">
                        <CheckboxCard
                            {...input}
                            checked={checked}
                            name={props.name}
                            type={props.type}
                            value={input.value}
                            onChange={checked => {
                                let value;

                                switch (props.type) {
                                    case 'checkbox':
                                        value = checked;
                                        break;
                                    case 'radio':
                                        value = input.value;
                                        break;
                                }

                                props.onChange(props.name, input.id, value);
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
}

CheckboxCardList.propTypes = {
    inputs: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            iconId: PropTypes.string,
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
    value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.bool), PropTypes.string]).isRequired,
};
