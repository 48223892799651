import { html } from '../../../../../core/scripts/util/util';

import openStateMobileControlBar from './openStateMobileControlBar.html';
import { searchInput, optionsList } from './common.html';

export default (vm) => {
    return html`
        <!-- mix-click is here to swallow the click and prevent closing of the menu -->
        <div pf-mix-click="$event.stopPropagation()" class="filterList m-filterList_colorWhite">
            <div class="filterList-hd">
                <div class="filterList-hd-controls">
                    ${openStateMobileControlBar(vm)}
                </div>
                ${searchInput(vm)}
            </div>
            <div pf-mix-init="$closest.setScrollElement($element)" class="filterList-bd">
                ${optionsList(vm)}
            </div>
            <div class="filterList-ft">
                <button pf-mix-click="globalFilterChicletsElement.resetAllChicletFilters('modal'); $closest.onToggleMoreFiltersBtnClicked($event, $element)" class="filterList-ft-resetBtn">Reset</button>
                <button
                    type="button"
                    class="filterList-ft-resultsBtn"
                    pf-mix-click="$closest.onToggleMoreFiltersBtnClicked($event, $element)"
                >
                    <span class="txt m-txt_heavy m-txt_uppercase m-txt_color m-txt_colorPrimary">See ${vm.totalResults} ${vm.selectedAnimalTypeLabel}</span>
                </button>
            </div>
        </div>
    `;
}
