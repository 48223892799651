import PFDCElementElement from '../pfdc-element/element';
import contextContainer from '../../../../core/scripts/context';
import { buildCustomEvent } from '../../../../core/scripts/util/dom';
import template from './template.html';
import { EV_UI_ACTIVATE } from '../../constants/events';
import { KEY_ENTER, KEY_SPACE, KEY_SPACE_BAR } from '../../constants/keyboard';
import Utils from '../../../../core/scripts/lib/wirey/Utils';
import ensightenTracking from '../../../../core/scripts/elements/pf-app/ensighten/ensighten';
import { Config } from '../../../../core/scripts/lib/Config';
import { COUNTRY_NOT_LISTED_VALUE } from '../../../../core/scripts/constants/geography';
import savedSearchService from '../../../../core/scripts/services/savedSearchService';
import animalSearchAPIService from '../../api-services/animal-search/animalSearchAPIService';

/**
 * Duration the search set slideout stays open
 * @type {number}
 */
const SEARCH_SET_SLIDEOUT_DURATION = 5000;

/**
 * This component controls the state and functionality of the create saved search
 * slideout on pet search
 *
 * @extends PFDCElementElement
 */
export class PFDCCreateSavedSearchElement extends PFDCElementElement {
    static _elementTag = 'pfdc-create-saved-search';
    static template = template;

    get viewModel() {
        return {
            ...super.viewModel,
            contextElement: this,
            searchName: this.searchName,
        };
    }

    get searchName() {
        let {
            animalType,
            near,
            searchLocationName,
        } = this.stateController.animalSearch.results.state.searchSummary;
        if (!animalType) {
            animalType = 'Pets';
        }
        if (!near) {
            near = 'near';
        }
        if (!searchLocationName) {
            searchLocationName = 'everywhere';
            return `${animalType} ${searchLocationName}`;
        }
        return `${animalType} ${near} ${searchLocationName}`;
    }

    constructor() {
        super();
        this.subscribe(() => this.render(), 'animalSearch.results');

        // Listen for changes to certain filters below
        this.subscribe(() => this.resetToDefault(), [
            'animalSearch.filters.daysOnPetfinder',
            'animalSearch.filters.distance',
            'animalSearch.filters.animalType',
            'animalSearch.filters.breed',
            'animalSearch.filters.age',
            'animalSearch.filters.gender',
            'animalSearch.filters.attribute',
            'animalSearch.filters.coatLength',
            'animalSearch.filters.color',
            'animalSearch.filters.size',
            'animalSearch.filters.species',
            'animalSearch.filters.shelter',
        ]);
    }

    /**
     * Handler for toggling the slideout
     * @param {Object} ev
     */
    onToggleSavedSearchFormClicked = ev => {
        if (ev.type.includes('key')) {
            const keys = [KEY_ENTER, KEY_SPACE, KEY_SPACE_BAR];
            if (keys.includes(ev.key)) {
                ev.preventDefault();
                this._initiateSaveSearch(ev.currentTarget);
            }
            return;
        } else {
            this._initiateSaveSearch(ev.currentTarget);
        }
    };

    async _initiateSaveSearch(elementForReturningFocus) {
        // we need to ensure a search has been completed before proceeding
        await animalSearchAPIService.lastInitiatedSearch;

        savedSearchService.launchSaveSearchModal({
            focusTarget: elementForReturningFocus,
            analyticTriggerLabel: 'saveSearchCard',
            searchUrl: this.stateController.animalSearch.results.state.url,
        });
    }

    /**
     * Trigger the success modal
     */
    triggerSuccessModal() {
        const detail = Object.assign(
            {},
            {
                type: 'ui.activate',
                trigger: this,
                target: '#Save_Search_Modal',
            }
        );
        this.dispatchEvent(buildCustomEvent('action', detail));
    }

    /**
     * Helper to determine if user is auther and break if there is a search set
     * @return {boolean}
     */
    userAuthed() {
        // Is the user already authed?
        if (this.state.userAuth) {
            return true;
        }
        return false;
    }

    /**
     * Handler for slideout create saved search form
     * @param {Object} ev
     * @param {element} element
     */
    onCreateFormSubmit(ev) {
        ev.preventDefault();

        this.createSavedSearch({
            email: this.stateController.animalSearch.savedSearch.state
                .emailInputValue,
        });
    }

    /**
     * Attempts the creation of a saved search
     *
     * @param {{email: string}} additionalParams An object to allow passing of additional data
     * @param {bool} [allowTokenRefreshRetry] A flag to control whether or not to allow a retry
     */
    async createSavedSearch(additionalParams, allowTokenRefreshRetry = true) {
        ensightenTracking.eventConsumer085({
            searchName: this.viewModel.searchName,
        });

        this.stateController.animalSearch.savedSearch.patchState({
            formPosting: true,
        });

        // create base params obj
        const params = {
            token: await Config.userSavedSearchToken,
            searchUrl: this.stateController.animalSearch.results.state.url,
            country: this.stateController.animalSearch.savedSearch.country,
            optins: this.stateController.animalSearch.savedSearch.state
                .optinValues,
        };

        // copy additional params in
        Object.assign(params, additionalParams);

        try {
            const response = await contextContainer.userSavedSearchRepository.createSavedSearch(
                params
            );
            this.handleSuccess();
        } catch (error) {
            // if no fields are present in the errors list, the working assumption here
            // (best we have to go off of aside from comparing against the error message)
            // is that the token may have expired; attempt to get a new token and try again
            if (allowTokenRefreshRetry && !error.errors.fields) {
                Config.refreshUserMe();
                this.createSavedSearch(additionalParams, false);
                return;
            }

            this.handleError(error);
            throw true; // Throw an exception to stop the success modal.
        }
    }

    /**
     * Handler for email field input changes, sets state so we can persist email
     * @param {Object} ev
     * @param {element} element
     */
    onEmailInputChanged(ev, element) {
        this.stateController.animalSearch.savedSearch.patchState({
            emailInputValue: element.value,
        });
        contextContainer.focusManager.focusElement(element);
    }

    onOptInChanged(checkboxName, element) {
        this.stateController.animalSearch.savedSearch.patchState({
            optinValues: {
                [checkboxName]: element.checked,
            },
        });
    }

    /**
     * Handler for country field changes, sets state so we can show optins or clear errors
     * @param {Object} ev
     * @param {element} element
     */
    onCountryChanged(ev, element) {
        // Update country error...
        if (element.value.length > 0) {
            this.stateController.animalSearch.savedSearch.setStateAtAddress(
                [],
                'errors.fields.saved_search[country]'
            );
        }

        this.stateController.ui.patchState({
            countryNotListed: element.value[0] === COUNTRY_NOT_LISTED_VALUE,
        });

        this.toggleOptins(element.value);
    }

    /**
     * Handler for breakpoints
     * @param {string} currentBreakpoint
     */
    onBreakpointChanged(currentBreakpoint) {
        this.patchLocalState({
            currentBreakpoint,
        });
    }

    // Actions

    /**
     * Handles successfull form api submission
     */
    handleSuccess() {
        ensightenTracking.eventConsumer086({
            searchName: this.viewModel.searchName,
        });
        this.stateController.animalSearch.savedSearch.patchState({
            pristine: false,
            createSearchFormOpen: false,
            createSearchSlideoutOpen: false,
            formPosting: false,
            searchSet: true,
            searchSetSlideoutOpen: true,
            searchSetAlertText: 'Your new pet email alert has been set',
        });

        // Try to flash the saved search set state slideout
        try {
            setTimeout(() => {
                const slideout = this.querySelector(
                    '[pfdc-create-saved-search-set-slideout]'
                );
                slideout.classList.add('u-isActive');
            }, 100);

            setTimeout(() => {
                const slideout = this.querySelector(
                    '[pfdc-create-saved-search-set-slideout]'
                );
                slideout.classList.remove('u-isActive');
            }, SEARCH_SET_SLIDEOUT_DURATION - 500);

            setTimeout(() => {
                this.stateController.animalSearch.savedSearch.patchState({
                    searchSetSlideoutOpen: false,
                });
            }, SEARCH_SET_SLIDEOUT_DURATION);
        } catch (e) {
            return;
        }
    }

    /**
     * Handles error form api submission
     * @param {Object} response
     */
    handleError(response) {
        if (response.errors) {
            let globalError = '';
            let countryError = '';
            let emailError = '';
            try {
                if (Boolean(response.errors.global)) {
                    globalError = response.errors.global.join(' ');
                    ensightenTracking.eventConsumer087({ error: globalError });
                }
                if (Boolean(response.errors.fields['saved_search[country]'])) {
                    countryError = response.errors.fields[
                        'saved_search[country]'
                    ].join(' ');
                    ensightenTracking.eventConsumer087({ error: countryError });
                }
                if (Boolean(response.errors.fields['saved_search[email]'])) {
                    emailError = response.errors.fields[
                        'saved_search[email]'
                    ].join(' ');
                    ensightenTracking.eventConsumer087({ error: emailError });
                }
            } catch (e) {
                // noop
            }
            // const globalError = response.errors.global.join(' ');
            // const countryError = response.errors.fields['saved_search[country]'].join(' ');
            // const emailError = response.errors.fields['saved_search[email]'].join(' ');

            this.stateController.animalSearch.savedSearch.patchState({
                errors: {
                    global: globalError,
                    country: countryError,
                    email: emailError,
                },
                formPosting: false,
            });

            if (this.state.userAuth) {
                alert(`${globalError}`);
                return;
            }
        }

        // TODO Can we refactor this?
        // Set focus
        setTimeout(() => {
            contextContainer.focusManager.focusFirstFocusable(this, false);
        }, 500);
    }

    /**
     * Handles form toggle events by updating the state
     */
    toggleForm() {
        const state = this.stateController.animalSearch.state;

        this.stateController.animalSearch.savedSearch.patchState({
            pristine: !state.savedSearch.pristine,
            createSearchFormOpen: !state.savedSearch.createSearchFormOpen,
            createSearchSlideoutOpen: !state.savedSearch
                .createSearchSlideoutOpen,
        });

        if (state.savedSearch.createSearchFormOpen) {
            ensightenTracking.eventConsumer083();
        } else {
            ensightenTracking.eventConsumer084();
        }

        // TODO Can we refactor this?
        // Set focus
        setTimeout(() => {
            contextContainer.focusManager.focusFirstFocusable(this, false);
        }, 500);
    }

    /**
     * Set state of optin checkboes based on passed country value
     * @param {string} countryValues
     */
    toggleOptins(countryValues) {
        this.stateController.animalSearch.savedSearch.country = countryValues;
    }

    /**
     * Briefly tease the create search slideout.
     */
    teaseSlideout() {
        this.stateController.animalSearch.savedSearch.patchState({
            teaseSlideout: true,
        });
    }

    /**
     * Stop teasing
     */
    stopTeasingSlideout() {
        this.stateController.animalSearch.savedSearch.patchState({
            teaseSlideout: false,
        });
    }

    /**
     * Reset to the default state
     */
    resetToDefault() {
        this.stateController.animalSearch.savedSearch.setDefaultState();
    }
}

export default PFDCCreateSavedSearchElement;
