import PFDCElement from '../pfdc-element/element';
import siteStateController from '../../state-controllers/siteStateController';
import { html } from '../../../../core/scripts/util/util.js';
import { Config } from '../../../../core/scripts/lib/Config';

const keys = [
    'daysOnPetfinder',
    'distance',
    'animalType',
    'breed',
    'age',
    'gender',
    'attribute',
    'coatLength',
    'color',
    'location',
    'size',
    'species',
    'shelter',
];

/**
 * # `pf-ad` Element
 *
 * @class PFDCAdElement
 * @extends PFElement
 */
export class PFDCAdElement extends PFDCElement {
    get slotId() {
        return this.getAttribute('slot-id');
    }

    get lazyload() {
        return this.hasAttribute('lazy-load') || false;
    }

    onConnected() {
        this.render();
    }

    async render() {
        this.innerHTML = '';

        const filters = siteStateController.state.animalSearch.filters;
        const vm = {
            targeting: {},
        };

        keys.forEach(key => {
            if (filters[key].value.length) {
                const targetingValue = Array.isArray(filters[key].value)
                    ? filters[key].value.map(x => encodeURIComponent(x))
                    : encodeURIComponent(filters[key].value);
                vm.targeting[key] = targetingValue;
            }
        });

        if (Config.userAuthed) {
            vm.targeting['userIsLoggedIn'] = true; // eslint-disable-line
        }

        try {
            const savedSearches = await Config.savedSearches;
            if (savedSearches.length) {
                vm.targeting['savedSearches'] = true; // eslint-disable-line
            }
        } catch (e) { }

        try {
            const countryCode = await Config.userCountryCode;
            const stateCode = await Config.userStateCode;
            const locationName = await Config.userLocationName;
            if (countryCode) {
                vm.targeting.usersCountryCode = countryCode;
            }
            if (stateCode) {
                vm.targeting.usersStateCode = stateCode;
            }
            if (locationName) {
                vm.targeting.usersLocationName = locationName;
            }
        } catch (e) { }

        vm.elementContext = this;
        this.innerHTML = template(vm);
    }
}

const template = vm => {
    const targeting = Object.keys(vm.targeting).length
        ? `targeting='${JSON.stringify(vm.targeting)}'`
        : '';

    const lazyload = vm.elementContext.lazyload ? 'lazy-load' : '';
    const overrideAdIdAttribute = vm.elementContext.hasAttribute('override-ad-id')
        ? `override-ad-id=${vm.elementContext.getAttribute('override-ad-id')}`
        : '';

    const positionTargetIdAttribute = vm.elementContext.hasAttribute('position-target-id')
        ? `position-target-id=${vm.elementContext.getAttribute('position-target-id')}`
        : '';

    return html`
        <pf-ad
            id="${vm.elementContext.slotId}"
            ${targeting}
            ${lazyload}
            ${overrideAdIdAttribute}
            ${positionTargetIdAttribute}
        ></pf-ad>
    `;
};

export default PFDCAdElement;
