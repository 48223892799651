import { html } from '../../../core/scripts/util/util';

export default vm => {
    return html`
        <div class="animalSearchFooter" pf-mix-class="u-isLoaded: viewModel.hasSearched, u-noResults: !viewModel.hasResults">
            <div class="grid grid_gutterLg">
                <div class="grid-col grid-col_1/4@minMd grid-col_2/5@minXl u-isHidden@maxMd">

                    <pfdc-social-slide
                        facebook-sharing-url="http://www.facebook.com/sharer/sharer.php?u=$${vm.state.animalSearch.results.shareableUrl}"
                        twitter-sharing-url="http://www.twitter.com/intent/tweet?text=&url=$${encodeURIComponent(vm.state.animalSearch.results.shareableUrl)}"
                        pinterest-sharing-url="http://www.pinterest.com/pin/create/button/?url=$${vm.state.animalSearch.results.shareableUrl}&description=$${encodeURIComponent(vm.state.animalSearch.results.shareableUrl)}"
                        email-title="${encodeURIComponent(vm.state.animalSearch.results.name)}"
                        email-body="${encodeURIComponent(vm.state.animalSearch.results.shareableUrl)}"
                        clipboard-url="${vm.state.animalSearch.results.shareableUrl}"
                    ></pfdc-social-slide>

                </div>
                <div class="grid-col grid-col_3/4@minMd grid-col_3/5@minXl">
                    <pfdc-page-controls></pfdc-page-controls>
                </div>
            </div>
        </div>
    `;
};
