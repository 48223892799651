import { html } from '../../../../../core/scripts/util/util';

// Filters Header (Sm only)
export default vm => html`
    <div class="grid grid_gutter">
        <div class="grid-col grid-col_1/2">
            <button
                class="fieldBtn m-fieldBtn_full m-fieldBtn_tight u-vr6x"
                type="button"
                pf-mix-click="$closest.onToggleMoreFiltersBtnClicked($event, $element)"
                pfdc-mobile-filter-search-btn
                data-test="Mobile_Filter_Search_Button"
            >
                <span class="fieldBtn-icon">
                    <span class="icon m-icon_colorPrimary">
                        <svg role="img" focusable="false">
                            <use xlink:href="#icon-filter"/>
                        </svg>
                    </span>
                </span>
                 <pfdc-element class="fieldBtn-label" render-on-state-change="animalSearch.filters">
                    Filter<span class="u-isHidden@maxMd"> Search</span>
                        <span pf-mix-show="stateController.animalSearch.filters.numFiltersApplied">
                            (<span pf-mix-content="stateController.animalSearch.filters.numFiltersApplied"></span>)
                        </span>
                </pfdc-element>
            </button>
        </div>
        <div class="grid-col grid-col_1/2">
            <pfdc-generic-select id="sort-select" pf-mix-change="$closest.onSortByChanged($element.value)" observe-state="animalSearch.filters.sortBy" input-aria-label="Select Sort By Field" sort-by-select-label="Sort By">
             </pfdc-generic-select>
        </div>
    </div>
`;
