import { html } from '../../../../core/scripts/util/util';

/**
 * Template for suggester leveraging template literals less, and mixins more
 *
 * @param {object} vm
 * @returns {string}
 */
export default function(vm) {
    const randomId = Math.ceil(Math.random() * Date.now());
    const listId = `${randomId}-instructions`;

    const menuClosedPartial = vm => {
        return html`
            <button
                class="suggester-open-button"
                pf-mix-click="$closest.onSuggesterClick()"
                pf-mix-focus="$closest.onSuggesterClick()"
                aria-label="${vm.inputAriaLabel}"
                data-test="Suggester_Cover_Button"
            >
                <span pf-mix-if="currentLabelIsNotPlaceholder"
                    >${vm.controller.labelPrefix}</span
                >
                <span pf-mix-content="currentLabel" data-test="Suggester_Current_Label"></span>
            </button>
        `;
    };

    const menuOpenPartial = vm => {
        return html`
            <pfdc-suggester-input
                value-address="state.${vm.controller.inputTextAddress}"
                random-id="${randomId}"
                list-id="${listId}"
                placeholder="${vm.controller.labelPlaceholder}"
                ${
                    '' /* NOTE: we stop prop on change here to prevent an incorrect change value from bubbling up,
                as the suggester is expected to emit a change when the actual selection has changed */
                }
                pf-mix-change="$event.stopPropagation()"
            ></pfdc-suggester-input>

            <div aria-live="assertive" class="suggester-aria-live">
                <pfdc-element
                    observe-state="
                    isLoading: ${vm.controller.optionsLoadingAddress},
                    activeItemIndex: ${vm.controller.activeItemIndexAddress},
                    inputText: ${vm.controller.inputTextAddress}
                "
                >
                    <div
                        pf-mix-if="hasEnoughInputForSearch && observedState.isLoading"
                    >
                        Retrieving new results
                    </div>
                    <div
                        pf-mix-if="hasEnoughInputForSearch && !observedState.isLoading && observedState.activeItemIndex === -1"
                    >
                        <span pf-mix-content="optionCount"></span>
                        <span pf-mix-if="optionCount == 1"
                            >${vm.controller.unitLabelSingular} found.</span
                        >
                        <span pf-mix-if="optionCount != 1"
                            >${vm.controller.unitLabelPlural} found.</span
                        >
                    </div>
                </pfdc-element>
            </div>
        `;
    };

    return html`
        <pfdc-element
            observe-state="optionsMenuIsOpen: ${
                vm.controller.optionsMenuOpenAddress
            }"
            class="suggester-container"
        >
            <div
                pf-mix-if="!observedState.optionsMenuIsOpen"
                class="suggester-upper"
            >
                ${menuClosedPartial(vm)}
            </div>
            <div
                pf-mix-if="observedState.optionsMenuIsOpen"
                class="suggester-upper"
            >
                ${menuOpenPartial(vm)}
            </div>
        </pfdc-element>

        <pfdc-element
            observe-state="
                optionsMenuIsOpen: ${vm.controller.optionsMenuOpenAddress},
                inputText: ${vm.controller.inputTextAddress},
                isLoading: ${vm.controller.optionsLoadingAddress}
            "
        >
            <div
                pf-mix-if="observedState.optionsMenuIsOpen && hasEnoughInputForSearch"
                class="suggester-dropdown"
            >
                <!-- Loading message -->
                <div pf-mix-if="observedState.isLoading">Loading...</div>

                <!-- No results message -->
                <div pf-mix-if="!observedState.isLoading && optionCount === 0">
                    No ${vm.controller.unitLabelPlural} found.
                </div>

                <!-- Options list -->
                <div pf-mix-if="!observedState.isLoading && optionCount > 0">
                    <pfdc-option-list
                        pf-mix-if="observedState"
                        option-list-id="${listId}"
                        observe-state="${vm.controller.baseStateAddress}"
                    ></pfdc-option-list>
                </div>
            </div>
        </pfdc-element>
    `;
}
