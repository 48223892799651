import PFDCElement from '../pfdc-element/element';

import template from './suggester-input-template.html';

export default class PFDCTextInputElement extends PFDCElement {
    static _elementTag = 'pfdc-suggester-input';

    static template = template;

    /**
     * Getters / Config
     */

    get valueAddress() {
        return this.getAttribute('value-address');
    }
    get randomId() {
        return this.getAttribute('random-id');
    }
    get listId() {
        return this.getAttribute('list-id');
    }
    get placeholder() {
        return this.getAttribute('placeholder');
    }
}
