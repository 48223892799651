import React, { Fragment } from 'react';
import StateController from '../../generics/StateController';
import Modal from '../../../../../core/scripts/react-components/specifics/Modal';
import uiStateController from '../../../state-controllers/uiStateController';
import InterceptorForm from './InterceptorForm';

export default function InterceptorModal() {
    function _onSubmit() {
        uiStateController.searchInitPaused = false;
    }

    /**
     * Gets the interceptor modal content and copy.
     *
     * @private
     * @returns {HTMLElement}
     */
    function _interceptorModalContent() {
        return (
            <Fragment>
                <h1 className="txt txt_h2 u-vr4x">Let's get this right...</h1>
                <InterceptorForm
                    onSubmit={() => {
                        _onSubmit();
                    }}
                />
            </Fragment>
        );
    }

    /**
     * Gets the interceptor modal.
     *
     * @private
     * @param {Object} props
     * @returns {HTMLElement}
     */
    function _interceptorModal() {
        return (
            <Modal
                extensionClassNames={{
                    ['modal_transparentBg']: true,
                    ['u-textCenter']: true,
                }}
                noClose={true}
            >
                {_interceptorModalContent()}
            </Modal>
        );
    }

    return (
        <StateController
            stateLocation="ui.searchInitPaused"
            onStateUpdated={searchInitPaused => {
                if (!searchInitPaused) {
                    return null;
                }

                return _interceptorModal();
            }}
        />
    );
}
