export default vm => {
    // sm-md
    return `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Color</div>
        <pfdc-generic-autosuggest
            id="color-autosuggest"
            pf-mix-id="color-autosuggest"
            pf-mix-change="$closest.onColorChanged($event.selected)"
            observe-state="animalSearch.filters.color"
            template="colorSelector"
            select-multiple
            input-aria-label="Pick a color"
            autosuggest-placeholder="Search By Color"
            autosuggest-unit-label-singular="Color"
            autosuggest-unit-label-plural="Colors"
        ></pfdc-generic-autosuggest>
    `;
};
