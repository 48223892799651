import { html, ifTruthy } from '../../../../../core/scripts/util/util';

import speciesFilter from './filters/speciesFilter';
import breedFilter from './filters/breedFilter';
import ageFilter from './filters/ageFilter';
import sizeFilter from './filters/sizeFilter';
import genderFilter from './filters/genderFilter';
import goodWithFilter from './filters/goodWithFilter';
import careBehaviorFilter from './filters/careBehaviorFilter';
import coatLengthFilter from './filters/coatLengthFilter';
import colorFilter from './filters/colorFilter';
import daysOnPetfinderFilter from './filters/daysOnPetfinderFilter';
import animalNameFilter from './filters/animalNameFilter';
import shelterFilter from './filters/shelterFilter';
import transportableFilter from './filters/transportableFilter';

// Filters Body (Sm and Md)
export default vm => {
    const state = vm.state;

    return html`
        <pf-focus-manager
            trap-focus
            lock-scroll
            focus-first-focusable
            class="modal"
        >
            <!-- BEGIN: Content -->
            <div class="filterList" role="main">
                <div class="filterList-hd">
                    <div class="filterList-hd-controls">
                        <div class="filterList-hd-controls-title" aria-live="polite">
                            <pfdc-element render-on-state-change="animalSearch.results.totalResults">
                                <span class="txt m-txt_bold m-txt_color m-txt_colorPrimary">
                                    <span pf-mix-content="state.animalSearch.results.totalResults">${vm.totalResults}</span>
                                    Available ${vm.selectedAnimalTypeLabel}
                                </span>
                            </pfd-element>
                        </div>
                        <div class="filterList-hd-controls-closeBtn">
                            <button
                                type="button"
                                pf-mix-focus-on-render="true"
                                aria-label="Close this dialog"
                                pf-mix-click="$closest.onToggleMoreFiltersBtnClicked($event, $element);"
                                data-test="Mobile_Filters_Close_Modal_Button"
                            >
                                <span class="icon icon_sm m-icon_colorPrimaryS2">
                                    <svg role="img">
                                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="filterList-bd">
                    <div class="tier m-tier_padTop u-vr6x">
                        <div class="vrArray vrArray_divided m-vrArray_6x">
                            ${false ? 'TODO: forced to not display; not yet removing, just disabling for now until weve determined this should be gone forever.' : ''}
                            ${true ? '' : `
                            <div>
                                <div class="grid grid_gutterLg">
                                    <!-- Sort By filter -->
                                    ${ifTruthy(false && state.ui.filtersDisplay.sortBy, html`
                                        <div class="grid-col grid-col_1/2">
                                            <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Sort By</div>
                                            <pfdc-generic-select
                                                id="sort-select"
                                                pf-mix-change="$closest.onSortByChanged($element.value)"
                                                observe-state="animalSearch.filters.sortBy"
                                                input-aria-label="Select Sort By"
                                            ></pfdc-generic-select>
                                        </div>
                                    `)}
                                </div>
                            </div>
                            `}

                            ${ifTruthy(state.ui.filtersDisplay.species || state.ui.filtersDisplay.breed || state.ui.filtersDisplay.age || state.ui.filtersDisplay.size || state.ui.filtersDisplay.gender || state.ui.filtersDisplay.household, html`
                                <div>
                                    <div class="grid grid_gutterLg">
                                        ${ifTruthy(state.ui.filtersDisplay.species, html`
                                            <!-- Species filter -->
                                            <div class="grid-col grid-col_1/2@minMd">
                                                ${speciesFilter(vm)}
                                            </div>
                                        `)}

                                        ${ifTruthy(state.ui.filtersDisplay.breed, html`
                                            <!-- Breed filter -->
                                            <div class="grid-col grid-col_1/2@minMd">
                                                ${breedFilter(vm)}
                                            </div>
                                        `)}

                                        ${ifTruthy(state.ui.filtersDisplay.age, html`
                                            <!-- Age filter -->
                                            <div class="grid-col grid-col_1/2@minMd">
                                                ${ageFilter(vm)}
                                            </div>
                                        `)}

                                        ${ifTruthy(state.ui.filtersDisplay.size, html`
                                            <!-- Size filter -->
                                            <div class="grid-col grid-col_1/2@minMd">
                                                ${sizeFilter(vm)}
                                            </div>
                                        `)}

                                        ${ifTruthy(state.ui.filtersDisplay.gender, html`
                                            <!-- Gender filter -->
                                            <div class="grid-col grid-col_1/2@minMd">
                                                ${genderFilter(vm)}
                                            </div>
                                        `)}

                                        ${ifTruthy(state.ui.filtersDisplay.household, html`
                                            <!-- Good With filter -->
                                            <div class="grid-col grid-col_1/2@minMd">
                                                ${goodWithFilter(vm)}
                                            </div>
                                        `)}
                                    </div>
                                </div>
                            `)}

                            <div>
                                <h2 class="u-isVisuallyHidden">Health and Behavior Pet Search Filters</h2>
                                <div class="grid grid_gutterLg">
                                    ${ifTruthy(state.ui.filtersDisplay.attribute, html`
                                        <!-- Care & Behavior filter -->
                                        <div class="grid-col">
                                            ${careBehaviorFilter(vm)}
                                        </div>
                                    `)}

                                    ${ifTruthy(state.ui.filtersDisplay.coatLength, html`
                                        <!-- Coat Length filter -->
                                        <div class="grid-col grid-col_1/2">
                                            ${coatLengthFilter(vm)}
                                        </div>
                                    `)}

                                    ${ifTruthy(state.ui.filtersDisplay.color, html`
                                        <!-- Color filter -->
                                        <div class="grid-col grid-col_1/2">
                                            ${colorFilter(vm)}
                                        </div>
                                    `)}

                                    ${ifTruthy(state.ui.filtersDisplay.daysOnPetfinder, html`
                                        <!-- Days on Petfinder filter -->
                                        <div class="grid-col grid-col_1/2@minMd">
                                            ${daysOnPetfinderFilter(vm)}
                                        </div>
                                    `)}

                                    <!-- Shelter Filter -->
                                    <div class="grid-col grid-col_1/2@minMd">
                                        ${shelterFilter(vm)}
                                    </div>

                                    <!-- Animal Name value -->
                                    <div class="grid-col grid-col_1/2@minMd">
                                        ${animalNameFilter(vm)}
                                    </div>

                                    <!-- Transportable Filter -->
                                    <div class="grid-col grid-col_1/2@minMd">
                                        ${transportableFilter(vm)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filterList-ft">
                    <button pf-mix-click="$closest.onToggleMoreFiltersBtnClicked($event, $element); $closest.onResetClicked()" class="filterList-ft-resetBtn m-txt_colorPrimary">Reset</button>
                    ${(state.animalSearch.results.totalResults === 0)
                        ? '<pf-element class="u-inherit u-widthFull" on-click="ui.activate" data-target="#Save_Search_Modal" allow-event-propagation>'
                        : ''}
                        <button
                            type="button"
                            class="filterList-ft-resultsBtn"
                            ${(state.animalSearch.results.totalResults === 0)
                                ? 'style="flex: 0 0 100%"'
                                : ''
                            }
                            pf-mix-click="$closest.onToggleMoreFiltersBtnClicked($event, $element);"
                        >
                            <pfdc-element observe-state="animalSearch.results"
                                ${(state.animalSearch.results.totalResults === 0)
                                    ? 'pf-mix-click="trackingBridge.ensighten(\'Consumer062\');"'
                                    : 'pf-mix-click="trackingBridge.ensighten(\'Consumer061\');"'
                                }
                            >
                                <span class="txt m-txt_heavy m-txt_uppercase m-txt_color m-txt_colorPrimary">
                                    ${(state.animalSearch.results.totalResults === 0)
                                        ? 'Zero Results, Set Alert'
                                        : html`Apply Filters`
                                    }
                                </span>
                            </pfdc-element>
                        </button>
                    ${(state.animalSearch.results.totalResults === 0) ? '</pf-element>' : ''}
                </div>
            </div>
            <!-- END: Content -->
        </pf-focus-manager>
    `;
};
