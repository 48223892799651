import { html } from '../../../../../core/scripts/util/util';
import { searchInput, optionsList } from './common.html';
import openStateDesktopControlBar from './openStateDesktopControlBar.html';
import { WIDTH_MODES } from '../genericTemplate.html';

export default (vm) => {
    let sizingClass = '';
    switch (vm.props.widthMode) {
        case WIDTH_MODES.FULL:
            sizingClass = 'filterList_desktop';
            break;
        case WIDTH_MODES.WIDE:
            sizingClass = 'filterList_desktopWide';
            break;
        case WIDTH_MODES.DOUBLE:
            sizingClass = 'filterList_desktopWide';
            break;
    }

    // if we're doing a size that results in absolute positioning of an element, we need to have
    // an something that will occupy the correct amount of size for things to display properly
    const needsHeightSettingClass = vm.props.widthMode === WIDTH_MODES.WIDE || vm.props.widthMode === WIDTH_MODES.DOUBLE;

    return html`
        <div class="${needsHeightSettingClass ? 'fixedHeight11x' : ''}">
            <div
                class="filterList ${sizingClass} ${(vm.displayedOptions.length === 0) ? '' : 'resultsShown'}"
            >
                <pf-focus-manager
                    trap-tab-focus
                    focus-element="[${vm.id}-search-input]"
                    ${vm.localState.focusIsAllowed ? `return-focus-to="[${vm.id}-open-btn]"` : ''}
                >
                    ${openStateDesktopControlBar(vm)}
                    ${searchInput(vm)}
                    ${optionsList(vm)}
                </pf-focus-manager>
            </div>
        </div>
    `;
}
