import { html } from '../../../../../../core/scripts/util/util';

const TRANSPORTABLE_LABEL = 'Include out-of-town pets that can be transported to your area';
const TRANSPORTABLE_LABEL_LINK_LABEL = 'Learn More';

export default vm => {
    return html`
        <pfdc-element observe-state="animalSearch.filters.transportable" class="field field_icon m-field_iconRight_sm m-field_noLabel">
            <label class="checkbox" for="Transportable">
                <input
                    class="checkbox-input"
                    type="checkbox"
                    name="search"
                    id="Transportable"
                    placeholder=""
                    pf-mix-id="transportableInput"
                    pf-mix-change="$closest.onTransportableChanged($element.checked)"
                    data-test="Filters_Transportable_Checkbox"
                    $${vm.state.animalSearch.filters.transportable.value ? 'checked' : ''}
                    pf-mix-checked="observedState.value"
                    aria-label="$${TRANSPORTABLE_LABEL}"
                >
                <span class="checkbox-btn"></span>
                <span class="checkbox-label">$${TRANSPORTABLE_LABEL}</span>
            </label>
            <div class="checkbox m-checkbox_hidden">
                <span class="checkbox-btn"></span>
                <span class="checkbox-label">
                    <a href="$${vm.transportHref}" class="txt txt_link" target="_blank">$${TRANSPORTABLE_LABEL_LINK_LABEL}</a>
                </span>
            </div>
        </pfdc-element>
    `;
};
