import { html, ifTruthy } from '../../../../../core/scripts/util/util';
import ageFilter from './filters/ageFilter';
import animalNameFilter from './filters/animalNameFilter';
import breedFilter from './filters/breedFilter';
import careBehaviorFilter from './filters/careBehaviorFilter';
import coatLengthFilter from './filters/coatLengthFilter';
import colorFilter from './filters/colorFilter';
import daysOnPetfinderFilter from './filters/daysOnPetfinderFilter';
import genderFilter from './filters/genderFilter';
import goodWithFilter from './filters/goodWithFilter';
import shelterFilter from './filters/shelterFilter';
import sizeFilter from './filters/sizeFilter';
import speciesFilter from './filters/speciesFilter';
import transportableFilter from './filters/transportableFilter';


export default vm => {
    const filters = [];

    (vm.state.ui.filtersDisplay.species) ? filters.push(speciesFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.breed) ? filters.push(breedFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.age) ? filters.push(ageFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.size) ? filters.push(sizeFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.gender) ? filters.push(genderFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.household) ? filters.push(goodWithFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.coatLength) ? filters.push(coatLengthFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.color) ? filters.push(colorFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.attribute) ? filters.push(careBehaviorFilter(vm)) : false;
    (vm.state.ui.filtersDisplay.daysOnPetfinder) ? filters.push(daysOnPetfinderFilter(vm)) : false;
    filters.push(shelterFilter(vm));
    filters.push(animalNameFilter(vm));
    filters.push(transportableFilter(vm));

    return html`
        <ul class="vrArray m-vrArray_4x">
            ${filters.map(filterHtml => html`
                <li>${filterHtml}</li>
            `)}
        </ul>
    `;
};
