import { html, ifTruthy } from '../../../../core/scripts/util/util';
import { helpers } from '../../../../core/scripts/util/helpers';

export default vm => {
    // TODO get translations
    const animalTypes = [
        {
            type: 'dogs',
            label: 'Find a Dog',
            iconId: '#icon-dog',
        },
        {
            type: 'cats',
            label: 'Find a Cat',
            iconId: '#icon-cat',
        },
        {
            type: 'rabbits',
            label: 'Find a Rabbit',
            iconId: '#icon-rabbit',
        },
        {
            type: 'small-furry',
            label: 'Find a Small & Furry',
            iconId: '#icon-smallFurry',
        },
        {
            type: 'scales-fins-others',
            label: 'Find Scales, Fins & Other',
            iconId: '#icon-fish',
        },
        {
            type: 'birds',
            label: 'Find a Bird',
            iconId: '#icon-bird',
        },
        {
            type: 'horses',
            label: 'Find a Horse',
            iconId: '#icon-horse',
        },
        {
            type: 'barnyard',
            label: 'Find Barnyard Animals',
            iconId: '#icon-barnyard',
        },
    ];
    const expandLabel = 'Other Pets';
    return html`
        <fieldset pf-mix-change="animalSearchElement.onMobileAnimalTypeChanged($event.target.value)" class="u-textCenter">
            <legend class="txt m-txt_heavy m-txt_uppercase m-txt_alignCenter m-txt_loose u-widthFull u-vr2x">Pet Type</legend>

            <div class="u-inlineBlock u-textLeft">
                ${animalTypes.map((animal, index) => petTypeRadioInput(vm, animal, index))}
            </div>

            ${ifTruthy(!vm.localState.showOtherPets, html`
                <div class="animalSearchSelect animalSearchSelect_centered m-animalSearchSelect_shadowed">
                    <div class="animalSearchSelect-customSelect">
                        <button pf-mix-click="$closest.onOtherPetsClick($event, $element)" class="animalSearchSelect-customSelect-btn" type="button">${expandLabel}</button>
                    </div>
                </div>`
            )}
        </fieldset>
    `;
};

const petTypeRadioInput = (vm, animal, index) => {
    const OTHER_PET_CUTOFF_INDEX = 2;

    const hideInput = (!vm.localState.showOtherPets && (index >= OTHER_PET_CUTOFF_INDEX));
    const animalTypeValue = vm.contextElement.stateController.animalSearch.filters.state.animalType.value[0];

    // TODO:
    const shouldFocusItem = vm.localState.showOtherPets && index === OTHER_PET_CUTOFF_INDEX;

    return html`
        <div>
            <label class="radio u-vr2x ${hideInput ? 'u-isHidden' : ''}">
                <input
                    class="radio-input"
                    type="radio"
                    name="animalType"
                    value="${animal.type}"
                    aria-label="${animal.label}"
                    ${animalTypeValue === animal.type ? 'checked' : ''}
                    ${shouldFocusItem ? 'pf-mix-focus-on-render' : ''}
                >
                <span class="radio-btn"></span>
                <span class="radio-label">
                    <span class="icon">
                        <svg role="img">
                            <use xlink:href="${animal.iconId}"/>
                        </svg>
                    </span>
                    <span>${animal.label}</span>
                </span>
            </label>
        </div>
    `;
};
