/**
 * This file provides the following various consumers:
 *
 *  - a map of how the JS and server codebases refer to items (FE -> BE)
 *  - dictates which items are to be included in actions like searching
 *      - An item must be present for it to be searched, even if the JS
 *        and server refer to items by the same name
 */

export default {
    // pagination
    page: 'page',
    resultsPerPage: 'limit',

    // static filters
    adoptionStatus: 'status',
    token: 'token',
    savedSearch: 'saved_search',
    daysOnPetfinder: 'days_on_petfinder',
    distance: 'distance',
    status: 'status',
    animalType: 'type',
    sortBy: 'sort',
    animalName: 'name',

    // dynamic filters
    age: 'age',
    attribute: 'attribute',
    breed: 'breed',
    coatLength: 'coat_length',
    color: 'color',
    gender: 'gender',
    household: 'household',
    size: 'size',
    species: 'species',

    // remote lookup filters
    shelter: 'shelter_id',
    location: 'location_slug',

    // recently viewed pet ids
    recentlyViewedPets: 'pet_id',

    // transportable
    transportable: 'include_transportable',
};
