import { PFDCElementElement } from '../pfdc-element/element';
import template from './template.html';
import Utils from '../../../../core/scripts/lib/wirey/Utils';

export class PFDCAnimalIconElement extends PFDCElementElement {
    static _elementTag = 'pfdc-animal-icon';

    static template = template;

    get viewModel() {
        return {
            ...super.viewModel,
            animalType: this._animalTypeOverload,
        };
    }

    get _animalTypeOverload() {
        if (this._animalType) {
            return this._animalType;
        }

        if (this._resolveAnimalType) {
            const result = Utils.HTMLElementUtils.findElementContainingAddress(
                this._resolveAnimalType,
                this,
                true
            );

            if (result.element) {
                return result.value;
            }
        }

        return '';
    }

    get _resolveAnimalType() {
        return this.getAttribute('resolve-animal-type');
    }

    get _animalType() {
        return this.getAttribute('animal-type');
    }
}

export default PFDCAnimalIconElement;
