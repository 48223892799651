import AnimalSearchElement from '../base-elements/AnimalSearchElement';
import template from './template.html';

export class PFDCPageControlsElement extends AnimalSearchElement {
    static _elementTag = 'pfdc-page-controls';

    static template = template;

    get pagesOnEachSide() {
        return parseInt(this.getAttribute('page-range'), 10) || 4;
    }

    get viewModel() {
        const lastPage =
            this.animalSearchStateController.pagination.totalPages - 1;

        return {
            ...super.viewModel,
            lastPage,
            isFirstPage:
                this.animalSearchStateController.pagination.currentPage === 0,
            isLastPage:
                this.animalSearchStateController.pagination.currentPage ===
                lastPage,
            isOnlyPage:
                this.animalSearchStateController.pagination.totalPages === 1,
        };
    }

    get firstPaginationPage() {
        return Math.max(
            0,
            this.animalSearchStateController.pagination.currentPage -
                this.pagesOnEachSide
        );
    }

    get lastPaginationPage() {
        return Math.min(
            this.animalSearchStateController.pagination.currentPage +
                this.pagesOnEachSide,
            this.maxLastPage
        );
    }

    get maxLastPage() {
        return this.stateController.animalSearch.pagination.totalPages - 1;
    }

    get pagesArr() {
        const arr = [];

        const firstPaginationPage = this.firstPaginationPage;
        const lastPaginationPage = this.lastPaginationPage;

        // create initial range
        for (let i = firstPaginationPage; i <= lastPaginationPage; i++) {
            arr.push({
                label: i + 1,
                value: i,
            });
        }

        // should we display '...' after first number
        if (firstPaginationPage > 1) {
            arr.unshift({
                label: '...',
                value: null,
                disabled: true,
            });
        }

        // should we display additional "1"
        if (firstPaginationPage > 0) {
            arr.unshift({
                label: 1,
                value: 0,
            });
        }

        // should we display '...' before last number
        if (lastPaginationPage < this.maxLastPage - 1) {
            arr.push({
                label: '...',
                value: null,
                disbled: true,
            });
        }

        // should we display last number
        if (lastPaginationPage < this.maxLastPage) {
            arr.push({
                label: this.maxLastPage + 1,
                value: this.maxLastPage,
            });
        }

        return arr;
    }

    get currentPage() {
        const offset = this.animalSearchStateController.pagination.state.offset;
        const count = this.animalSearchStateController.pagination.state
            .countPerPage;
        return Math.floor(offset / count);
    }

    onPaginationChanged(payload) {
        if ('pagination.totalPages' in payload.flattenedModifications) {
            const pageOptions = this.pagesArr;
            this.animalSearchStateController.pagination.setStateAtAddress(
                pageOptions,
                'options'
            );
        }

        this.render();
    }

    /**
     * Public API
     */

    goToPreviousPage() {
        this.animalSearchStateController.pagination.goToPreviousPage();
    }

    goToNextPage() {
        this.animalSearchStateController.pagination.goToNextPage();
    }

    goToPage(number) {
        this.animalSearchStateController.pagination.goToPage(number);
    }

    // TODO: unused? marked for removal
    // setResultsPerPage(newValue) {
    //     const newCountPerPage = parseInt(newValue, 10);

    //     this.animalSearchStateController.pagination.countPerPage = parseInt(newCountPerPage, 10);

    //     // update page number if necessary due to results per page change
    //     const currentOffet = this.animalSearchStateController.pagination.offset;
    //     const newOffset = Math.floor(currentOffet / newCountPerPage) * newCountPerPage;

    //     console.log('newCountPerPage: ', newCountPerPage);
    //     console.log('currentOffet: ', currentOffet);
    //     console.log('newOffset: ', newOffset);

    //     if (newOffset !== currentOffet) {
    //         // TODO: genericize this by utilizing observed state and triggering a change event?
    //         // TODO: currently this is hard tied to animal search
    //         this.animalSearchStateController.pagination.offset = newOffset;
    //     }
    // }
}

export default PFDCPageControlsElement;
