import { html } from '../../../../../core/scripts/util/util';

export default (vm) => {
    const unitLabel = vm.selectedLabels.length === 1
        ? vm.unitLabelSingular
        : vm.unitLabelPlural;

    const selectionPlaceholder = vm.selectedLabels.length === 0
        ? 'Any'
        : `${vm.selectedLabels.length} ${unitLabel}`;

    return html`
        <div
            class="animalSearchSelect animalSearchSelect_shadowed ${vm.themeClass}"
        >
            <div class="animalSearchSelect-customSelect">
                <button
                    ${vm.id}-open-btn
                    class="animalSearchSelect-customSelect-btn"
                    type="button"
                    aria-label="${vm.inputAriaLabel}, ${selectionPlaceholder}"
                    aria-haspopup="true"
                    id="${vm.id}_List_Box_Btn"
                    data-test="Filters_Open_Dropdown_Button_List"
                >
                    <div class="txt m-txt_bold m-txt_ellipsisOverflow">
                        ${selectionPlaceholder}
                    </div>
                </button>
            </div>
        </div>
    `;
}
