export default vm => {
    // sm-md
    return `
        <div class="txt txt_label m-txt_heavy m-txt_uppercase m-txt_alignCenter u-vr1x">Shelter or Rescue</div>
        <pfdc-generic-autosuggest
            id="shelter-autosuggest"
            pf-mix-change="$closest.onShelterChanged($event.selected)"
            observe-state="animalSearch.filters.shelter"
            template="shelterSelector"
            select-multiple
            input-aria-label="Search Organization Names"
            autosuggest-placeholder="Search Organization Names"
            autosuggest-unit-label-singular="Shelter"
            autosuggest-unit-label-plural="Shelters"
            autosuggest-api-service="searchOrganizations"
            autosuggest-api-method="fetchAsOptions"
            autosuggest-min-search-text-length="3"
        ></pfdc-generic-autosuggest>
    `;
};
