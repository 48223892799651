import { html, ifTruthy } from '../../../core/scripts/util/util';
import { helpers } from '../../../core/scripts/util/helpers';

const translations = require('../../../core/scripts/data/i18nJSON.json');

const countryNotListed = `
    <div id="CountryNotListedMessage" class="alert alert_info m-alert_padded" tabindex="0">
        <h5 class="txt m-txt_heavy m-txt_colorPrimaryS1">${translations['user.form.country.not_listed.alert.header']}</h5>
        <p class="txt m-txt_colorPrimaryS1">${translations['user.form.country.not_listed.alert.text']}</p>
    </div>
`;

const countryNotListedAria = `${translations['user.form.country.not_listed.alert.header']} ${translations['user.form.country.not_listed.alert.text']}`;

export default vm => {
    return html`
        <div class="u-isVisuallyHidden" role="alert" aria-live="assertive">
            ${ifTruthy(vm.state.ui.countryNotListed, countryNotListedAria, '')}
        </div>
        ${ifTruthy(vm.state.ui.countryNotListed, countryNotListed, '')}
    `;
};
