import { html } from '../../../../core/scripts/util/util.js';

export default vm => html`
    <pf-ensighten on-click="analyticsSearchAndMatch348" data-analytics-key="search-and-match">
        <div class="actionCard m-actionCard_bgWhite m-actionCard_padSm u-vr6x">
            <div class="actionCard-close">
                <button pfdc-search-match-cta-close aria-label="Dismiss this message" type="button">
                    <span class="icon icon_sm m-icon_block m-icon_colorPrimaryS1">
                        <svg role="img">
                            <use xlink:href="#icon-close" />
                        </svg>
                    </span>
                </button>
            </div>
            <div class="actionCard-media m-actionCard-media_constrain">
                <picture>
                    <source
                        srcset="/assets/www/media/images/content/onboarding-quiz-dog_mobile.jpg"
                        media="(max-width: 1023px)"
                    />
                    <source srcset="/assets/www/media/images/content/onboarding-quiz-dog_desktop.jpg" />
                    <img
                        src="/assets/www/media/images/content/onboarding-quiz-dog_desktop.jpg"
                        alt="potential dog matches"
                    />
                </picture>
            </div>
            <div class="actionCard-body">
                <h3 class="txt txt_h2 m-txt_colorPrimary u-vr3x">
                    Find Your Best Match
                </h3>
                <p class="txt m-txt_tight">
                    It only takes 60 seconds!
                </p>
            </div>
            <div class="actionCard-footer">
                <a href="/user/profile/create/?experience=loginAtEnd" class="actionCard-footer-cta">
                    <span>Get Started</span>
                </a>
            </div>
        </div>
    </pf-ensighten>
`;
