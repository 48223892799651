import { html } from '../../../../core/scripts/util/util';
import { animalTypeIconMap } from '../../constants/maps';

export default vm => {
    return html`
        <svg role="img" style="width: inherit; height: inherit;">
            <use xlink:href="#icon-${animalTypeIconMap[vm.animalType.toLowerCase()]}" />
        </svg>
    `;
};
