import { html } from '../../../../core/scripts/util/util';
import filtersSmHtml from './partials/filters-sm.html';
import filtersMdUpHtml from './partials/filters-md-up.html';

export default vm => {
    return html`
        <!-- Sneeze guard -->
        <pfdc-element observe-state="ui.fundamentalFiltersLoading" template="loadingResultsIndicator"></pfdc-element>
        ${
            (vm.state.currentBreakpoint === 'sm' || vm.state.currentBreakpoint === 'md')
                ? filtersSmHtml(vm)
                : filtersMdUpHtml(vm)
        }
    `;
};
