import { html, ifTruthy } from '../../../../core/scripts/util/util';

// prettier-ignore
export default vm => {
    return vm.sortBySelectLabel !== false ? searchSortBySelect(vm) : html`
        <div
            class="animalSearchSelect animalSearchSelect_shadowed ${vm.themeClass} ${vm
                .localState.menuIsOpen
                ? 'u-isOpen'
                : ''}"
        >
            ${vm.state.currentBreakpoint === 'sm'
                ? nativeSelect(vm)
                : customSelect(vm)}
        </div>
    `;
};

// prettier-ignore
const customSelect = vm => {
    const customSelectedValueText = vm.controller.customSelectedValueText;

    return html`
        <div class="animalSearchSelect-customSelect">
            <div
                ${vm.id}-open-btn
                class="animalSearchSelect-customSelect-btn"
                role="button"
                tabindex="0"
                ${vm.ariaDescribedById
                    ? `aria-describedby="${vm.ariaDescribedById}"`
                    : ''}
                aria-label="${vm.inputAriaLabel}, ${customSelectedValueText
                    ? customSelectedValueText
                    : ''}${!customSelectedValueText && vm.selectedLabels.length
                    ? vm.selectedLabels.join(', ')
                    : ''}${!customSelectedValueText && !vm.selectedLabels.length
                    ? vm.placeholder
                    : ''}"
                ${vm.observedState.options.length > 0
                    ? 'aria-haspopup="true"'
                    : ''}
                id="${vm.id}_List_Box_Btn"
                data-test="Filters_Open_Dropdown_Button_List"
            >
                <div class="split m-split_fullHeight">
                    <div class="txt m-txt_bold m-txt_ellipsisOverflow">
                        ${customSelectedValueText
                            ? customSelectedValueText
                            : ''}
                        ${!customSelectedValueText && vm.selectedLabels.length
                            ? vm.selectedLabels.join(', ')
                            : ''}
                        ${!customSelectedValueText && !vm.selectedLabels.length
                            ? vm.placeholder
                            : ''}
                    </div>
                    <div
                        role="button"
                        tabindex="0"
                        class="txt txt_link m-txt_heavy m-txt_colorPrimaryS1 ${vm
                            .selectedLabels.length > 1
                            ? ''
                            : 'u-isHidden'}"
                        pf-mix-click="$closest.onClearBtnClicked($event, $element)"
                        pf-mix-keydown="$closest.onClearBtnKeyDown($event, $element)"
                    >
                        Clear
                    </div>
                </div>
            </div>
            ${vm.menuIsOpenOrStillAnimating ? customSelectDropdown(vm) : ''}
        </div>
    `;
};

// prettier-ignore
const searchSortBySelect = vm => {
    if (!vm.observedState.options.length) {
        return html ``;
    }

    return html`
        <div class="${vm.state.currentBreakpoint === 'sm' ? 'field field_select s-field_hasInput u-vr4x' : 'field field_select m-field_minWidthSm s-field_hasInput u-vr4x'}">
            <div class="field-label">
                <label class="field-label-text" for="sort">Sort By:</label>
            </div>
            <select
                    id="${vm.id}_${vm.observedState.options.length}_nativeSelect"
                    class="field-input"
                    name="sort"
                    aria-label="${vm.inputAriaLabel}"
                    aria-invalid="false"
                    pf-mix-change="$closest.onNativeSelectChanged($event, $element)"
            >
                ${vm.observedState.options.map(
                    (option, index) => html`
                                <option
                                    value="$${option.value}"
                                    ${vm.selectedValues.includes(option.value)
                        ? 'selected'
                        : ''}
                                >
                                    ${option.label}
                                </option>
                            `
                )}
            </select>
        </div>
    `;
};

// prettier-ignore
const customSelectDropdown = vm => {
    const highlightedIndex = vm.localState.highlightedIndex;
    const focusIsAllowed = vm.localState.focusIsAllowed;

    return html`
        <pf-focus-manager
            trap-tab-focus
            ${focusIsAllowed ? `return-focus-to="[${vm.id}-open-btn]"` : ''}
        >
            <ul
                class="animalSearchSelect-customSelect-list"
                id="${vm.id}_List_Box"
                role="listbox"
                ${vm.canSelectMultiple ? 'aria-multiselectable="true"' : ''}
            >
                ${vm.observedState.options.map((option, index) => html`
                    <li
                        ${vm.id}-item-btn
                        class="filterList-list-item ${vm.selectedValues.includes(option.value) ? 'u-isSelected' : ''} ${vm.localState.highlightedIndex === index ? 'isHighlighted' : ''}"
                        pf-mix-focus="$closest.onItemHovered($event, $element, ${index})"
                        ${(focusIsAllowed && highlightedIndex === index) ? 'pf-mix-focus-on-render style="background-color: #efeef1;"' : ''}
                        role="option"
                        aria-selected="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
                        tabindex="0"
                        aria-label="${option.label} ${option.count != null ? `(${option.count})` : ''}, ${vm.selectedValues.includes(option.value) ? 'selected' : 'not selected'}"
                    >
                        <label class="filterList-list-chk-label" pf-mix-click="$event.stopPropagation();">
                            <div class="filterList-list-chk-inner" data-test="Filters_Open_Menu_Option_List">
                                <div class="filterList-list-title">
                                    ${option.label}
                                    ${ifTruthy(option.subLabel, html`<div>${option.subLabel}</div>`)}
                                </div>
                                <div class="split">
                                    <div class="filterList-list-qty u-hr7x">${option.count != null ? `(${option.count})` : ''}</div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            class="filterList-list-chk u-isVisuallyHidden" ${vm.selectedValues.includes(option.value) ? 'checked' : ''}
                                            role="checkbox"
                                            aria-checked="${vm.selectedValues.includes(option.value) ? 'true' : 'false'}"
                                            aria-label="${option.label}, ${vm.selectedValues.includes(option.value) ? 'selected' : 'not selected'}"
                                            pf-mix-change="$event.stopPropagation()"
                                            pf-mix-click="$closest.onItemClicked($event, $element, '${escape(option.value)}')"
                                        />
                                        <div class="filterList-list-chk-svg"></div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </li>
                `)}
            </ul>
        </pf-focus-manager>
    `;
};

// prettier-ignore
const nativeSelect = vm => {
    return html`
        <select
            id="${vm.id}_nativeSelect"
            pf-select-select
            class="animalSearchSelect-select"
            pf-mix-change="$closest.onNativeSelectChanged($event, $element)"
            ${vm.canSelectMultiple ? 'multiple' : ''}
            aria-label="${vm.inputAriaLabel}"
        >
            ${!vm.canSelectMultiple && vm.placeholder
                ? `<option value="" ${
                      !vm.selectedValues.length ? 'selected' : ''
                  }>
                       ${vm.placeholder}
                   </option>`
                : ''}
            ${vm.observedState.options.map(
                (option, index) => html`
                    <option
                        value="$${option.value}"
                        ${vm.selectedValues.includes(option.value)
                            ? 'selected'
                            : ''}
                    >
                        ${option.label}
                    </option>
                `
            )}
        </select>
    `;
};
