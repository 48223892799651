import AnimalSearchElement from '../base-elements/AnimalSearchElement';
import ensightenTracking from '../../../../core/scripts/elements/pf-app/ensighten/ensighten';

// NOTE: this service is not used in code, but used by an autosuggest via the API Service registry
import '../../../../core/scripts/services/searchOrganizationsAPIService';

import animalSearchAPIService from '../../api-services/animal-search/animalSearchAPIService';
import animalSearchFiltersAPIService from '../../api-services/animal-search/animalSearchFiltersAPIService';
import breedsAPIService from '../../api-services/animal-search/breedsAPIService';
import geographyAPIService from '../../api-services/geographyAPIService';

import ContentToutProvider from '../../providers/ContentToutProvider';
import SearchParamsModel from './SearchParamsModel';

import Utils from '../../../../core/scripts/lib/wirey/Utils';
import _get from 'lodash/get';

import { debounce } from '../../../../core/scripts/util/dom';

const databridge = window.PF.databridge;

// TODO: this mapping ideally shouldn't be happening here, and instead should be happening
// at some "provider" level (API service? an actual "Provider" level?)... or somewhere else.
// state -> api
import filterToServerMap from '../../constants/animalSearchFilterToServerMap';
import { Config } from '../../../../core/scripts/lib/Config';
import socialLoginStateController from '../../react-components/specifics/SocialLogin/controller/SocialLoginStateController';
import { CONTEXT } from '../../react-components/specifics/LoginLauncher/utilities/copyMap';

class ActionTest {
    _shouldTakeAction = true;

    get shouldTakeAction() {
        return this._shouldTakeAction;
    }

    preventAction() {
        this._shouldTakeAction = false;
    }
}

export class PFDCAnimalSearchElement extends AnimalSearchElement {
    static _elementTag = 'pfdc-animal-search';

    _initialStateLoaded = false;
    _initialSearchCompleted = false;

    /**
     * Pet Card Offset
     *
     * Stores the pet card offset value after a pet card has been clicked
     * so that we can refocus the pet card upon returning to the search results.
     *
     * Offset value is zero based. -1 indicates no offset available.
     */
    _defaultPetCardOffset = -1;

    /**
     * Placeholder class property. This value will be set in constructor.
     */
    _petCardOffset = null;

    /**
     * @type {SearchParamsModel}
     */
    _searchParamsModel = null;

    /**
     * Gets the offset query param from the url if it exists.
     */
    get URLAnimalOffset() {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.has('o') ? searchParams.get('o') : this._defaultPetCardOffset;
    }

    constructor() {
        super();

        this._searchParamsModel = new SearchParamsModel();

        // TODO: this code should really be at the level of something like an "app controller"; if something like this comes into existence in the future, move this code
        window.addEventListener('popstate', e => {
            if (!e.state) {
                return;
            }

            this.stateController.setState(e.state, {
                preventAllReactivity: true,
            });
        });

        this._petCardOffset = this.URLAnimalOffset;
    }

    /**
     * Focus Active Pet Card
     *
     * Method to bring the previously clicked on pet card into view and focus.
     */
    focusActivePetCard() {
        if (this._petCardOffset === this._defaultPetCardOffset) {
            return;
        }

        const petCardIndex = this._petCardOffset % this.viewModel.state.pagination.countPerPage;
        const animalsOnPageArray = this.viewModel.state.results.items;
        const petCardItem = animalsOnPageArray[petCardIndex];
        const selector = `#petId_${petCardItem.animal.id} > .petCard-link`;
        const petCardToFocus = document.querySelector(selector);
        petCardToFocus.scrollIntoView();
        petCardToFocus.focus();

        // Reset flag
        this._petCardOffset = this._defaultPetCardOffset;
    }

    get viewModel() {
        return {
            ...super.viewModel,
            hasResults: this.stateController.animalSearch.results.totalResults > 0,
            hasSearched: animalSearchAPIService.searchesExecuted > 0,
        };
    }

    /**
     * Lifecycle
     */

    async onConnected() {
        super.onConnected();

        const token = await Config.userToken;

        this._searchParamsModel.token = token;
        this._searchParamsModel.adoptionStatus = databridge.animalSearchParameters.status;
        this._searchParamsModel.savedSearch = databridge.animalSearchParameters.saved_search;

        this.debouncedPerformSearch = debounce(this._performSearch.bind(this), 300);

        // this updates filter state and thus triggers a search
        this._loadInitialState();
        this._loadUserInformation();
    }

    _getTransformedStaticFilters(staticFilters = databridge.animalSearchEnumerations.filters.static) {
        return Utils.ObjectUtils.mapTransform(staticFilters, {}, filterToServerMap, true);
    }

    _getTransformedDynamicFilters(dynamicFilters = databridge.animalSearchEnumerations.filters.dynamic) {
        return Utils.ObjectUtils.mapTransform(dynamicFilters, {}, filterToServerMap, true);
    }

    /**
     * Data loading/parsing
     */

    _loadInitialState() {
        // static filters
        const transformedStaticFilters = this._getTransformedStaticFilters();
        this._loadStaticFilterOptions(transformedStaticFilters);

        // process filter options
        const transformedDynamicFilters = this._getTransformedDynamicFilters();
        this._loadDynamicFilterOptions(transformedDynamicFilters);

        this._updateFiltersDisplay(transformedStaticFilters, transformedDynamicFilters);

        // load intial filter values from user's load
        this.loadInitialFilterValues();

        // on initial load, if we have a shelter and no distance, take action
        this._setDistanceAnywhereIfShelterSelected(true);

        // Get countries
        this._loadCountries();

        // Checks to see if an animal search should be paused
        if (this._shouldInitPause) {
            this.stateController.ui.searchInitPaused = true;

            const unsubscribe = this.stateController.ui.subscribe(payload => {
                if (!this.stateController.ui.searchInitPaused) {
                    this._fetchSearchFiltersFromServer();
                    this._resolveInitialState();
                    unsubscribe();
                }
            }, 'searchInitPaused');
        } else {
            this._resolveInitialState();
        }
    }

    /**
     * Returns true if there are NO missing parameters, AND is NOT a saved search, AND does NOT include an organization.
     *
     * @readonly
     * @private
     * @returns {Boolean}
     */
    get _shouldInitPause() {
        const isMissingParams = this.stateController.animalSearch.filters.missingRequiredParams.length;
        const isSavedSearch = databridge.animalSearchParameters.saved_search;
        const isOrgSearch = this.stateController.animalSearch.filters.shelter.length;
        const isRecentlyViewedPetSearch = this.stateController.animalSearch.filters.recentlyViewedPets.length;

        return isMissingParams && !(isSavedSearch || isOrgSearch || isRecentlyViewedPetSearch);
    }

    /**
     * Resolves searching flags and kicks off animal search
     *
     * @private
     */
    _resolveInitialState() {
        this.stateController.ui.fundamentalFiltersLoading = false;
        this._initialStateLoaded = true;

        // kick off initial search
        this._performSearch();
    }

    _loadStaticFilterOptions(staticFilters) {
        // static filter options come through with the default option having no value.  This causes
        // issues such as the query string having an undefined value for the null value.
        const filterOptionsWithSetDefaultValue = staticFilters.daysOnPetfinder.options.map(option => {
            if (option.default) {
                option.value = '';
            }
            return option;
        });
        this.stateController.animalSearch.filters.daysOnPetfinderOptions = filterOptionsWithSetDefaultValue;
        this._setAnimalTypeState(staticFilters.animalType.options);

        // TODO: server is returning options for resultsPerPage as string values; fixing here for now... remove once resolved on server
        this.stateController.animalSearch.filters.resultsPerPageOptions = staticFilters.resultsPerPage.options.map(
            item => ({
                ...item,
                value: parseInt(item.value, 10),
            })
        );
    }

    _setAnimalTypeState(staticOpts) {
        const defaultLabel = staticOpts.find(opt => opt.default === true).label;
        const animalTypeOpts = staticOpts.filter(opt => opt.default === false);

        this.stateController.animalSearch.filters.patchState({
            animalType: {
                defaultLabel,
                options: animalTypeOpts,
            },
        });
    }

    _loadDynamicFilterOptions(dynamicFilters) {
        this.stateController.animalSearch.filters.ageOptions = dynamicFilters.age.options;
        this.stateController.animalSearch.filters.attributeOptions = dynamicFilters.attribute.options;

        this.stateController.animalSearch.filters.breedOptions = dynamicFilters.breed.options;

        this.stateController.animalSearch.filters.coatLengthOptions = dynamicFilters.coatLength.options;
        this.stateController.animalSearch.filters.colorOptions = dynamicFilters.color.options;
        this.stateController.animalSearch.filters.genderOptions = dynamicFilters.gender.options;
        this.stateController.animalSearch.filters.sizeOptions = dynamicFilters.size.options;
        this.stateController.animalSearch.filters.speciesOptions = dynamicFilters.species.options;
        this.stateController.animalSearch.filters.householdOptions = dynamicFilters.household.options;
        this.stateController.animalSearch.filters.sortByOptions = dynamicFilters.sortBy.options;

        this.stateController.animalSearch.filters.distanceOptions = dynamicFilters.distance.options;
    }

    _updateFiltersDisplay(staticFilters = null, dynamicFilters = null) {
        // gather information about filter display
        const filtersDisplay = {};

        if (staticFilters) {
            for (const id in staticFilters) {
                filtersDisplay[id] = staticFilters[id].display;
            }
        }

        if (dynamicFilters) {
            for (const id in dynamicFilters) {
                filtersDisplay[id] = dynamicFilters[id].display;
            }
        }

        this.stateController.ui.patchState({
            filtersDisplay,
        });
    }

    _getDefaultFilterOptionsFromEnumerations() {
        const allFilters = Object.assign(
            databridge.animalSearchEnumerations.filters.static,
            databridge.animalSearchEnumerations.filters.dynamic
        );

        return Object.keys(allFilters).reduce((combined, filterId, index) => {
            const filterEntry = allFilters[filterId];
            if ('options' in filterEntry) {
                const optionWithDefault = filterEntry.options.find(option => option.default === true);
                if (optionWithDefault) {
                    combined[filterId] = optionWithDefault.value;
                }
            }

            return combined;
        }, {});
    }

    loadInitialFilterValues() {
        const defaultEnumerationValues = this._getDefaultFilterOptionsFromEnumerations();
        const searchParameters = Object.assign(defaultEnumerationValues, databridge.animalSearchParameters);

        // temporarily set shelter options so display labels can be visible for those that are present on page load;
        // NOTE: this must happen before the values get copied into our machinery to avoid issues with looking up shelter options by value
        this.stateController.animalSearch.filters.shelterOptionsCache = searchParameters.shelter_id.map(item => ({
            label: item.name,
            long_label: item.name,
            value: item.display_id,
        }));

        // TODO: look into this as an area to improve performance; (is there a reason the data "fixing" that happens after mapTransform can't be done before mapTransform, thus reducing the amount of state updates?)
        Utils.ObjectUtils.mapTransform(
            searchParameters,
            this.stateController.animalSearch.filters,
            filterToServerMap,
            true,
            ['shelter_id']
        );

        // manually set location as an array due to it being a plain string on load
        this.stateController.animalSearch.filters.location = searchParameters.location_slug
            ? [searchParameters.location_slug]
            : [];

        this.stateController.animalSearch.filters.shelter = searchParameters.shelter_id.map(item => item.display_id);

        // we must set the count per page first as a calculation of the offset will occur upon page setting
        this.stateController.animalSearch.pagination.countPerPage = searchParameters.limit;
        this.stateController.animalSearch.pagination.currentPage = searchParameters.page - 1;
    }

    /**
     * Load user object from user me and patch it to the site state
     */
    async _loadUserInformation() {
        try {
            const user = await Config.user;
            this.stateController.patchState({
                user: {
                    country: user.country,
                    firstName: user.first_name,
                    lastName: user.last_name,
                },
            });
        } catch (e) {
            return;
        }
    }

    /**
     * This is where the country field on create saved search gets the countries from the
     * geographyAPIService and sets the fields state to the users' set country.
     */
    async _loadCountries() {
        try {
            // TODO should this default to the US?
            const initCountry = this.stateController.state.user.country || 'US';
            const countries = await geographyAPIService.getCountriesForGenericSelect();
            this.stateController.animalSearch.savedSearch.patchState({
                country: {
                    options: countries.data,
                    value: [initCountry],
                },
            });
        } catch (e) {
            throw new Error(e);
        }
    }

    /**
     * Custom lifecycle hooks (called from parent)
     *
     * Note: onFiltersChanged and onSearchTermsChanged are added by AnimalSearchElement,
     * and therefore their payload modifications objects are relative to
     * siteStateController.animalSearch
     */

    onFiltersChanged(payload) {
        this._checkFilterValueChanges(payload);

        if (this._shouldClearSavedSearch(payload)) {
            this._searchParamsModel.savedSearch = null;
        }
    }

    _checkFilterValueChanges(payload) {
        const FILTER_VALUE_PATH_REGEX = /filters\.([a-zA-Z]+)\.value$/;

        const filtersChanged = [];

        for (const item in payload.flattenedModifications) {
            const matches = item.match(FILTER_VALUE_PATH_REGEX);

            if (matches === null) {
                continue;
            }

            // TODO: remove this once resultsPerPage is removed from filters
            if (matches[1] === 'resultsPerPage') {
                continue;
            }

            filtersChanged.push({
                name: matches[1],
                value: payload.flattenedModifications[item],
            });
        }

        if (filtersChanged.length) {
            this.onFilterValuesChanged(filtersChanged);
        }
    }

    onFilterValuesChanged(filters) {
        // reset page
        this.stateController.animalSearch.pagination.currentPage = 0;
    }

    onSearchTermsChanged(payload) {
        if (!this._initialStateLoaded) {
            return;
        }

        const preSearchCheckObj = new ActionTest();

        this._preSearchCheckAnimalTypeValue(payload, preSearchCheckObj);
        this._preSearchCheckShelterDistance(payload, preSearchCheckObj);
        this._preSearchCheckSpeciesChanged(payload, preSearchCheckObj);

        if (!this._shouldPerformSearch(payload.flattenedModifications)) {
            return;
        }

        if (!preSearchCheckObj.shouldTakeAction) {
            return;
        }

        this.debouncedPerformSearch(payload.options);
    }

    /**
     * @param {object} payload StateController payload object
     * @param {ActionTest} preSearchCheckObj
     * @returns {void}
     */
    _preSearchCheckAnimalTypeValue(payload, preSearchCheckObj) {
        // if animal type has changed, we must clear species prior to performing a search
        // the clearing of species will itself trigger another call of this function;
        // therefore, we'll trigger a fetch of dynamic search filters and return out of
        // the function to prevent a double search
        if ('filters.animalType.value' in payload.flattenedModifications === false) {
            return;
        }

        // trigger a fetch for new dynamic filters
        this._fetchSearchFiltersFromServer();

        // Clear species and breed since any old values that were selected
        // will no longer be applicable to the newly selected animal type
        this.stateController.animalSearch.filters.species = null;
        this.stateController.animalSearch.filters.breed = null;

        this._preSearchCheckAnimalTypeAndCoatLength();

        preSearchCheckObj.preventAction();
    }

    _preSearchCheckAnimalTypeAndCoatLength() {
        const animalTypesWithCoatLength = ['dogs', 'cats', 'rabbits', 'small-furry', 'barnyard'];

        const selectedAnimalType = this.stateController.animalSearch.filters.animalType;

        const isCoatLengthSet = this.stateController.animalSearch.filters.coatLength.length > 0;
        const doesSelectedAnimalTypeUseCoatLength = animalTypesWithCoatLength.indexOf(selectedAnimalType) >= 0;

        // Clear coat length if we're moving to an animal type that doesn't use coat length.
        // At a glance it may seem like we're reseting it on every change where the animal type does not
        // fall into the array above, but the isCoatLengthSet check will prevent any additional
        // nullifications from occurring if we're already on an animal type not in the
        // animalTypesWithCoatLength array.
        if (!doesSelectedAnimalTypeUseCoatLength && isCoatLengthSet) {
            this.stateController.animalSearch.filters.coatLength = null;
        }
    }

    /**
     * @param {object} payload StateController payload object
     * @param {ActionTest} preSearchCheckObj
     * @returns {void}
     */
    _preSearchCheckShelterDistance(payload, preSearchCheckObj) {
        const shelterValueChanged = 'filters.shelter.value' in payload.flattenedModifications;

        if (shelterValueChanged && this._setDistanceAnywhereIfShelterSelected()) {
            preSearchCheckObj.preventAction();
        }
    }

    /**
     * Set the distance to a desired value based on the current selection of the shelter filter
     * @param {boolean} maintainCurrentPage will not reset pagination to page 1 if true
     * @returns {boolean} Whether or not the distance was set
     */
    _setDistanceAnywhereIfShelterSelected(maintainCurrentPage = false) {
        const currentPage = this.stateController.animalSearch.pagination.currentPage;
        const shelterHasValue = this.stateController.animalSearch.filters.shelter.length > 0;
        const isDefaultDistance = this.stateController.animalSearch.filters.isDefaultDistance();

        // upon selection of a shelter, if the user had not previously
        // selected a distance, we will automatically select 'Anywhere'
        if (!shelterHasValue || !isDefaultDistance) {
            return false;
        }

        this.stateController.animalSearch.filters.distance = ['Anywhere'];

        if (maintainCurrentPage) {
            this.stateController.animalSearch.pagination.currentPage = currentPage;
        }

        return true;
    }

    /**
     * @param {object} payload StateController payload object
     * @param {ActionTest} preSearchCheckObj
     * @returns {void}
     */
    _preSearchCheckSpeciesChanged(payload, preSearchCheckObj) {
        if ('filters.species.value' in payload.flattenedModifications) {
            const animalTypeSlug = this.stateController.animalSearch.filters.animalType[0] || null;
            const speciesName = this.stateController.animalSearch.filters.species[0] || null;

            this._updateBreedsForAnimalTypeAndSpecies(animalTypeSlug, speciesName);

            this.stateController.animalSearch.filters.breed = null;
        }
    }

    /**
     * Search related functionality
     */

    // TODO: improve this -- it's brittle and clumsy
    _shouldPerformSearch(flattenedModifications) {
        const itemsToExecuteSearchOnChange = ['pagination.offset', 'pagination.countPerPage'];

        const itemsToSkip = ['filters.breed.counts'];

        // TODO: is this how we want to control this?
        for (const key in flattenedModifications) {
            if (itemsToExecuteSearchOnChange.indexOf(key) !== -1) {
                return true;
            }

            // if breed counts, skip
            if (itemsToSkip.indexOf(key) !== -1) {
                continue;
            }

            const keyPieces = key.split('.');
            if (keyPieces[0] === 'filters') {
                if (keyPieces.indexOf('options') !== -1) {
                    // options array; prevent
                    continue;
                }

                // a filter value changed... perform search
                if (keyPieces[keyPieces.length - 1] === 'value') {
                    return true;
                }
            }
        }

        return false;
    }

    _shouldClearSavedSearch(payload) {
        if (!this._initialStateLoaded) {
            return false;
        }

        // resultsPerPage exists in both the pagination state controller as well as the filters state controller...
        // we need to ignore a change in the filter state controller for this item
        // TODO: remove 'resultsPerPage' from here once the extra tracking of resultsPerPage in both filter and pagination state controllers is fixed
        const keysToIgnore = ['sortBy', 'resultsPerPage'];

        for (const key in payload.modifications.filters) {
            if (keysToIgnore.indexOf(key) !== -1) {
                continue;
            }

            // all of our filters have a sub-prop of value for tracking of their value
            // if this changes, that means a filter has actually changed, thus we should
            // clear the saved search
            if ('value' in payload.modifications.filters[key]) {
                return true;
            }
        }

        return false;
    }

    async _fetchSearchFiltersFromServer() {
        this.stateController.ui.fundamentalFiltersLoading = true;

        const response = await animalSearchFiltersAPIService.getFilters(this._searchParamsModel.getSearchParams());
        this._loadDynamicFilterOptions(response.data);
        this._updateFiltersDisplay(null, response.data);

        this.stateController.ui.fundamentalFiltersLoading = false;
    }

    _updateBreedsForAnimalTypeAndSpecies(animalTypeSlug, speciesName) {
        // if no species was provided, show all breeds
        if (!speciesName) {
            return this._showAllBreedOptions();
        } else {
            return this._fetchBreedsForAnimalTypeAndSpecies(animalTypeSlug, speciesName);
        }
    }

    _showAllBreedOptions() {
        const breedOptions = this.stateController.animalSearch.filters.breedOptions;
        for (let i = 0; i < breedOptions.length; i++) {
            breedOptions[i].display = true;
        }

        this.stateController.animalSearch.filters.breedOptions = breedOptions;
    }

    async _fetchBreedsForAnimalTypeAndSpecies(animalTypeSlug, speciesName) {
        const response = await breedsAPIService.getBreedsForSpecies(animalTypeSlug, speciesName);

        if (response.wasCancelled) {
            return;
        }

        // get values for breeds in response
        const valuesForBreedsToShow = [];
        for (let i = 0; i < response.data.length; i++) {
            valuesForBreedsToShow[i] = response.data[i].value;
        }

        const breedOptions = this.stateController.animalSearch.filters.breedOptions;
        for (let i = 0; i < breedOptions.length; i++) {
            const shouldShow = valuesForBreedsToShow.indexOf(breedOptions[i].value) !== -1;
            breedOptions[i].display = shouldShow;
        }

        this.stateController.animalSearch.filters.breedOptions = breedOptions;
    }

    async _performSearch(options = {}) {
        this.stateController.ui.resultsLoading = true;

        const searchParams = this._searchParamsModel.getSearchParams();
        const requestInterceptor = url => {
            window.localStorage.setItem('animalSearchApiUrl', window.location.origin + url);
        };
        const [searchResponse, contentTout] = await Promise.all([
            animalSearchAPIService.search(searchParams, requestInterceptor),
            ContentToutProvider.getContentTout(this.stateController.animalSearch.filters.animalType[0]),
        ]);

        // exit if request was cancelled
        if (searchResponse.wasCancelled) {
            return;
        }

        // Adding localStorage variable to manage PDP nav functionality in Next.js
        const animalSearchIdsAndUrls = searchResponse.data.items.map(r => ({
            id: r.animal.id,
            url: r.animal.social_sharing.email_url,
        }));
        window.localStorage.setItem('animalSearchIdsAndUrls', JSON.stringify(animalSearchIdsAndUrls));
        window.localStorage.setItem('animalSearchTotalPages', searchResponse.data.totalPages);
        this._processSearchResponse(searchResponse, contentTout);

        this.stateController.ui.resultsLoading = false;

        try {
            window.localStorage.setItem('animalSearchResultUrl', searchResponse.data.url);
            // update location
            if (!this._initialSearchCompleted) {
                const state = this.stateController.state;
                // At this time isPerformingFirstLoad is true and we do not want to store it that way
                state.ui.animalSearch.isPerformingFirstLoad = false;

                window.history.replaceState(state, null, searchResponse.data.url);
            } else {
                window.history.pushState(this.stateController.state, null, searchResponse.data.url);

                this._resetLyticsModal();
            }
        } catch (ex) {
            // this is for an edge case in our dev environments; don't want to throw as that will break the application
            console.warn('Unable to replace/push new state onto the history stack.');
        }

        // PFPROD-4085 - GA4 Analytics - Search Performed Event
        this.ensighten('PerformSearchGa4');

        if (!this._initialSearchCompleted) {
            this._initialSearchCompleted = true;
            this.stateController.ui.isPerformingFirstLoad = false;
        }
    }

    _setInitialLocationData(searchResponse) {
        this.stateController.ui.patchState({
            animalSearch: {
                locationSelect: {
                    labelText: `${searchResponse.data.summary.animal_search_location_name ||
                        'Enter City, State or ZIP'}`,
                    activeItemIndex: -1,
                    optionsMenuOpen: false,
                    optionsLoading: false,
                    options: [],
                },
            },
        });
    }

    _processSearchResponse(searchResponse, contentTout) {
        if (searchResponse.error) {
            // TODO: handle this
            console.error('Search error:', searchResponse.errorMessage);
        } else {
            this._setInitialLocationData(searchResponse);

            const totalPages = searchResponse.data.totalPages;

            this.stateController.animalSearch.pagination.totalPages = totalPages;

            // set filter option counts
            this.stateController.animalSearch.filters.speciesCounts = searchResponse.data.facets.species;
            this.stateController.animalSearch.filters.breedCounts = searchResponse.data.facets.breed;
            this.stateController.animalSearch.filters.ageCounts = searchResponse.data.facets.age;
            this.stateController.animalSearch.filters.genderCounts = searchResponse.data.facets.gender;
            this.stateController.animalSearch.filters.sizeCounts = searchResponse.data.facets.size;
            this.stateController.animalSearch.filters.colorCounts = searchResponse.data.facets.color;
            this.stateController.animalSearch.filters.coatLengthCounts = searchResponse.data.facets.coatLength;
            this.stateController.animalSearch.filters.attributeCounts = searchResponse.data.facets.attribute;
            this.stateController.animalSearch.filters.householdCounts = searchResponse.data.facets.household;

            // set results
            this.stateController.animalSearch.results.set({
                items: searchResponse.data.items,
                totalResults: searchResponse.data.totalResults,
                totalPages, // NOTE: this doesn't look like it's currently being used -- remove?
                url: searchResponse.data.url,
                shareableUrl: searchResponse.data.shareableUrl,
                name: searchResponse.data.name,
                contentTout,
                summary: searchResponse.data.summary,
            });

            this.ensighten('Consumer484', searchResponse.data.totalResults);
        }

        // requestAnimationFrame needed to ensure we have pet cards to interact with
        window.requestAnimationFrame(() => {
            this.focusActivePetCard();
        });
    }

    /**
     * Resets the 3rd party Lytics modal instance
     * Triggered after any filter is changed by the user
     */
    _resetLyticsModal() {
        try {
            window.lio.loaded = false;
            window.lio.hasEntity = false;
            window.lio.pathforaLoaded = false;
            window.pathfora.clearAll();
            window.lio.getEntity();
        } catch (error) {
            console.error('Lytics instance does not exist on Window');
        }
    }

    /**
     * Filter event handlers
     */

    // this handler gets called directly, and thus needs to wrap the
    // provided value in an array to match the expected structure
    onMobileAnimalTypeChanged(newValue) {
        this.onAnimalTypeChange([newValue]);
    }

    onAnimalTypeChange(newValue) {
        this.stateController.animalSearch.filters.animalType = newValue;

        // Update page <title> with new animal type
        document.title = `${this.stateController.animalSearch.filters.animalTypeExpandedValues[0].longLabel} for Adoption | Petfinder`;

        this.triggerFilterChangedEvent(null, newValue, 'animal type');
        this.ensighten('Consumer066', { type: newValue });
    }

    ensighten(eventId, payload) {
        ensightenTracking[`event${eventId}`](payload);
    }

    onBreedChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.breed.slice();
        this.stateController.animalSearch.filters.breed = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'breed');
        this.triggerFilterChangedEvent(oldValue, newValue, 'breed');
        this.ensighten('Consumer049', { breed: newValue });
    }

    onAgeChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.age.slice();
        this.stateController.animalSearch.filters.age = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'age');
        this.triggerFilterChangedEvent(oldValue, newValue, 'age');
        this.ensighten('Consumer052', { age: newValue });
    }

    onGenderChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.gender.slice();
        this.stateController.animalSearch.filters.gender = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'gender');
        this.triggerFilterChangedEvent(oldValue, newValue, 'gender');
        this.ensighten('Consumer051', { gender: newValue });
    }

    get isGuest() {
        return !Config.userAuthed;
    }

    get hasCompletedAdoptionProfile() {
        const animalType = this.stateController.animalSearch.filters.animalType[0];
        const completedAdoptionProfiles = Config.page['completed_adoption_profiles'];

        return (
            completedAdoptionProfiles &&
            completedAdoptionProfiles.length > 0 &&
            completedAdoptionProfiles.includes(animalType)
        );
    }

    onAuthSuccessCallback = () => {
        window.location.assign(`${window.location.origin}${window.location.pathname}/?sort=best_match`);
    };

    onSortByChanged(newValue) {
        this.triggerFilterChangedEvent(null, newValue, 'sort by');

        if (newValue[0] === 'best_match') {
            if (this.isGuest) {
                const $modalTrigger = document.querySelector('#Best_Matches_Modal_Guest_Trigger');

                $modalTrigger.click();

                return;
            }

            if (!this.hasCompletedAdoptionProfile) {
                const $modalTrigger = document.querySelector('#Best_Matches_Modal_Trigger');

                $modalTrigger.click();

                return;
            }
        }

        this.stateController.animalSearch.filters.sortBy = newValue;
        this.ensighten('Consumer504', { sortBy: newValue });
    }

    onResultsPerPageChanged(newValue) {
        // copy to filters for template purposes
        this.stateController.animalSearch.filters.resultsPerPage = newValue;

        // copy to pagination for functionality purposes
        this.stateController.animalSearch.pagination.countPerPage = newValue;
    }

    onDaysOnPetfinderChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.daysOnPetfinder.slice();
        this.stateController.animalSearch.filters.daysOnPetfinder = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'days on petfinder');
        this.triggerFilterChangedEvent(oldValue, newValue, 'days on petfinder');
        this.ensighten('Consumer058', { daysOnPetfinder: newValue });
    }

    onDistanceChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.distance.slice();
        this.stateController.animalSearch.filters.distance = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'distance');
        this.triggerFilterChangedEvent(oldValue, newValue, 'distance');
        this.ensighten('Consumer057', { distance: newValue });
    }

    onAttributeChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.attribute.slice();
        this.stateController.animalSearch.filters.attribute = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'health behavior');
        this.triggerFilterChangedEvent(oldValue, newValue, 'health behavior');
        this.ensighten('Consumer053', { attribute: newValue });
    }

    onCoatLengthChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.coatLength.slice();
        this.stateController.animalSearch.filters.coatLength = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'coat length');
        this.triggerFilterChangedEvent(oldValue, newValue, 'coat length');
        this.ensighten('Consumer054', { coatLength: newValue });
    }

    onColorChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.color.slice();
        this.stateController.animalSearch.filters.color = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'color');
        this.triggerFilterChangedEvent(oldValue, newValue, 'color');
        this.ensighten('Consumer055', { color: newValue });
    }

    onSizeChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.size.slice();
        this.stateController.animalSearch.filters.size = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'size');
        this.triggerFilterChangedEvent(oldValue, newValue, 'size');
        this.ensighten('Consumer050', { size: newValue });
    }

    onSpeciesChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.species.slice();
        this.stateController.animalSearch.filters.species = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'species');
        this.triggerFilterChangedEvent(oldValue, newValue, 'species');
        this.ensighten('Consumer292', { species: newValue });
    }

    onLocationChanged(newValue) {
        // prevent re-selection of the same value
        if (this.stateController.animalSearch.filters.location[0] === newValue) {
            return;
        }

        const oldValue = this.stateController.animalSearch.filters.location[0];
        this.triggerFilterChangedEvent(oldValue, newValue, 'location');

        this.stateController.animalSearch.filters.location = newValue;
        this._fetchSearchFiltersFromServer();

        this.ensighten('Consumer065', { location: newValue });
    }

    onShelterChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.shelter.slice();
        this.stateController.animalSearch.filters.shelter = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'adoption org');
        this.triggerFilterChangedEvent(oldValue, newValue, 'adoption org');
        this.ensighten('Consumer060', { shelter: newValue });
    }

    onAnimalNameChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.animalName;
        this.stateController.animalSearch.filters.animalName = newValue;
        this.triggerRemovedFilterEventNonArray(oldValue, newValue, 'pet name');
        this.triggerFilterChangedEvent(oldValue, newValue ? 'yes' : '', 'pet name');
        this.ensighten('Consumer059', { name: newValue });
    }

    onHouseholdChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.household.slice();
        this.stateController.animalSearch.filters.household = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'household');
        this.triggerFilterChangedEvent(oldValue, newValue, 'household');
        this.ensighten('Consumer056', { household: newValue });
    }

    onHealthBehaviorChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.healthBehavior.slice();
        this.stateController.animalSearch.filters.healthBehavior = newValue;
        this.triggerRemovedFilterEvent(oldValue, newValue, 'health behavior');
        this.triggerFilterChangedEvent(oldValue, newValue, 'health behavior');
        this.ensighten('Consumer053', { healthBehavior: newValue });
    }

    onTransportableChanged(newValue) {
        const oldValue = this.stateController.animalSearch.filters.transportable;
        this.stateController.animalSearch.filters.transportable = newValue;
        this.triggerRemovedFilterEventNonArray(oldValue ? 'include' : 'exclude', newValue, 'include out-of-town pets');
        this.triggerFilterChangedEvent(oldValue ? 'include' : 'exclude', newValue, 'include out-of-town pets');
        this.ensighten('Consumer283', { transportable: newValue });
    }

    triggerRemovedFilterEvent(oldValue, newValue, filterName) {
        if (newValue.length < oldValue.length) {
            let removedFilter;
            // TODO: does this condition ever happen? marked for review and cleanup
            if (Array.isArray(oldValue)) {
                removedFilter = oldValue.filter(filter => !newValue.includes(filter));
            } else {
                removedFilter = newValue;
            }
            this.ensighten('Consumer284', {
                filterName,
                filterValue: Array.isArray(removedFilter)
                    ? removedFilter.map(filter => filter.toLowerCase()).join(',')
                    : removedFilter.toLowerCase(),
            });
        }
    }

    triggerRemovedFilterEventNonArray(oldValue, newValue, filterName) {
        if (oldValue && !newValue) {
            const removedFilter = oldValue;

            this.ensighten('Consumer284', {
                filterName,
                filterValue: removedFilter,
            });
        }
    }

    triggerFilterChangedEvent(oldVal, newVal, filterName) {
        let oldValue = oldVal;
        let newValue = newVal;

        if (oldVal === null || typeof oldVal === 'undefined') {
            oldValue = Array.isArray(newVal) ? [] : '';
        }

        if (newVal === null || typeof newVal === 'undefined') {
            newValue = Array.isArray(oldVal) ? [] : '';
        }

        let removedFilter;
        let addedFilter;

        // filters can be arrays or single values
        if (Array.isArray(oldValue)) {
            if (newValue.length < oldValue.length) {
                removedFilter = oldValue.filter(filter => !newValue.includes(filter));
            } else {
                addedFilter = newValue.filter(filter => !oldValue.includes(filter));
            }
        } else {
            if (oldValue && !newValue) {
                removedFilter = oldValue;
            } else {
                addedFilter = newValue;
            }
        }

        // Filter Removed
        if (removedFilter) {
            // Nothing to do here for now
        }

        // Filter Added
        if ((Array.isArray(addedFilter) && addedFilter.length > 0) || (!Array.isArray(addedFilter) && addedFilter)) {
            const addedFilterSingle = Array.isArray(addedFilter) ? addedFilter[0] : addedFilter;

            // PFPROD-4085 - GA4 Analytics - Search - Add Filter Event
            /* eslint-disable camelcase, no-undefined */
            window.dataLayer.push(
                {
                    event: 'filter_applied',
                    ga4: true,
                    event_params: {
                        filter_name: filterName.toLowerCase(),
                        filter_value: addedFilterSingle.toLowerCase(),
                        page_type: 'search results',
                    },
                },
                { event_params: undefined }
            );
        }
    }
}

export default PFDCAnimalSearchElement;
